import React from 'react';
import {ACTION, Can, Reply} from "ability";
import TooltipIcon from "components/TooltipIcon";
import {Stack} from "@mui/material";

const ViewAnnotationReplies = (props) => {
    const getReplyObject = (reply) => {
        try {
            const creator = reply.creator.id;
            return new Reply({user_id: creator});
        } catch (ignored) {
            return new Reply({});
        }
    };

    return (
        <>
            {
                // Show replies
                props.getReplies().map((reply, index) => (
                    <div key={index} className={'reply'}>
                        <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'} className={'width100'}>
                            <code>{reply.value}</code>

                            {
                                // only show edit options if it is not a fake annotation
                                !props.isFake &&
                                <Can I={ACTION.ACTION} a={'editReplies'}>
                                    {/* I can only update/delete my replies */}
                                    <Can I={ACTION.UPDATE} this={getReplyObject(reply)}>
                                        <div>
                                            <TooltipIcon tooltipText={'general.edit'} icon={"edit"} onClick={() => {
                                                props.setReplyEdit(reply);
                                                props.setReply(true);
                                            }}/>
                                            <TooltipIcon tooltipText={'general.delete'} icon={"delete"}
                                                         onClick={() => {
                                                             props.deleteBodySpecific(reply);
                                                         }}/>
                                        </div>
                                    </Can>
                                </Can>
                            }
                        </Stack>
                    </div>
                ))
            }
        </>
    );
};

export default ViewAnnotationReplies;