import Grid from "@mui/material/Grid";
import Avatar from "@mui/material/Avatar";
import React from "react";
import useAuth from "hooks/useAuth";

const AvatarUser = () => {
    const user = useAuth();

    return (
        <Grid container alignItems={'center'} spacing={2}>
            <Grid item>
                <Avatar
                    src={""}
                    style={{
                        width: 40,
                        heigth: 5,
                    }}
                />
            </Grid>
            <Grid item style={{marginRight: '12px', marginLeft: '-10px'}}>
                {user.email}
            </Grid>
        </Grid>
    );
};

export default AvatarUser;
