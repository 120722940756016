import axios from "axios";
import {failureCallback, SERVER_URI, successCallback} from "api/index";

const api = axios.create({
    baseURL: SERVER_URI,
    withCredentials: true,
    withXSRFToken: true,
});

api.defaults.headers.common = {
    "Accept": "application/json",
    "Content-Type": "application/json"
};

// request to get CSRF Token from Laravel
export const setCSRFToken = () => {
    return api.get("/sanctum/csrf-cookie").then(successCallback, failureCallback);
};

export const setBearerToken = (token) => {
    api.defaults.headers.common = {"Authorization": `Bearer ${token}`};
};

export default api;
