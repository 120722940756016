import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {handleDataServer, successData} from "api";
import containerApi from 'api/routes/container';

const containerSlicer = createSlice({
    name: "container",
    initialState: {
        container: false,
        resources: [],
        html: ""
    },
    reducers: {
        setContainer: (state, action) => {
            const {container, html} = action.payload;

            state.container = container;
            state.resources = container.resources;
            state.html = html;
        },
        setResources: (state, action) => {
            state.resources = action.payload;
        },
    },
    extraReducers(builder) {
        // show container
        builder.addCase(showContainer.fulfilled, (state, action) => {
            if (action.payload == null) {
                console.log('error getting container settings');
                return;
            }

            const {container, html} = action.payload;
            state.container = container;
            state.resources = container.resources;
            state.html = html;
        });
    }
});

// thunk actions
export const showContainer = createAsyncThunk("container/show",
    async (id, thunkAPI) => {
        return await handleDataServer(await containerApi.showContainer(id), successData);
    });

// getters
export const selectContainer = (state) => state.container.container;
export const selectResources = (state) => state.container.resources;
export const selectHtml = (state) => state.container.html;

// actions
export const {
    setContainer,
    setResources
} = containerSlicer.actions;

// slice
export default containerSlicer;
