import React, {useCallback, useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {selectHtml} from "store/modules/Container";
import {Annotorious} from '@annotorious/react';
import {TextAnnotator} from "@recogito/react-text-annotator";
import {
    closeAnnotation,
    selectAnnotations,
    selectIsAnnotating,
    selectTypeAnnotation,
    setIsAnnotating
} from "store/modules/Annotations";
import useAuth from "hooks/useAuth";
import MockStorage from "components/container/recogito/MockStorage";
import '@recogito/text-annotator/dist/text-annotator.css';
import CustomPopup from "components/container/recogito/popup/CustomPopup";
import MainPopup from "components/container/recogito/popup/MainPopup";
import MyW3CTextFormat from "components/container/recogito/MyW3CTextFormat";

const DocumentAnnotator = (
    {
        target = ""
    }
) => {
    const user = useAuth();
    const dispatch = useDispatch();

    // annotator hook useAnnotator cannot be used here, so we pass from MockStorage to this component using useState
    const [annotator, setAnnotator] = useState(undefined);

    const [loaded, setLoaded] = useState(false);

    const html = useSelector(selectHtml);
    const annotations = useSelector(selectAnnotations);

    const isAnnotating = useSelector(selectIsAnnotating);
    const typeAnnotation = useSelector(selectTypeAnnotation);

    const w3cAdapter = useCallback((container) => MyW3CTextFormat(target, container), []);

    const handleClose = () => {
        dispatch(closeAnnotation());

        if (!annotator) return;

        // get last annotation and check if body is empty; if it is, we remove the annotation by undoing the previous action
        const lastAnnotation = annotator.getAnnotations().pop();
        if (lastAnnotation && lastAnnotation.body.length === 0) {
            annotator.undo();
        }
    };

    useEffect(() => {
        if (!loaded) {
            setLoaded(true);
        }
    }, [annotations, isAnnotating, typeAnnotation]);

    return (
        <Annotorious>
            <TextAnnotator adapter={w3cAdapter} renderer={'SPANS'} userAction={'SELECT'}>
                <div dangerouslySetInnerHTML={{__html: html}} id={'html'}></div>
            </TextAnnotator>

            <CustomPopup
                openOnAnnotation={false}
                openClick={isAnnotating}
                onClose={handleClose}
                onAnnotationClick={() => dispatch(setIsAnnotating(true))}
                popup={props => (
                    <MainPopup {...props} />
                )}
            />

            <MockStorage onSetAnnotator={setAnnotator} target={target}/>
        </Annotorious>
    );
};

export default DocumentAnnotator;
