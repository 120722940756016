export default {
    translations: {
        brand: {
            name: "DLNotes",
            description: "Collaborative annotation tool in digital content for language teaching",
        },
        auth: {
            login: "Login",
            createAccount: "Create account",
            alreadyHaveAccount: "Already have an account",
            backIndex: "Go back to main page",
            logout: "Logout",
            roleFailed: "It looks like your profile role does not exist. Contact a system administrator.",
            forgotPassword: "Forgot my password",
            forgot: {
                instructionsEmail: "The recovery instructions were sent to {{email}}"
            },
            confirm: {
                title: "Set an access password",
                passwordSet: "Password set successfully, you will be redirected to the login page",
                confirmPassword: "Confirm the password",
                setPassword: "Set password",
                passwordDiff: "Passwords do not match"
            },
            create: {
                title: "Create user",
                line0: "It is very good to have you here!",
                line1: "You will receive an email to confirm your registration, see you later!",
                fullName: "Full name"
            }
        },
        common: {
            template: {
                logo: "DLNotes Logo"
            }
        },
        teacher: {
            exportAnnotations: "Export annotations",
        },
        stats: {
            stats_free: "Free statistics",
            stats_semantic: "Semantic statistics",
            telemetry: "Telemetry",
        },
        activities: {
            title: "Activities",
            titleNew: "activity",
            newActivity: "New activity",
            deleteActivity: "Delete activity",
            create: {
                nameActivity: "Activity's name",
                repeatTitleAnnotations: "Repeat document title in annotations board",
                knowledgeBase: "Knowledge base",
                knowledgeBaseShare: "Knowledge bases are shared between activity users",
                singleKnowledgeBase: "A knowledge base for all documents",
                includeClassesValidation: "Include classes in validation",
                digitalLibraryDocuments: "Digital library documents",
                yourDocuments: "Your documents",
                permissions: "Permissions",
                attributes: {
                    createAnnotations: "Create annotations",
                    editAnnotations: "Edit annotations",
                    createFree: "Create free annotations",
                    createSemantic: "Create semantic annotations",
                    createReplies: "Create replies",
                    editReplies: "Edit replies",
                    visibleEverybody: "Make annotations with 'public' visibility",
                    visibleGroups: "Make annotations with 'moderator' visibility",
                    visiblePrivate: "Make annotations with 'private' visibility",
                    seeAllSemantic: "See other members of the knowledge base",
                    filterByUser: "Allow filtering of free annotations by user"
                }
            }
        },
        users: {
            title: "Users",
            singleTitle: "user",
            removeCourse: "user from this course",
            addUsers: {
                title: "Add users",
                search: "Search for users",
                role: "Role"
            },
            read: {
                title: "Users management",
                table: {
                    id: "ID",
                    name: "Name",
                    email: "Email",
                    role: "Role"
                }
            },
            edit: {
                title: "Edit user"
            },
            changeItself: "You changed your own user, you will be redirected to the login page!",
        },
        courses: {
            users: {
                title: "Course users"
            },
            title: "Courses",
            titleNew: "course",
            create: {
                nameCourse: "Course's name",
            },
            edit: "Rename course"
        },
        documents: {
            title: "Documents",
            singleTitle: "document",
            singleTitleCaps: "Document",
            sameAs: "Semantic annotation (same as)",
            path: "Path",
            author: "Author"
        },
        semantic: {
            title: "Semantic",
            titleShow: "Semantic schemes",
            singleTitle: "Semantic scheme",
            schemeDefinition: "Scheme edit",
            addTitle: "Semantic scheme definition",
            addSibling: "Add concept",
            addChild: "Add child",
            typeClass: "Type the concept here",
            property: "Property",
            properties: "Properties",
            relations: "Relations",
            relation: "Relation",
            classDefinition: "Class definition",
            semanticClass: "Semantic class",
            field: {
                type: "Field type",
                display: "Display",
                sameAs: "Semantic definition (sameAs)",
                range: "Range of relation",
                primitives: "Primitive types",
                enumerates: "Enumerates",
                enumerateName: "Enumerate name",
                enumerate: "enumerate",
            },
            setRequiredError: "All root classes must have a main property. The following does not have: {{display}}",
        },
        general: {
            name: "Name",
            moderator: "Moderator",
            teacher: "Teacher",
            student: "Student",
            create: "Create",
            add: "Add",
            edit: "Edit",
            delete: "Delete",
            reset: "Reset",
            email: "Email",
            password: "Password",
            alert: "Ops, it was not possible to complete the request",
            confirmDelete: "Do you really want to delete?",
            confirmDeleteText: "Do you really want to delete this {{item}}?",
            confirmDuplicate: "Do you really want to duplicate?",
            confirmDuplicateText: "Do you really want to duplicate this {{item}}?",
            fulfillAll: "You must fulfill all the fields",
            title: "Title",
            actions: "Actions",
            item: "Item",
            public: "Public",
            private: "Private",
            moderate: "Moderator",
            description: "Description",
            notValidUrl: "Not a valid URL for: {{url}}",
            save: "Save",
            cancel: "Cancel",
            index: "Index",
            duplicate: "Duplicate",
            reply: "Reply",
            visibility: "Visibility",
            comment: "Comment",
            all: "All",
            refresh: "Refresh",
            home: "Home",
            copy: "Copy",
            key: "Key",
            secret: "Secret",
            close: "Close"
        },
        genericRead: {
            notFound: "No {{title}} found"
        },
        roles: {
            owner: "Owner",
            moderator: "Moderator",
            student: "Student",
            teacher: "Teacher",
            admin: "Administrator"
        },
        admin: {
            users: "Users"
        },
        selectMultiple: {
            leftTitle: "Available",
            rightTitle: "Selected"
        },
        dragDropFile: {
            title: "Drag or click to select",
            hoverTitle: "Drag here",
            invalidFileType: "Invalid file type"
        },
        boolean: {
            true: "Yes",
            false: "No"
        },
        container: {
            collapse: "Collapse",
            expand: "Expand",
            hideAnnotations: "Hide annotations",
            showAnnotations: "Show annotations",
            freeAnnotation: "Free annotation",
            semanticAnnotation: "Semantic annotation",
            deleteInstance: "Delete instance",
            annotationReport: "Annotation report",
            report: "Report",
            annotationType: "Annotation type",
            freeType: "Free type",
            resource: "Semantic resource",
            user: "User"
        },
        annotation: {
            freeAnnotationType: "Free annotation type",
            purpose: {
                commenting: "Comment",
                highlighting: "Recommendation",
                moderating: "Task",
                describing: "Example",
                editing: "Explanation",
                questioning: "Question",
                bookmarking: "See also",
                classifying: "Semântica"
            },
            seeInstance: "See instance",
            addResource: "Add resource",
            type: "Type",
            seize: "Seize",
            semanticAnnotation: {
                steps: {
                    classChoose: "Choose a class",
                    propertyFulfill: "Fulfill the properties",
                    back: "Back step",
                    next: "Next step",
                }
            },
            annotatedText: "Annotated text",
            goToAnnotation: "Go to annotation"
        }
    },
};
