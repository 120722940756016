import React from 'react';
import {FormControl, FormLabel, Radio, RadioGroup} from "@mui/material";
import {useTranslation} from "react-i18next";
import {v4 as uuid} from 'uuid';
import FormControlLabel from "@mui/material/FormControlLabel";
import utils from "utils/utils";
import useAuth from "hooks/useAuth";

const AccessRightsSelector = (props) => {
    const user = useAuth();
    const {t} = useTranslation();

    const idAccessRights = uuid();

    const getAccessRights = () => {
        const accessRights = {...utils.accessRights};

        if (utils.isProtectedUser(user)) {
            delete accessRights.private;
            return accessRights;
        }

        return accessRights;
    };

    return (
        <div style={{width: 'auto'}}>
            <FormControl style={{paddingLeft: '15px'}}>
                <FormLabel id={idAccessRights}>{t('general.visibility')}</FormLabel>

                <RadioGroup
                    row
                    aria-labelledby={idAccessRights}
                    value={props.accessRights}
                    onChange={(e) => props.setAccessRights(e.target.value)}
                >
                    {Object.keys(getAccessRights()).map((key, index) => (
                        <FormControlLabel key={index} value={key} control={<Radio />} label={t(`general.${key}`)} />
                    ))}
                </RadioGroup>
            </FormControl>
        </div>
    );
};

export default AccessRightsSelector;