import {useSelector} from "react-redux";
import {selectContainer} from "store/modules/Container";
import {useEffect, useState} from "react";

const useRoleCourseContainer = () => {
    const container = useSelector(selectContainer);
    const [role, setRole] = useState(0);

    useEffect(() => {
        if (container) {
            setRole(container.activity.course.user[0].relation.role_id);
        }
    }, [container]);

    return role;
};

export default useRoleCourseContainer;