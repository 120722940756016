import {Link, useNavigate} from "react-router-dom";
import React from "react";
import coursesApi from 'api/routes/courses';
import TooltipIcon from "components/TooltipIcon";
import utils, {getRouteRole, openConfirmDelete} from "utils/utils";
import {handleDataServer, successCallback, successData} from "api";
import useAuth from "hooks/useAuth";
import {useTranslation} from "react-i18next";

const CourseManagement = (
    {
        course
    }
) => {
    const {t} = useTranslation();
    const user = useAuth();
    const navigate = useNavigate();

    const deleteCourse = async (flag) => {
        if (flag) {
            let result = await handleDataServer(await coursesApi.deleteCourse(course.id), successCallback);
            if (result.status) {
                navigate(0);
            }
        }
    };

    const getOwner = () => {
        return course.users.find(user => user.relation.role_id === utils.rolesCourses.owner);
    };

    return (
        <div className={'button-container'}>
            {utils.isAdmin(user) &&
                <small>{t("roles.teacher")}: {getOwner() ? getOwner().name : ""}</small>
            }

            <Link to={`${getRouteRole(user)}/courses/${course.id}/activities/new`}>
                <TooltipIcon tooltipText={"activities.newActivity"} icon={"library_add"} color={'primary'}/>
            </Link>
            <Link to={`${getRouteRole(user)}/courses/${course.id}/users`}>
                <TooltipIcon tooltipText={"users.title"} icon={"people"} color={'primary'}/>
            </Link>
            <Link to={`${getRouteRole(user)}/courses/${course.id}/edit`}>
                <TooltipIcon tooltipText={"courses.edit"} icon={"edit"} color={'primary'}/>
            </Link>
            <Link to={''} onClick={(e) => openConfirmDelete("courses.titleNew", deleteCourse, null, e)}>
                <TooltipIcon tooltipText={"general.delete"} icon={"delete"} color={'primary'}/>
            </Link>
        </div>
    );
};

export default CourseManagement;
