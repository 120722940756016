import React from "react";
import {Link, useNavigate} from "react-router-dom";
import TooltipIcon from "components/TooltipIcon";
import {useMediaQuery} from "@mui/material";
import {getRouteRole, openConfirmDelete} from "utils/utils";
import {handleDataServer, successStatus} from "api";
import activitiesApi from 'api/routes/activities';
import useAuth from "hooks/useAuth";

const ActivityManagement = (
    {
        course,
        activity
    }
) => {
    const user = useAuth();
    const isMobile = useMediaQuery('(max-width:900px)');
    const permission = activity && activity.permissions ? activity.permission[0] : {};
    const navigate = useNavigate();

    // const genericHandleDataServer = async (result, type) => {
    //     let data = await handleDataServer(result, (dataServer) => dataServer.data);
    //
    //     // make download
    //     const url = window.URL.createObjectURL(new Blob([data]));
    //     const link = document.createElement('a');
    //     link.href = url;
    //     link.setAttribute('download', `${activity.id}-${type}.csv`);
    //     document.body.appendChild(link);
    //     link.click();
    //     link.remove();
    // };

    // const getStatsFree = async () => {
    //     // let result = await statsApi.getStatsFree(user.access_token, activity.id);
    //     // await genericHandleDataServer(result, 'free');
    // };
    //
    // const getStatsSemantic = async () => {
    //     // let result = await statsApi.getStatsSemantic(user.access_token, activity.id);
    //     // await genericHandleDataServer(result, 'semantic');
    // };
    //
    // const getStatsTelemetry = async () => {
    //     // let result = await statsApi.getTelemetry(user.access_token, activity.id);
    //     // await genericHandleDataServer(result, 'telemetry');
    // };

    const deleteActivity = async (flag) => {
        if (flag) {
            let result = await handleDataServer(await activitiesApi.deleteActivity(activity.id), successStatus);
            if (result) {
                navigate(0);
            }
        }
    };

    return (
        <div className={isMobile ? '' : 'atividades-container'}>
            {/*{permission.createFree &&*/}
            {/*    <TooltipIcon*/}
            {/*        tooltipText={"stats.stats_free"}*/}
            {/*        icon={'show_chart'}*/}
            {/*        onClick={getStatsFree}*/}
            {/*    />*/}
            {/*}*/}

            {/*{permission.createSemantic === "1" &&*/}
            {/*    <TooltipIcon*/}
            {/*        tooltipText={"stats.stats_semantic"}*/}
            {/*        icon={'schema'}*/}
            {/*        onClick={getStatsSemantic}*/}
            {/*    />*/}
            {/*}*/}

            {/*<TooltipIcon tooltipText={"stats.telemetry"} icon={'query_stats'} onClick={getStatsTelemetry}/>*/}

            <Link to={`${getRouteRole(user)}/courses/${course.id}/activities/${activity.id}/edit`}>
                <TooltipIcon tooltipText={"general.edit"} icon={"edit"}/>
            </Link>

            <TooltipIcon
                tooltipText={"activities.deleteActivity"}
                icon={"delete"}
                onClick={() => openConfirmDelete("activities.titleNew", deleteActivity, null, null)}
            />
        </div>
    );
};

export default ActivityManagement;
