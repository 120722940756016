import React, {useEffect} from "react";
import {getCourses, selectCourses} from 'store/modules/Courses';
import Card from "@mui/material/Card";
import {getRouteRole, isProtectedUser} from "utils/utils";
import {useDispatch, useSelector} from "react-redux";
import {Link} from "react-router-dom";
import Button from "@mui/material/Button";
import {useTranslation} from "react-i18next";
import useAuth from "hooks/useAuth";
import AccordionCourses from "components/course/accordions/AccordionCourses";

const ReadCourses = () => {
    const {t} = useTranslation();
    const user = useAuth();
    const dispatch = useDispatch();
    const courses = useSelector(selectCourses);

    const populate = async () => {
        dispatch(getCourses());
    };

    useEffect(() => {
        populate().then(el => el);
    }, []);

    return (
        <Card>
            <h1>{t("courses.title")}:</h1>

            {isProtectedUser(user) &&
                <Button
                    variant="contained"
                    color="primary"
                    className={'marginBottom8'}
                >
                    <Link to={`${getRouteRole(user)}/courses/new`}>
                        {t("general.create")} {t("courses.titleNew")}
                    </Link>
                </Button>
            }

            {courses &&
                <AccordionCourses
                    courses={courses}
                    showOptions={true}
                />
            }
        </Card>
    );
};

export default ReadCourses;
