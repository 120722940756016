import React from 'react';
import utils, {parseISOString} from "utils/utils";
import TooltipIcon from "components/TooltipIcon";
import {CardHeader, Stack} from "@mui/material";
import {useTranslation} from "react-i18next";
import {SERVER_URI} from "api";
import {useDispatch} from "react-redux";
import {setScrollIntoAnnotation} from "store/modules/Annotations";

const ViewAnnotationHeader = (props) => {
    const {t} = useTranslation();
    const dispatch = useDispatch();

    const getVisibilityIcon = () => {
        switch (props.accessRights) {
            case utils.accessRights.public:
                return 'public';
            case utils.accessRights.private:
                return 'lock';
            case utils.accessRights.moderate:
                return 'shield';
            default:
                return '';
        }
    };

    const getLinkResource = () => {
        const value = props.annotationNow.bodies[0].value['@id'];
        if (utils.isValidUrl(value)) {
            return value;
        } else {
            // it is a resource uuid
            return `${SERVER_URI}/resource/${value}`;
        }
    };

    const getTitle = () => {
        const typeAnnotation = props.getTypeAnnotation();
        const title = `${t(`container.${typeAnnotation}Annotation`)}`;

        if (typeAnnotation === utils.typeAnnotation.free) {
            return `${title} (${t(`annotation.purpose.${props.body.purpose}`)})`;
        }

        return title;
    };

    const goToAnnotation = () => {
        dispatch(setScrollIntoAnnotation(props.annotationNow.id));

        // we click into the #html div, so the popup is closed (to be used inside MUI Modal only)
        // we use it inside ReportAnnotations
        document.querySelector('.MuiBackdrop-root').click();
    };

    return (
        <CardHeader
            className={'move'}
            action={
                <>
                    {
                        // if it is fake, and we are watching only the annotation content, we show the button to go to
                        // the annotation location
                        props.isFake &&
                        <TooltipIcon tooltipText={'annotation.goToAnnotation'} icon={'edit_location_alt'}
                                        onClick={goToAnnotation}
                        />
                    }

                    {
                        props.getTypeAnnotation() === utils.typeAnnotation.semantic &&
                        <TooltipIcon tooltipText={'annotation.seeInstance'} icon={'share'}
                                     onClick={() => window.open(getLinkResource(), "_blank")}
                        />
                    }
                    <TooltipIcon tooltipText={`general.${props.accessRights}`}
                                 icon={getVisibilityIcon()} noCursor/>

                    {
                        // we only show the cancel button if the annotation is not fake
                        !props.isFake &&
                        <TooltipIcon tooltipText={'general.cancel'} icon={'cancel'}
                                     onClick={props.cancel}/>
                    }
                </>
            }
            title={getTitle()}
            subheader={
                <Stack>
                    <span>{props.annotationNow.target.creator.name}</span>
                    <span>{props.annotationNow.target.created ? parseISOString(props.annotationNow.target.created) : ""}</span>
                </Stack>
            }
        />
    );
};

export default ViewAnnotationHeader;