import React, {useEffect, useState} from "react";
import Card from "@mui/material/Card";
import {useParams} from "react-router-dom";
import coursesApi from "api/routes/courses";
import {handleDataServer, successData} from "api";
import Button from "@mui/material/Button";
import AddUserModal from "components/protected/modal/AddUserModal";
import UsersTable from "components/protected/users/UsersTable";
import {useTranslation} from "react-i18next";
import {openConfirmDelete} from "utils/utils";
import EditUserModal from "components/protected/modal/EditUserModal";

const ReadUsers = () => {
    const {t} = useTranslation();

    let {idCourse} = useParams();

    const [users, setUsers] = useState([]);

    const [modalAdd, setModalAdd] = useState(false);

    const [modalEdit, setModalEdit] = useState(false);
    const [userEdit, setUserEdit] = useState();

    const closeModal = async (update) => {
        setModalAdd(false);
        setModalEdit(false);
        if (update) {
            await populate();
        }
    };

    const handleDelete = async (flag, id) => {
        if (flag) {
            let result = await coursesApi.removeUser(idCourse, id);
            await handleDataServer(result, successData);
            await populate();
        }
    };

    const handleEdit = (id) => {
        setUserEdit(users.find(el => el.id === id));
        setModalEdit(true);
    };

    const populateUsers = async () => {
        let courseData = await handleDataServer(await coursesApi.showCourse(idCourse), successData);
        let users = courseData.users;

        if (users) {
            setUsers(users);
        }
    };

    const populate = async () => {
        await populateUsers();
    };

    useEffect(() => {
        populate().then(el => el);
    }, []);

    return (
        <Card>
            <h1>{t("courses.users.title")}</h1>

            <Button
                variant="contained"
                color="primary"
                onClick={() => setModalAdd(true)}
            >
                {t("general.add")}
            </Button>

            <UsersTable
                users={users}
                hasRole={true}
                addActions={true}
                showChips={false}
                handleEdit={handleEdit}
                handleDelete={(id) => openConfirmDelete("users.removeCourse", handleDelete, id)}
            />

            <AddUserModal
                usersAdded={users}
                open={modalAdd}
                handleClose={closeModal}
            />

            <EditUserModal
                open={modalEdit}
                handleClose={closeModal}
                user={userEdit}
            />
        </Card>
    );
};

export default ReadUsers;
