import React, {useEffect} from 'react';
import utils from "utils/utils";
import ViewSemanticSpecific from "components/container/recogito/popup/ViewAnnotation/ViewSemanticSpecific";
import {useTranslation} from "react-i18next";

const ViewAnnotationContent = (props) => {
    const {t} = useTranslation();

    return (
        <>
            <div className={'dashed'}>
                <h3>{t('annotation.annotatedText')}</h3>
                {props.annotationNow.target.selector[0].quote}
            </div>

            {
                // Show specific info about free/semantic annotation
                props.getTypeAnnotation() === utils.typeAnnotation.free
                && props.body.purpose && props.body.purpose !== 'classifying' ?
                    // free
                    <div>
                        <b><code>{props.body.title}</code></b> <br/>
                        <div dangerouslySetInnerHTML={{__html: props.body.value}}></div>
                    </div> :

                    // semantic
                    <ViewSemanticSpecific {...props} />
            }
        </>
    );
};

export default ViewAnnotationContent;