import {useSelector} from "react-redux";
import {useEffect, useState} from "react";
import {selectContainer} from "store/modules/Container";

const usePermissionCourse = () => {
    const container = useSelector(selectContainer);
    const [permission, setPermission] = useState({});

    useEffect(() => {
        if (container) {
            setPermission(container.activity.permission);
        }
    }, [container]);

    return permission;
};

export default usePermissionCourse;
