import React, {useEffect, useState} from 'react';
import {Stack} from "@mui/material";
import {useTranslation} from "react-i18next";
import Typography from "@mui/material/Typography";
import RolesSelect from "components/protected/RolesSelect/RolesSelect";
import Button from "@mui/material/Button";
import coursesApi from 'api/routes/courses';
import usersApi from 'api/routes/users';
import {handleDataServer, successData} from "api";
import {useParams} from "react-router-dom";
import ModalClose from "components/protected/modal/ModalClose";

const EditUserModal = (
    {
        open,
        handleClose,
        isCourse = true,
        user = {}
    }
) => {
    const {t} = useTranslation();
    const [role, setRole] = useState("");
    let {idCourse} = useParams();

    const handleSubmit = async (e) => {
        e.preventDefault();

        // check if field is fulfilled and it is different from the current role
        if (role && (isCourse ? role !== user.relation.role_id : role !== user.role_id)) {
            let result = isCourse ?
                await coursesApi.updateUser(idCourse, user.id, role) :
                await usersApi.changeRole(user.id, role);

            let data = await handleDataServer(result, successData);
            if (data) {
                handleClose(true);
            }
        }
    };

    useEffect(() => {
        if (open) {
            setRole(isCourse ? user.relation.role_id : user.role_id);
        }
    }, [open]);

    return (
        <ModalClose
            open={open}
            onClose={() => handleClose(false)}
        >
            <>
                <Typography variant={'h6'} component={'h2'}>
                    {t('users.edit.title')} {user.name}
                </Typography>
                <div>
                    <Stack direction={'row'} justifyContent={'space-evenly'}>
                        <RolesSelect role={role} setRole={setRole} isCourse={isCourse}/>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleSubmit}
                        >
                            {t("general.edit")}
                        </Button>
                    </Stack>
                </div>
            </>
        </ModalClose>
    );
};

export default EditUserModal;
