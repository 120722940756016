import {createTheme} from "@mui/material/styles";

export default createTheme({
    palette: {
        primary: {
            main: '#5a5a5a',
            contrastText: "#fff"
        },
        secondary: {
            main: '#b7b7b7',
        },
    },
});
