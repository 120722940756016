import React, {useState} from 'react';
import {useSelector} from "react-redux";
import {selectResources} from "store/modules/Container";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import {FormControl, Stack} from "@mui/material";
import {v4 as uuid} from 'uuid';
import semantic from "utils/semantic";
import TooltipIcon from "components/TooltipIcon";
import utils from "utils/utils";
import ModalAddResource from "components/container/recogito/ModalAddResource";

const SemanticRelation = (
    {
        relation,
        onChange,
        values,
        classObj,
        isEdit
    }
) => {
    // resources
    const resources = useSelector(selectResources);
    const resourcesRelation = resources.filter(el => el.class_semantic_id === relation.range.id);

    // handle creation of new resource/semantic annotation
    const [isAdd, setIsAdd] = useState(false);

    const getResourcesNotSelected = () => {
        return resourcesRelation.filter(el => getValueNow().findIndex(value => utils.getBasename(value['@id']) === el.id) === -1);
    };

    const getValueNow = () => {
        const tag = semantic.getTagFromProperty(relation);
        return values ? values[tag] ? values[tag] : [] : [];
    };

    const getSelect = (value) => {
        const uuidLabel = uuid();
        const valueNow = value === "" ? "" : utils.getBasename(value['@id']);

        const itemsItem = getResourcesNotSelected();
        if (valueNow !== "") {
            itemsItem.push(resourcesRelation.find(el => el.id === valueNow));
        }

        return (
            <FormControl
                variant="outlined"
                className={'width100 marginBottom8'}
            >
                <InputLabel id={`select-${uuidLabel}`} className={'capitalize'}>
                    {relation.display}
                </InputLabel>
                <Select
                    className={'width100'}
                    labelId={`select-${uuidLabel}`}
                    label={relation.display}
                    name={`${relation.id}`}
                    value={valueNow}
                    onChange={(e) => onChange(e.target.value, relation, valueNow, 'update')}
                >
                    {
                        itemsItem.map((el, index) => (
                            <MenuItem key={index} value={el.id}>{el.value_search}</MenuItem>
                        ))
                    }
                </Select>
            </FormControl>
        );
    };

    const handleDelete = (value, index) => {
        onChange(index, relation, value, 'delete');
    };

    const handleAdd = () => {
        setIsAdd(true);
    };

    const handleCloseAdd = () => {
        setIsAdd(false);
    };

    return (
        <>
            <div className={'border-top-bottom'}>
                {/* iterate all relations */}
                {getValueNow().map((value, index) => (
                    <Stack key={index} direction={'row'} alignItems={'center'}>
                        {getSelect(value)}
                        <TooltipIcon tooltipText={'general.delete'} icon={'delete'}
                                     onClick={() => handleDelete(value, index)}/>
                    </Stack>
                ))}

                {/* always have one more select (relations are many-to-many) */}
                <Stack direction={'row'} alignItems={'center'}>
                    {getSelect("")}
                    <TooltipIcon tooltipText={'general.add'} icon={'add'}
                                 onClick={handleAdd}/>
                </Stack>
            </div>

            {/* Modal to handle adding a resource through SemanticAnnotation  */}
            <ModalAddResource
                open={isAdd}
                onChange={onChange}
                relation={relation}
                onClose={handleCloseAdd}
            />
        </>
    );
};

export default SemanticRelation;