import api from '../api';
import {failureCallback, JSON_CONTENT_TYPE, successCallback} from "../index";

export default {
    addOntology: async(name, description, isPublic) => {
        return await api.post('/api/ontologies/store', {
            name: name,
            description: description,
            isPublic: isPublic
        }).then(successCallback, failureCallback);
    },
    updateOntology: async(idOntology, name, description, isPublic) => {
        return await api.post('/api/ontologies/update', {
            name: name,
            description: description,
            isPublic: isPublic
        }, {
            params: {
                id: idOntology
            }
        }).then(successCallback, failureCallback);
    },
    duplicateOntology: async(idOntology) => {
        return await api.post('/api/ontologies/duplicate', {}, {
            params: {
                id: idOntology
            }
        }).then(successCallback, failureCallback);
    },
    showOntology: async(idOntology) => {
        return await api.get(`/api/ontologies/show`, {
            params: {
                id: idOntology
            }
        }).then(successCallback, failureCallback);
    },
    getOntologies: async() => {
        return await api.get('/api/ontologies/index')
            .then(successCallback, failureCallback);
    },
    deleteOntology: async(idOntology) => {
        return await api.delete('/api/ontologies/delete', {
            params: {
                id: idOntology
            }
        }).then(successCallback, failureCallback);
    },

    // semantic schemes
    saveScheme: async(idOntology, schema) => {
        return await api.post('/api/ontologies/schemes/store', schema, {
            params: {
                id: idOntology
            },
            headers: { JSON_CONTENT_TYPE },
            data: schema
        }).then(successCallback, failureCallback);
    },
};
