import React from "react";
import utils, {isStudent} from "utils/utils";
import Template from "pages/common/Template";
import {Navigate} from "react-router-dom";
import useAuth from "hooks/useAuth";

const ProtectedStudent = () => {
    const user = useAuth();

    return (
        <>
            {isStudent(user) ?
                <Template/> :
                <Navigate to={'/'} />
            }
        </>
    );
};

export default ProtectedStudent;
