const semantic = {
    // https://www.golinuxcloud.com/javascript-recursive-search-array-of-objects/
    recursiveFind: (array, element, type, history = [], first = false, display = undefined, sameAs = undefined) => {
        if (array === undefined || element === undefined) return;

        const isFindByDisplay = display !== undefined && sameAs !== undefined;

        for (let obj of array) {
            if (first) {
                history = [];
            }

            // checking if the object is the same as the element or if the display and sameAs
            // are the same (if isFindByDisplay is true)
            if (obj === element ||
                (isFindByDisplay && obj.display === display && obj.sameAs === sameAs)
            ) {
                switch (type) {
                    case "array":
                        return [array, history];
                    case "object":
                        return [obj, history];
                }
            }

            if (obj.children) {
                history.push(obj);
                let result = recursiveFind(obj.children, element, type, history, false, display, sameAs);
                let resultParse = Array.isArray(result) ? result[0] : result;

                if (result) {
                    return [resultParse, history];
                }
            }
        }

        return undefined;
    },
    recursiveFindArray: (array, element) => {
        return recursiveFind(array, element, 'array', [], true)[0];
    },
    recursiveFindArrayWithHistory: (array, element) => {
        return recursiveFind(array, element, 'array', [], true);
    },
    recursiveFindObject: (array, element) => {
        return recursiveFind(array, element, 'object', [], true)[0];
    },
    recursiveFindObjectWithHistory: (array, element) => {
        return recursiveFind(array, element, 'object', [], true);
    },
    getPropertiesRelations: (array, element) => {
        const [find, history] = recursiveFindObjectWithHistory(array, element);

        // add the object found to the last of history, to get their properties and relations
        history.push(find);

        let properties = [];
        let relations = [];

        history.forEach((el) => {
            if (el.properties) {
                properties.push(...el.properties);
            }

            if (el.relations) {
                relations.push(...el.relations);
            }
        });

        return [properties, relations];
    },
    getTagFromProperty: (property) => {
        return property.display;
    },
};

export default semantic;
export const {
    recursiveFind,
    recursiveFindArray,
    recursiveFindObject,
    recursiveFindArrayWithHistory,
    recursiveFindObjectWithHistory,
    getPropertiesRelations,
    getTagFromProperty
} = semantic;
