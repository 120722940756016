import React, {useEffect, useRef, useState} from 'react';
import './style.css';
import {useTranslation} from "react-i18next";

const TreeEntry = (
    {
        entry = {},
        depth = 1,
        onSelected,
        selected,
        changeAttributeSelected,
        readonly = false,
        blocked = false
    }
) => {
    const {t} = useTranslation();
    const [isExpanded, setIsExpanded] = useState(false);
    const inputText = useRef();

    const handleSelect = (entry) => {
        if (blocked) return;

        onSelected(entry);
    };

    return (
        <>
            <button
                className={selected === entry || selected.id === entry.id ? 'selected' : ""}
                style={{ cursor: blocked ? 'default' : "pointer" }}
                onClick={() => handleSelect(entry)}
            >
                {entry.children.length > 0 &&
                    <div
                        style={{paddingLeft: '6px', paddingRight: '6px', width: '20px'}}
                        onClick={() => setIsExpanded(prev => !prev)}
                    >
                        {isExpanded ? '-' : '+'}
                    </div>
                }
                <input
                    ref={inputText}
                    type="text"
                    placeholder={t('semantic.typeClass')}
                    className={'inputEntry name'}
                    style={{paddingLeft: entry.children.length > 0 ? "" : "20px"}}
                    value={entry.display}
                    onChange={(e) => changeAttributeSelected('display', e.target.value)}
                    readOnly={readonly}
                />
            </button>
            <div style={{borderLeft: '1px solid black', margin: '5px 5px'}}>
                {isExpanded && (
                    <div style={{paddingLeft: '5px'}}>
                        {entry.children?.map((child, index) =>
                            <TreeEntry key={index} entry={child} depth={depth + 1} onSelected={onSelected}
                                       selected={selected} changeAttributeSelected={changeAttributeSelected}
                                        readonly={readonly}
                            />
                        )}
                    </div>
                )
                }
            </div>
        </>
    );
};

export default TreeEntry;
