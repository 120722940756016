import React from "react";
import {isProtectedUser} from "utils/utils";
import Template from "pages/common/Template";
import {Navigate} from "react-router-dom";
import useAuth from "hooks/useAuth";

/**
 * Protected component to use for admin and teacher roles
 * @returns {JSX.Element} Protected component
 * @constructor
 */
const Protected = () => {
    const user = useAuth();

    return (
        <>
            {isProtectedUser(user) ?
                <Template/> :
                <Navigate to={'/'}/>
            }
        </>
    );
};

export default Protected;
