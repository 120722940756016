import {decode} from "html-entities";
import store from "store/store";
import {setError} from "store/modules/Error";
import {setToken, setUser} from "store/modules/User";

export const SERVER_URI = process.env.REACT_APP_SERVER_URI;

export const MULTIPART_FORM_DATA = {
    "Content-Type": "multipart/form-data"
};

export const JSON_CONTENT_TYPE = {
    "Content-Type": "application/json"
};

export const successCallback = (response) => {
    return response;
};

export const successData = (response) => {
    return response.data;
};

export const successStatus = (response) => {
    return response.status;
};

export const failureCallback = (error) => {
    store.dispatch(setError(decode(error)));
    return error.response;
};

export const handleSuccessData = async(dataServer) => {
    return dataServer.status === true;
};

const NOT_AUTHENTICADED = "Unauthenticated";

const logout = () => {
    setTimeout(() => {
        store.dispatch(setUser(false));
        store.dispatch(setToken(''));

        window.location.href = "/";
    }, 1);
};

export const handleDataServer = async (dataServer, func) => {
    if (dataServer && dataServer.data && dataServer.data.status) {
        return await func(dataServer.data);
    } else if (dataServer && dataServer.data && !dataServer.data.status) {
        const data = dataServer.data;

        try {
            let errorString = data.message;

            if (errorString === NOT_AUTHENTICADED) {
                logout();
                return;
            }

            errorString += ` | Status ${dataServer.status}`;

            store.dispatch(setError(errorString));
        } catch (e) {
            console.log(dataServer, e);
        }
    } else {
        console.log(dataServer);
        window.alert("Ops, não foi possível fazer a chamada para o sistema. Tente novamente mais tarde.");
    }
    return null;
};
