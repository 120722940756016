import React, {useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {selectTypeAnnotation, setTypeAnnotation} from "store/modules/Annotations";
import utils from "utils/utils";
import FreeAnnotation from "components/container/recogito/popup/FreeAnnotation";
import CommonAnnotation from "components/container/recogito/popup/CommonAnnotation";
import SemanticAnnotation from "components/container/recogito/popup/SemanticAnnotation";
import ViewCommonAnnotation from "components/container/recogito/popup/ViewAnnotation/ViewCommonAnnotation";

const MainPopup = (
    {
        isOpen,
        selected = [],
        onClose = () => {
        },
        onAnnotationClick = () => {
        }
    }
) => {
    const annotation = selected[0]?.annotation;
    const bodies = annotation?.bodies;
    const dispatch = useDispatch();

    const typeAnnotation = useSelector(selectTypeAnnotation);

    useEffect(() => {
        if (isOpen && selected.length && typeAnnotation === "") {
            // it is reading, it is necessary to set the type of annotation
            if (annotation && bodies && bodies.length) {
                const purpose = bodies[0]?.purpose;
                if (purpose === "classifying") {
                    dispatch(setTypeAnnotation(utils.typeAnnotation.semantic));
                } else {
                    dispatch(setTypeAnnotation(utils.typeAnnotation.free));
                }
            }
        }
    }, [isOpen, selected, typeAnnotation]);

    return (
        typeAnnotation !== "" ?
            <CommonAnnotation
                selected={selected}
                children={props => (
                    props.isFirst() ?
                        typeAnnotation === utils.typeAnnotation.free ?
                            <FreeAnnotation {...props} /> :
                            <SemanticAnnotation {...props} /> :
                        <ViewCommonAnnotation {...props} />
                )}
            /> :
            null
    );
};

export default MainPopup;