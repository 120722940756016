import React, {useEffect, useState} from 'react';
import Card from "@mui/material/Card";
import {CardActions, CardContent, CardHeader, Stack} from "@mui/material";
import TextField from "@mui/material/TextField";
import TooltipIcon from "components/TooltipIcon";
import {v4 as uuid} from 'uuid';
import utils from "utils/utils";
import {useDispatch} from "react-redux";
import {setMustClose} from "store/modules/Annotations";

/**
 * A reply to annotation comment. This component must be used inside the
 * CommonAnnotation component.
 * @param props Functions passed thorugh CommonAnnotation
 * @returns {JSX.Element}
 * @constructor
 */
const ReplyAnnotation = (props) => {
    const [reply, setReply] = useState("");

    const dispatch = useDispatch();

    const defaultBody = {
        id: uuid(),
        annotation: props.annotationNow.id,
        type: 'TextualBody',
        purpose: 'commenting',
        creator: utils.getCreatorAnnotation()
    };

    const handleChangeReply = (value) => {
        setReply(value);
        props.changeBody('value', value);
    };

    const handleSave = () => {
        const flag = props.save();

        if (flag) {
            props.closeReply();
        }
    };

    useEffect(() => {
        props.setIsReply(true);

        if (!props.replyObj) {
            props.setBody({...defaultBody});
            setReply("");
        } else {
            props.setBody({...props.replyObj});
            setReply(props.replyObj.value);
        }

        dispatch(setMustClose(false));
    }, [props.replyObj]);

    return (
        <Card>
            <CardHeader
                title={'Reply'}
            />
            <CardContent sx={{padding: '5px'}}>
                <TextField
                    variant={'outlined'}
                    label={'Reply'}
                    size={'small'}
                    required={true}
                    value={reply}
                    onChange={(e) => handleChangeReply(e.target.value)}
                />
            </CardContent>
            <CardActions disableSpacing sx={{justifyContent: 'space-between'}}>
                <TooltipIcon tooltipText={'general.delete'} icon={'delete'} onClick={props.deleteBody}/>

                <Stack direction={'row'}>
                    <TooltipIcon tooltipText={'general.cancel'} icon={'cancel'} onClick={props.closeReply}/>
                    <TooltipIcon tooltipText={'general.save'} icon={'save'} onClick={handleSave}/>
                </Stack>
            </CardActions>
        </Card>
    );
};

export default ReplyAnnotation;