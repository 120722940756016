import React from 'react';
import {useSelector} from "react-redux";
import {selectContainer} from "store/modules/Container";
import SemanticProperty from "components/container/recogito/SemanticProperty";
import SemanticRelation from "components/container/recogito/SemanticRelation";

const AnnotateSemantic = (
    {
        annotationSelected,
        classSelected,
        onChangeProperty,
        onChangeRelation,
        values,
        isEdit
    }
) => {
    const container = useSelector(selectContainer);
    const ontology = container.activity.ontology;
    const classes = ontology.classes;

    const classObj = classSelected ? classes.find(el => el.id === classSelected.id) : false;

    return (
        <>
            {classObj ?
                <>
                    <div className={'width100'} style={{paddingRight: '8px'}}>
                        {classObj.properties.map((property, index) => (
                            <div key={index} className={'marginTop8'}>
                                <SemanticProperty
                                    property={property}
                                    onChange={onChangeProperty}
                                    values={values}
                                    classObj={classObj}
                                    isEdit={isEdit}
                                />
                            </div>
                        ))}

                        {classObj.relations.map((relation, index) => (
                            <div key={index} className={'marginTop8'}>
                                <SemanticRelation
                                    relation={relation}
                                    onChange={onChangeRelation}
                                    values={values}
                                    classObj={classObj}
                                    isEdit={isEdit}
                                />
                            </div>
                        ))}
                    </div>
                </> :
                null
            }
        </>
    );
};

export default AnnotateSemantic;