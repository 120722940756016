import React, {PointerEvent, useCallback, useEffect, useState} from 'react';
import {useAnnotator, useSelection} from '@annotorious/react';
import {
    autoUpdate,
    flip,
    inline,
    offset,
    shift,
    size,
    useDismiss,
    useFloating,
    useInteractions,
    useRole
} from '@floating-ui/react';
import './style.css';

/**
 * Customized popup for text-annotator-js (inspired by the original one).
 * It is responsible for rendering the popup and managing its visibility.
 * When the popup is closed, onClose is called, so the callee can perform any necessary cleanup.
 * @param popup
 * @param openOnAnnotation
 * @param openClick
 * @param onClose
 * @param onAnnotationClick
 * @returns {JSX.Element|null}
 * @constructor
 */
const CustomPopup = (
    {
        popup,
        openOnAnnotation = true,
        openClick = false,
        onClose = () => {
        },
        onAnnotationClick = () => {
        }
    }
) => {
    const REASONS_CLOSE = ['escape-key', 'outside-press'];
    const MOBILE_WIDTH = 800;

    const annotator = useAnnotator();
    const {selected, event} = useSelection();
    const annotation = selected[0]?.annotation;

    const [isOpen, setOpen] = useState(openOnAnnotation && selected?.length > 0);

    const [mouseDown, setMouseDown] = useState(false);

    const {refs, floatingStyles, update, context} = useFloating({
        placement: 'top',
        open: isOpen,
        onOpenChange: (open, _event, reason) => {
            setOpen(open);

            if (!open && !annotation) {
                setMouseDown(false);
                onClose();
                annotator?.cancelSelected();
            }

            if (!open && REASONS_CLOSE.includes(reason)) {
                setMouseDown(false);
                onClose();
                annotator?.cancelSelected();
            }
        },
        middleware: [
            offset(10),
            inline(),
            flip(),
            shift({mainAxis: false, crossAxis: true, padding: 10}),
            size({
                apply({availableWidth, availableHeight, ...state}) {
                    if (!refs.floating.current) return;

                    if (availableWidth <= MOBILE_WIDTH) {
                        Object.assign(refs.floating.current.style, {
                            position: 'fixed',

                            // handle width
                            width: '350px',
                            maxWidth: '350px',
                            transform: 'inherit',
                            left: `${availableWidth / 2 - 175}px`,

                            // handle height
                            height: '300px',
                            overflowY: 'scroll',
                            top: `${availableHeight / 2 - 150}px`,
                        });
                    } else {
                        Object.assign(refs.floating.current.style, {
                            position: 'absolute',
                            width: 'inherit',
                            height: 'inherit',
                            maxWidth: 'inherit',
                            maxHeight: 'inherit',
                            left: '0',
                            top: '0',
                            overflowY: 'auto'
                        });
                    }
                },
            })
        ],
        whileElementsMounted: autoUpdate
    });

    const dismiss = useDismiss(context);
    const role = useRole(context, {role: 'tooltip'});
    const {getFloatingProps} = useInteractions([dismiss, role]);
    const selectedKey = selected.map(a => a.annotation.id).join('-');

    useEffect(() => {
        // Ignore all selection changes except those accompanied by a pointer event.
        if (event) {
            const hasAnnotation = selected.length > 0 && event.type === 'pointerup';
            if (hasAnnotation && annotator.isAnnotationClick() && !openOnAnnotation) {
                onAnnotationClick();
            }

            setOpen(openOnAnnotation && hasAnnotation);
        }
    }, [event?.type, selectedKey]);

    useEffect(() => {
        if (!isOpen && annotation === undefined) {
            setMouseDown(false);
            onClose();
            annotator?.cancelSelected();
        }

        if (!isOpen || !annotation) return;

        const {
            target: {
                selector: [{range}]
            }
        } = annotation;

        refs.setPositionReference({
            getBoundingClientRect: range.getBoundingClientRect.bind(range),
            getClientRects: range.getClientRects.bind(range)
        });
    }, [isOpen, annotation, refs]);

    useEffect(() => {
        setOpen(!openOnAnnotation && openClick);
        setMouseDown(false);
    }, [openClick]);

    // Prevent text-annotator from handling the irrelevant events triggered from the popup
    const getStopEventsPropagationProps = useCallback(
        () => ({
            onPointerUp: (event: PointerEvent<HTMLDivElement>) => {
                event.stopPropagation();
            }
        }),
        []
    );

    useEffect(() => {
        const config = {attributes: true, childList: true, subtree: true};

        const mutationObserver = new MutationObserver(() => update());
        mutationObserver.observe(document.body, config);

        window.document.addEventListener('scroll', update, true);

        return () => {
            mutationObserver.disconnect();
            window.document.removeEventListener('scroll', update, true);
        };
    }, [update]);

    const onMouseDown = (e) => {
        setMouseDown(true);
    };

    const onMouseUp = (e) => {
        setMouseDown(false);
    };

    const onMouseMove = (e) => {
        const floatingEl = refs.floating.current;
        if (!floatingEl) return;

        if (mouseDown) {
            const style = floatingEl.style.transform;
            let [x, y] = style.replace("translate(", "")
                .replace(")", "")
                .replaceAll("px", "")
                .split(", ")
                .map(el => parseFloat(el));

            Object.assign(refs.floating.current.style, {
                transform: `translate(${x + e.movementX}px, ${y + e.movementY}px)`
            });
        }
    };

    return isOpen && selected.length > 0 ? (
        <div onMouseUp={onMouseUp} onMouseDown={onMouseDown} onMouseMove={onMouseMove}>
            <div
                className="zIndexPopup floating"
                ref={refs.setFloating}
                style={floatingStyles}
                {...getFloatingProps()}
                {...getStopEventsPropagationProps()}>
                {popup({selected, onClose, onAnnotationClick, isOpen})}
            </div>
        </div>
    ) : null;
};

export default CustomPopup;
