import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {handleDataServer, successData} from "api";
import documentsApi from 'api/routes/documents';

const documentsSlicer = createSlice({
    name: 'documents',
    initialState: {
        documents: [],
    },
    reducers: {
        setDocuments: (state, action) => {
            state.documents = action.payload;
        },
    },
    extraReducers(builder) {
        // get documents
        builder
            .addCase(getDocuments.fulfilled, (state, action) => {
                state.documents = action.payload;
            });
    }
});

// thunk actions (async)
export const getDocuments = createAsyncThunk("documents/read",
    async (thunkAPI) => {
        const documentsData = await handleDataServer(await documentsApi.getDocuments(), successData);
        if (documentsData) {
            return documentsData;
        }
    });

// getters
export const selectDocuments = (state) => state.documents.documents;

// actions
export const {setDocuments} = documentsSlicer.actions;

// slice
export default documentsSlicer;
