import {useTranslation} from "react-i18next";
import {IconButton, Tooltip} from "@mui/material";
import Icon from "@mui/material/Icon";
import React from "react";

const TooltipIcon = (
    {
        disabled = false,
        noCursor = false,
        tooltipText,
        icon,
        color = 'inherit',
        onClick = () => {
        }
    }
) => {
    const {t} = useTranslation();

    return (
        <Tooltip title={t(tooltipText)} >
            <span>
                <IconButton disabled={disabled} onClick={onClick} className={noCursor ? 'noCursor' : ""}>
                    <Icon color={color}>{icon}</Icon>
                </IconButton>
            </span>
        </Tooltip>
    );
};

export default TooltipIcon;
