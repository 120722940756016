import React from 'react';
import {ACTION, Can} from "ability";
import CommonAnnotation from "components/container/recogito/popup/CommonAnnotation";
import TagSelector from "components/container/recogito/TagSelector";

const ViewAnnotationTags = (props) => {
    return (
        // Show/edit tags -> only the owner of the annotation can do that
        <Can I={ACTION.UPDATE} this={props.objectAnnotation}>
            <CommonAnnotation
                isFake={props.isFake}
                selected={props.selected}
                children={props1 => (
                    <TagSelector {...props1} />
                )}
            />
        </Can>
    );
};

export default ViewAnnotationTags;