import api from "../api";
import {failureCallback, successCallback} from "../index";

export default {
    addCourse: async (name) => {
        return await api.post('/api/courses/store', {
            name: name,
        }).then(successCallback, failureCallback);
    },
    getCourses: async () => {
        return await api.get('/api/courses/index')
            .then(successCallback, failureCallback);
    },
    showCourse: async (idCourse) => {
        return await api.get('/api/courses/show', {
            params: {
                id: idCourse
            }
        }).then(successCallback, failureCallback);
    },
    updateCourse: async (idCourse, name) => {
        return await api.post('/api/courses/update', {
            name: name
        }, {
            params: {
                id: idCourse
            }
        }).then(successCallback, failureCallback);
    },
    deleteCourse: async (idCourse) => {
        return await api.delete('/api/courses/delete', {
            params: {
                id: idCourse
            }
        }).then(successCallback, failureCallback);
    },

    // course users
    addUser: async (idCourse, idUser, role) => {
        return await api.post('/api/courses/users/add', {
            user_id: idUser,
            role_id: role
        }, {
            params: {
                id: idCourse,
            }
        }).then(successCallback, failureCallback);
    },
    removeUser: async (idCourse, idUser) => {
        return await api.post('/api/courses/users/remove', {
            user_id: idUser
        }, {
            params: {
                id: idCourse,
            }
        }).then(successCallback, failureCallback);
    },
    updateUser: async (idCourse, idUser, role) => {
        return await api.post('/api/courses/users/update', {
            user_id: idUser,
            role_id: role
        }, {
            params: {
                id: idCourse,
            }
        }).then(successCallback, failureCallback);
    },
};
