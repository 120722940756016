import React, {useState} from 'react';
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import LibraryBooksIcon from "@mui/icons-material/LibraryBooks";
import ListItemText from "@mui/material/ListItemText";
import {useNavigate} from "react-router-dom";
import {Stack} from "@mui/material";
import imageMoodle from 'img/moodle.png';
import ModalMoodle from "components/protected/modal/ModalMoodle";
import useAuth from "hooks/useAuth";
import utils from "utils/utils";

const ListDocuments = (
    {
        documents = []
    }
) => {
    const user = useAuth();
    const navigate = useNavigate();

    const [modalMoodle, setModalMoodle] = useState(false);
    const [documentMoodle, setDocumentMoodle] = useState({});

    const closeModal = () => {
        setModalMoodle(false);
        setDocumentMoodle({});
    };

    const openModal = (document) => {
        setDocumentMoodle(document);
        setModalMoodle(true);
    };

    return (
        <>
            {documents.map((document, i) =>
                <Stack direction={'row'} alignItems={'center'} key={i}>
                    {
                        utils.isProtectedUser(user) &&
                        <img
                            onClick={() => openModal(document)}
                            src={imageMoodle}
                            alt={'Moodle'}
                            title={'Moodle'}
                            width={'40px'}
                            height={'40px'}
                            className={'fade pointer'}
                        />
                    }
                    <ListItemButton
                        component="a"
                        onClick={() => navigate(`/container/${document.container.id}/show`)}
                    >
                        <ListItemIcon>
                            <LibraryBooksIcon/>
                        </ListItemIcon>
                        <ListItemText primary={document.title}/>
                    </ListItemButton>
                </Stack>
            )}

            <ModalMoodle
                open={modalMoodle}
                document={documentMoodle}
                handleClose={closeModal}
            />
        </>
    );
};

export default ListDocuments;
