import React, {useEffect, useState} from "react";
import coursesApi from 'api/routes/courses';
import {useNavigate, useParams} from "react-router-dom";
import {handleDataServer} from "api";
import {useDispatch, useSelector} from "react-redux";
import {getCourses, selectCourses} from "store/modules/Courses";
import {useTranslation} from "react-i18next";
import GenericAddDescription from "components/protected/GenericAddDescription";
import {getRouteRole} from "utils/utils";
import useAuth from "hooks/useAuth";

/**
 * Faz a inserção/atualização de um Curso
 * @returns {JSX.Element} Elemento de criação/atualização de um Curso
 * @constructor Sem parâmetros do component, pois são pegos pela url {@see routes.js}
 */
const NewCourse = () => {
    const user = useAuth();
    const {t} = useTranslation();
    const navigate = useNavigate();
    const [name, setName] = useState("");
    const [loaded, setLoaded] = useState(false);
    const dispatch = useDispatch();

    let {idCourse} = useParams();
    const isEdit = idCourse !== undefined;

    const courses = useSelector(selectCourses);
    let course;

    const getCourse = () => {
        return isEdit ? courses.find(el => el.id === parseInt(idCourse)) : null;
    };

    const addCourseAux = async (response) => {
        if (response.status) {
            navigate(getRouteRole(user));
        }
    };

    const addCourse = async () => {
        let result = isEdit ?
            await coursesApi.updateCourse(idCourse, name) :
            await coursesApi.addCourse(name);

        await handleDataServer(result, addCourseAux);
    };

    useEffect(() => {
        if (!loaded) {
            dispatch(getCourses());
            setLoaded(true);
        }

        course = getCourse();

        if (isEdit && course) {
            setName(course.name);
        }
    }, [courses]);

    return (
        <GenericAddDescription
            isEdit={isEdit}
            nameTitle={t("courses.titleNew")}
            name={name}
            setName={(e) => setName(e.target.value)}
            handleSubmit={addCourse}
            hasDescription={false}
        />
    );
};

export default NewCourse;
