import React from 'react';
import {Fab, Stack, Tooltip} from "@mui/material";
import {AccountTree, StickyNote2} from "@mui/icons-material";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {
    selectHasSelection,
    selectIsAnnotating,
    selectIsHidden,
    setIsAnnotating,
    setTypeAnnotation
} from "store/modules/Annotations";
import utils from "utils/utils";
import {ACTION, Can} from 'ability';

const ActionsContainer = () => {
    const {t} = useTranslation();

    const dispatch = useDispatch();
    const isAnnotating = useSelector(selectIsAnnotating);
    const isHidden = useSelector(selectIsHidden);
    const hasSelection = useSelector(selectHasSelection);

    const freeAnnotation = () => {
        if (!isAnnotating && !isHidden) {
            dispatch(setIsAnnotating(true));
            dispatch(setTypeAnnotation(utils.typeAnnotation.free));
        }
    };

    const semanticAnnotation = () => {
        if (!isAnnotating && !isHidden) {
            dispatch(setIsAnnotating(true));
            dispatch(setTypeAnnotation(utils.typeAnnotation.semantic));
        }
    };

    const isDisabled = () => {
        return isAnnotating || isHidden || !hasSelection;
    };

    return (
        <Can I={ACTION.ACTION} a={'createAnnotations'}>
            <Stack sx={{
                position: 'fixed',
                right: 0,
                top: 70,
                margin: '10px',
                zIndex: 2
            }}>
                <Can I={ACTION.ACTION} a={'createFree'}>
                    <Tooltip
                        title={t('container.freeAnnotation')}
                        placement={'left'}
                        onClick={freeAnnotation}
                    >
                        <span>
                            <Fab color={'primary'} disabled={isDisabled()}>
                                <StickyNote2/>
                            </Fab>
                        </span>
                    </Tooltip>
                </Can>
                <Can I={ACTION.ACTION} a={'createSemantic'}>
                    <Tooltip
                        title={t('container.semanticAnnotation')}
                        placement={'left'}
                        sx={{marginTop: '10px'}}
                        onClick={semanticAnnotation}
                    >
                        <span>
                            <Fab color={'secondary'} disabled={isDisabled()}>
                                <AccountTree/>
                            </Fab>
                        </span>
                    </Tooltip>
                </Can>
            </Stack>
        </Can>
    );
};

export default ActionsContainer;
