import React, {useEffect, useState} from "react";
import {Link, useNavigate, useSearchParams} from "react-router-dom";
import loginApi from "api/routes/login";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import {useTranslation} from "react-i18next";
import {handleDataServer, successCallback} from "api";
import utils from "utils/utils";
import {setError} from "store/modules/Error";
import {useDispatch} from "react-redux";
import useAuth from "hooks/useAuth";
import {setBearerToken} from "api/api";
import store from "store/store";
import {setToken, setUser} from "store/modules/User";

const Login = () => {
    const {t, i18n} = useTranslation();
    const [email, setEmail] = useState("");
    const [pass, setPassword] = useState("");
    const navigate = useNavigate();
    const user = useAuth();
    const dispatch = useDispatch();

    // if we have an external authentication, backend will redirect to /:authentication, which will be handled here
    // authentication must be a json with a web token and a user object, just like loginApi.login() returns
    const [searchParams, setSearchParams] = useSearchParams();

    async function handleSubmit(e) {
        e.preventDefault();
        const currentLanguage = i18n.language.substring(0, 2);
        const dataLogin = await handleDataServer(await loginApi.login(email, pass, currentLanguage), successCallback);

        login(dataLogin);
    }

    const login = (dataLogin) => {
        if (dataLogin.status) {
            const userNow = dataLogin.user;

            store.dispatch(setUser(userNow));
            store.dispatch(setToken(dataLogin.token));
            setBearerToken(dataLogin.token);

            // if there is the container id in the request, go for it
            if (dataLogin.container_id) {
                navigate(`/container/${dataLogin.container_id}/show`);
                return;
            }

            // else, we only redirect to the user dashboard
            if (userNow.role in utils.rolesNames) {
                navigate(`/${utils.rolesNames[userNow.role]}`);
            }
        }
    };

    useEffect(() => {
        if (user) {
            if (user.role in utils.rolesNames) {
                navigate(`/${utils.rolesNames[user.role]}`);
            } else {
                const logout = async () => {
                    await loginApi.logout();
                    navigate("/");
                };

                dispatch(setError(t("auth.roleFailed")));
                logout().then(r => r);
            }
        }
    }, []);

    useEffect(() => {
        // if authentication is not null, we have an external authentication
        if (!searchParams) return;

        const authentication = searchParams.get("authentication");
        if (!authentication) return;

        const authenticationParse = JSON.parse(authentication);
        login(authenticationParse.original);
    }, [searchParams]);

    return (
        <form
            className={'auth-form'}
            onKeyDown={(e) => e.key === "Enter" && handleSubmit(e)}
        >
            <Typography variant="p" component="div" style={{marginBottom: 25}}>
                Login
            </Typography>

            <TextField
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                label={t("general.email")}
                variant="outlined"
                className={'marginBottom8'}
            />
            <TextField
                value={pass}
                onChange={(e) => setPassword(e.target.value)}
                label={t("general.password")}
                type="password"
                variant="outlined"
                className={'marginBottom8'}
            />

            <Button
                onClick={handleSubmit}
                variant="contained"
                color="primary"
            >
                {t("auth.login")}
            </Button>

            <small style={{color: "gray", textAlign: "center"}}>
                <br></br>
                <Link to="/create">
                    <u>
                        {t("auth.createAccount")}
                    </u>
                </Link>
                <br></br>
                <Link to="/forgot">
                    <u>
                        {t("auth.forgotPassword")}
                    </u>
                </Link>
            </small>
        </form>
    );
};

export default Login;
