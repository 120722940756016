import React, {useEffect, useState} from "react";
import {useNavigate, useParams,} from "react-router-dom";
import loginApi from "api/routes/login";
import Button from "@mui/material/Button";
import {useTranslation} from "react-i18next";
import {handleDataServer, handleSuccessData} from "api";
import useAuth from "hooks/useAuth";
import PasswordConfirm from "components/auth/PasswordConfirm";

const ConfirmRecover = () => {
    const {t, i18n} = useTranslation();
    const {email, token} = useParams();
    const user = useAuth();
    const [pass, setPass] = useState("");
    const [confirmPass, setConfirmPass] = useState("");

    const navigate = useNavigate();

    async function handleSubmit(e) {
        e.preventDefault();
        if (pass !== confirmPass) return;

        const currentLanguage = i18n.language.substring(0, 2);
        const auth = await handleDataServer(
            await loginApi.resetPassword(email, token, pass, confirmPass, currentLanguage),
            handleSuccessData
        );

        if (auth) {
            alert(t("auth.confirm.passwordSet"));
            navigate("/");
        }
    }

    useEffect(() => {
        if (user) {
            navigate('/');
        }
    }, []);

    return (
        <form className={'auth-form'}>
            <p className={'marginTop0'}>{t("auth.confirm.title")}</p>
            <PasswordConfirm
                onChangePass={setPass}
                onChangeConfirmPass={setConfirmPass}
            />

            <Button
                onClick={handleSubmit}
                variant="contained"
            >
                {t("auth.confirm.setPassword")}
            </Button>
        </form>
    );
};

export default ConfirmRecover;
