import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {getOntologies, selectOntologies} from "store/modules/Semantic";
import semanticApi from "api/routes/semantic";
import {handleDataServer, handleSuccessData} from "api";
import GenericRead from "components/protected/GenericRead";
import {useTranslation} from "react-i18next";
import utils, {getRouteRole, openConfirmDelete, openConfirmGeneric} from "utils/utils";
import {useNavigate} from "react-router-dom";
import useAuth from "hooks/useAuth";

const SemanticSchemes = () => {
    const user = useAuth();
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const ontologies = useSelector(selectOntologies);

    const keys = [
        {
            key: "name",
            label: t("semantic.singleTitle")
        },
        {
            key: "description",
            label: t("general.description")
        },
        {
            key: "isPublic",
            label: t("general.public")
        }
    ];

    const actions = [
        {
            label: "semantic.schemeDefinition",
            icon: "edit_note",
            action: (ontologyId) => navigate(`${utils.getRouteRole(user)}/semantic/${ontologyId}/scheme`),
            disabled: (ontology) => ontology.canEdit > 0
        },
        {
            label: "general.duplicate",
            icon: "content_copy",
            action: (idOntology) => openConfirmGeneric(
                "general.confirmDuplicate",
                "general.confirmDuplicateText",
                "general.duplicate",
                "semantic.singleTitle",
                duplicateOntology,
                idOntology
            )
        }
    ];

    const buttons = [
        {
            label: "semantic.field.enumerates",
            action: () => navigate(`${utils.getRouteRole(user)}/semantic/enumerates`)
        }
    ];

    const duplicateOntology = async (flag, idOntology) => {
        if (flag) {
            let result = await semanticApi.duplicateOntology(idOntology);
            await handleDataServer(result, handleSuccessData);
            await populate();
        }
    };

    const deleteOntology = async (flag, idOntology) => {
        if (flag) {
            let result = await semanticApi.deleteOntology(idOntology);
            await handleDataServer(result, handleSuccessData);
            await populate();
        }
    };

    const populate = async () => {
        dispatch(getOntologies());
    };

    useEffect(() => {
        populate().then(el => el);
    }, []);

    return (
        <GenericRead
            title={t("semantic.titleShow")}
            titleAdd={t("semantic.singleTitle")}
            linkAdd={`${getRouteRole(user)}/semantic/new`}
            iterate={ontologies}
            iterateKeys={keys}
            actions={actions}
            onEdit={(id) => navigate(`${getRouteRole(user)}/semantic/${id}/edit`)}
            isDisabledEdit={(ontology) => ontology.canEdit > 0}
            onDelete={(id) => openConfirmDelete("semantic.singleTitle", deleteOntology, id)}
            isDisabledDelete={(ontology) => ontology.canEdit > 0}
            buttons={buttons}
        />
    );
};

export default SemanticSchemes;
