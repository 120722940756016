import {createSlice} from "@reduxjs/toolkit";

const initialState = {
    open: false,
    title: "general.confirmDelete",
    contentText: "general.confirmDeleteText",
    item: "general.item",
    confirmText: "general.delete",
    onClose: () => {
    },
    param: null
};

const confirmDialogSlicer = createSlice({
    name: "confirmDialog",
    initialState: {...initialState},
    reducers: {
        openDialog: (state, action) => {
            const {title, contentText, confirmText, item, onClose, param} = action.payload;

            state.open = true;
            state.title = title;
            state.contentText = contentText;
            state.item = item;
            state.confirmText = confirmText;
            state.onClose = onClose;
            state.param = param;
        },
        resetDialog: (state) => {
            state.open = initialState.open;
            state.title = initialState.title;
            state.contentText = initialState.contentText;
            state.item = initialState.item;
            state.confirmText = initialState.confirmText;
            state.onClose = initialState.onClose;
            state.param = initialState.param;
        }
    },
});

// getters
export const selectDialog = (state) => state.deleteDialog;

// actions
export const {openDialog, resetDialog} = confirmDialogSlicer.actions;

// slice
export default confirmDialogSlicer;
