import React, {useState} from 'react';
import semantic from "utils/semantic";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import {v4 as uuid} from "uuid";
import {Autocomplete, FormControl, createFilterOptions} from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import {selectResources} from "store/modules/Container";
import {useSelector} from "react-redux";

const filter = createFilterOptions();

const SemanticProperty = (
    {
        property,
        onChange,
        values,
        classObj,
        isEdit
    }
) => {
    const PRIMITIVE_ENUMERATE = "PrimitiveEnumerate";
    const ENUMERATE = "Enumerate";

    const [newItem, setNewItem] = useState(false);
    const resources = useSelector(selectResources);

    const resourcesClass = () => {
        if (classObj.mainPropertyId !== property.id) return [];

        // return array with only names
        const options = resources
            .filter(el => el.class_semantic_id === classObj.id);

        const withTitles = [];
        options.forEach(option => withTitles.push({title: option.resource[property.display], id: option.id}));

        return withTitles;
    };

    const itemsResource = () => {
        const fromClass = resourcesClass();
        if (newItem) {
            fromClass.push(newItem);

        }
        return fromClass;
    };

    const isPrimitive = (propertyNow) => {
        return propertyNow.typeable_type.includes(PRIMITIVE_ENUMERATE);
    };

    const getProperty = () => {
        const tag = semantic.getTagFromProperty(property);
        const valueNow = values ? values[tag] ? values[tag] : "" : "";
        const type = property.typeable.type;

        // check if it is a main property
        if (classObj.mainPropertyId === property.id) {
            // main property must be an autocomplete with the resources already created
            return (
                <Autocomplete
                    disabled={isEdit}
                    className={'width100'}
                    value={valueNow === '' ? null : valueNow}
                    onChange={(e, newValue) => {
                        if (newValue && newValue.inputValue) {
                            // only change property.display (creating a new resource)
                            const check = {...newValue};
                            check.title = check.inputValue;

                            setNewItem(check);
                            onChange([newValue.id, newValue.inputValue], property);
                        } else {
                            // change all the options according to the selected resource
                            const resource = resources.find(el => el.id === newValue.id);
                            onChange(null, null, resource);
                        }
                    }}
                    disablePortal
                    filterOptions={(options, params) => {
                        let filtered = filter(options, params);

                        const {inputValue} = params;

                        // suggest the creation of a new value
                        const isExisting = options.some((option) => inputValue === option.title);
                        if (inputValue !== '' && !isExisting) {
                            filtered.push({
                                inputValue,
                                title: `Add ${inputValue}`,
                                id: uuid()
                            });
                        }

                        if (newItem) {
                            filtered.splice(filtered.indexOf(newItem), 1);
                        }

                        return filtered;
                    }}
                    getOptionLabel={(option) => {
                        // Value selected with enter, right from the input
                        if (typeof option === 'string') {
                            return option;
                        }

                        // Add "xxx" option created dynamically
                        if (option.inputValue) {
                            return option.inputValue;
                        }

                        // regular option
                        return option.title;
                    }}
                    renderOption={(props, option) => {
                        const { key, ...optionProps } = props;
                        return (
                            <li key={key} {...optionProps}>
                                {option.title}
                            </li>
                        );
                    }}
                    renderInput={(params) =>
                        <TextField
                            {...params}
                            name={`${property.id}`}
                            label={property.display}
                            variant={'outlined'}
                            type={type}
                        />
                    }
                    isOptionEqualToValue={(option, value) => {
                        if (typeof value === 'string') {
                            return option.title === value;
                        }

                        if (value.title) {
                            return option.title === value.title;
                        }

                        if (value.id) {
                            return option.id === value.id;
                        }

                        return false;
                    }}
                    selectOnFocus
                    clearOnBlur
                    options={itemsResource()}
                />
            );
        }

        // otherwise, we check if it is primitive or enumerate
        if (isPrimitive(property)) {
            // primitive
            switch (type) {
                case "string":
                case "float":
                case "integer":
                case "date":
                    return (
                        <TextField
                            InputProps={
                                type === "integer" ?
                                    {
                                        inputProps: {
                                            max: 10000, min: 0
                                        }
                                    } : null
                            }
                            className={'width100'}
                            name={`${property.id}`}
                            label={property.display}
                            variant={'outlined'}
                            type={type}
                            value={valueNow}
                            onChange={(e) => onChange(e.target.value, property)}
                        />
                    );
                case "boolean":
                    return (
                        <FormControlLabel
                            control={
                                <Checkbox
                                    className={'width100'}
                                    name={`${property.id}`}
                                    value={valueNow}
                                    onChange={(e) => onChange(e.target.checked, property)}
                                />
                            }
                            label={property.display}
                        />
                    );
                default:
                    return null;
            }
        }

        // enumerate
        const uuidLabel = uuid();

        // an enumerate must be an object
        const makeEnumerateObject = (value) => {
            const [sameAs, display] = value.split('-');

            return {
                '@type': property.sameAs,
                '@value': sameAs,
                'additionalType': display
            };
        };
        return (
            <FormControl
                variant="outlined"
                className={'width100'}
            >
                <InputLabel id={`select-${uuidLabel}`} className={'capitalize'}>
                    {property.display}
                </InputLabel>
                <Select
                    className={'width100'}
                    labelId={`select-${uuidLabel}`}
                    label={property.display}
                    name={`${property.id}`}
                    value={valueNow !== '' ? `${valueNow['@value']}-${valueNow['additionalType']}` : valueNow}
                    onChange={(e) => onChange(makeEnumerateObject(e.target.value), property)}
                >
                    {
                        property.typeable.items.map((el, index) => (
                            <MenuItem key={index} value={`${el.sameAs}-${el.value}`}>{el.value}</MenuItem>
                        ))
                    }
                </Select>
            </FormControl>
        );
    };

    return (
        getProperty()
    );
};

export default SemanticProperty;