import React, {useEffect, useState} from 'react';
import SemanticAnnotation from "components/container/recogito/popup/SemanticAnnotation";
import CommonAnnotation from "components/container/recogito/popup/CommonAnnotation";
import {useDispatch, useSelector} from "react-redux";
import {selectContainer, selectResources, setResources} from "store/modules/Container";
import {useAnnotationStore, useAnnotator} from "@annotorious/react";
import semantic from "utils/semantic";
import utils from "utils/utils";
import {handleDataServer, successData} from "api";
import resourceApi from "api/routes/resource";
import {URL_JSON_LD} from "components/container/recogito/MyW3CTextFormat";
import {v4 as uuid} from 'uuid';
import {useParams} from "react-router-dom";
import ModalClose from "components/protected/modal/ModalClose";

const ModalAddResource = (
    {
        open,
        onChange,
        relation,
        onClose
    }
) => {
    const dispatch = useDispatch();

    let {idContainer} = useParams();

    const [addId, setAddId] = useState("");

    const storeAnnotation = useAnnotationStore();
    const annotator = useAnnotator();

    // resources
    const resources = useSelector(selectResources);
    const resourcesRelation = resources.filter(el => el.class_semantic_id === relation.range.id);

    // ontology structure
    const container = useSelector(selectContainer);
    const ontology = container.activity.ontology;
    const classes = ontology.classes;
    const structure = ontology.structure;

    const createAnnotation = () => {
        return {
            id: uuid(),
            '@context': URL_JSON_LD,
            body: [],
            creator: utils.getCreatorAnnotation(),
            target: [],
            created: new Date().toISOString(),
            modified: new Date().toISOString(),
        };
    };

    const getSelectedAnnotation = () => {
        if (addId === "") return;

        return storeAnnotation.all().find(el => el.id === addId);
    };

    const getSelectedStructureOntology = () => {
        if (!classes) return;

        // find class where id is the same as the range of the relation
        const classNow = classes.find(el => el.id === relation.range.id);

        // from the class, we get the 'display' and 'sameAs', which we will recursive find in 'structure' to find it
        const elementsSelected = semantic.recursiveFind(structure, null, 'object', [], true, classNow.display, classNow.sameAs);

        if (Array.isArray(elementsSelected)) {
            return elementsSelected[0];
        }
    };

    const resetAddResource = () => {
        setAddId("");
        onClose();
    };

    // we will remove the surplus of annotations in MockStorage
    const handleCancelAddResource = () => {
        resetAddResource();
    };

    const handleSaveAddResource = async (annotation, body) => {
        // check if user has not selected one resource that already existed
        if (body.value && body.value['@id']) {
            const resourceId = body.value['@id'];
            if (resourcesRelation.findIndex(el => resourceId === el.id) !== -1) {
                onChange(resourceId, relation, "", 'update');
                resetAddResource();
                return;
            }
        }

        // otherwise, we will proceed to the instance/resource creation
        // parse the annotation to the usual format
        const myAnnotation = {
            id: annotation.id,
            '@context': annotation['@context'],
            body: [body],
            accessRights: utils.accessRights.public,
            type: 'Annotation',
            creator: annotation.target.creator,
            created: annotation.target.created,
            modified: annotation.target.modified,
        };

        // add the new resource to the store
        const data = {
            container_id: idContainer,
            annotation: myAnnotation,
        };
        const result = await handleDataServer(await resourceApi.addResource(data), successData);

        if (result) {
            const {resource, resources} = result;
            dispatch(setResources(resources));

            // call onChange with the new resource added
            onChange(resource, relation, null, 'update');

            // close the SemanticAnnotation add
            resetAddResource();
        }
    };

    const handleOpen = () => {
        const annotationFake = createAnnotation();
        setAddId(annotationFake.id);

        annotator.addAnnotation(annotationFake);
    };

    useEffect(() => {
        if (open) {
            handleOpen();
        }
    }, [open]);

    return (
        <ModalClose
            open={open}
            onClose={onClose}
            style={false}
        >
            <>
                <div>
                    {
                        addId &&
                        <CommonAnnotation
                            isFake={true}
                            selected={getSelectedAnnotation()}
                            children={props =>
                                <SemanticAnnotation
                                    {...props}
                                    isFake={true}
                                    customTitle={'annotation.addResource'}
                                    selectedFake={getSelectedStructureOntology()}
                                    customCancel={handleCancelAddResource}
                                    customDelete={handleCancelAddResource}
                                    customSave={handleSaveAddResource}
                                />
                            }
                        />
                    }
                </div>
            </>
        </ModalClose>
    );
};

export default ModalAddResource;