import React, {useEffect} from 'react';
import Card from "@mui/material/Card";
import {CardActions, CardContent, CardHeader, Stack} from "@mui/material";
import TextField from "@mui/material/TextField";
import TooltipIcon from "components/TooltipIcon";
import {v4 as uuid} from 'uuid';
import AccessRightsSelector from "components/container/recogito/AccessRightsSelector";
import {useTranslation} from "react-i18next";
import {URL_ACCESS_RIGHTS, URL_JSON_LD, URL_TITLE_ANNOTATION} from "components/container/recogito/MyW3CTextFormat";
import SelectFreeAnnotationType from "components/container/recogito/popup/SelectFreeAnnotationType";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import {
    ClassicEditor,
    Bold,
    Essentials,
    Heading,
    Indent,
    IndentBlock,
    Italic,
    Link,
    List,
    MediaEmbed,
    Paragraph,
    Table,
    Undo
} from 'ckeditor5';

import 'ckeditor5/ckeditor5.css';


/**
 * An annotation that allows the user to write a free annotation,
 * i.e, a simple comment. This component must be used inside the
 * CommonAnnotation component.
 * @param props Functions passed thorugh CommonAnnotation
 * @returns {JSX.Element}
 * @constructor
 */
const FreeAnnotation = (props) => {
    const {t} = useTranslation();

    const defaultContext = [
        URL_JSON_LD,
        {
            "accessRights": URL_ACCESS_RIGHTS,
            "title": URL_TITLE_ANNOTATION
        }
    ];

    const defaultBody = {
        id: uuid(),
        annotation: props.annotationNow.id,
        type: 'TextualBody',
        purpose: 'commenting',
        title: '',
        value: '',
    };

    useEffect(() => {
        const bodyFind = props.findBodyComment(props.annotationNow);

        if (bodyFind) {
            props.setBody({...bodyFind});
        } else {
            props.setBody({...defaultBody});
        }
    }, [props.annotationNow]);

    return (
        <Card>
            <CardHeader
                className={'move'}
                title={t('container.freeAnnotation')}
            />
            <CardContent sx={{padding: '5px'}}>
                <SelectFreeAnnotationType
                    onChange={(value) => props.changeBody('purpose', value)}
                    value={props.body ? props.body.purpose : ""}
                />

                <TextField
                    variant={'outlined'}
                    className={'width100 marginTop8 marginBottom8'}
                    label={t('general.title')}
                    size={'small'}
                    required={true}
                    value={props.body ? props.body.title : ""}
                    onChange={(e) => props.changeBody('title', e.target.value)}
                />

                {/* Custom Text Editor */}
                <div className={'marginTop8 marginBottom8'}>
                    <CKEditor
                        editor={ ClassicEditor }
                        config={ {
                            toolbar: [
                                'undo', 'redo', '|',
                                'heading', '|', 'bold', 'italic', '|',
                                'link', 'insertTable', 'mediaEmbed', '|',
                                'bulletedList', 'numberedList', 'indent', 'outdent'
                            ],
                            plugins: [
                                Bold,
                                Essentials,
                                Heading,
                                Indent,
                                IndentBlock,
                                Italic,
                                Link,
                                List,
                                MediaEmbed,
                                Paragraph,
                                Table,
                                Undo
                            ],
                            initialData: props.body ? props.body.value : "",
                        } }
                        onChange={(e, editor) => props.changeBody('value', editor.getData())}
                    />
                </div>

                <AccessRightsSelector {...props} />
            </CardContent>
            <CardActions disableSpacing sx={{justifyContent: 'space-between'}}>
                <TooltipIcon tooltipText={'general.delete'} icon={'delete'} onClick={props.deleteAnnotation}/>

                <Stack direction={'row'}>
                    <TooltipIcon tooltipText={'general.cancel'} icon={'cancel'} onClick={props.cancel}/>
                    <TooltipIcon tooltipText={'general.save'} icon={'save'}
                                 onClick={() => props.saveAndClose(defaultContext)}/>
                </Stack>
            </CardActions>
        </Card>
    );
};

export default FreeAnnotation;