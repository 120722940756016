import React from 'react';
import TabPanel from "components/TabPanel";
import TextField from "@mui/material/TextField";
import {useTranslation} from "react-i18next";
import semantic from "utils/semantic";

const ClassDefinition = (
    {
        items,
        setItems,
        selected,
        index,
        tabNow
    }
) => {
    const {t} = useTranslation();

    const handleChange = (e) => {
        const value = e.target.value;

        const itemsNow = [...items];
        const find = semantic.recursiveFindObject(itemsNow, selected);
        find.sameAs = value;

        setItems(itemsNow);
    };

    return (
        <TabPanel value={tabNow} index={index}>
            <TextField
                label={t('semantic.field.sameAs')}
                variant={'outlined'}
                value={selected && selected.sameAs ? selected.sameAs : ""}
                className={'margin8'}
                sx={{width: '250px'}}
                onChange={handleChange}
            />
        </TabPanel>
    );
};

export default ClassDefinition;