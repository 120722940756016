import React, {useEffect, useState} from "react";
import {FormControl} from "@mui/material";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import UsersTable from "components/protected/users/UsersTable";
import usersApi from "api/routes/users";
import coursesApi from "api/routes/courses";
import {handleDataServer, handleSuccessData, successData} from "api";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import InputLabel from "@mui/material/InputLabel";
import {useParams} from "react-router-dom";
import {useTranslation} from "react-i18next";
import RolesSelect from "components/protected/RolesSelect/RolesSelect";
import utils from "utils/utils";
import ModalClose from "components/protected/modal/ModalClose";

const AddUserModal = (
    {
        open,
        handleClose,
        usersAdded = []
    }
) => {
    const {t} = useTranslation();
    let {idCourse} = useParams();

    const [allUsers, setAllUsers] = useState([]);
    const [usersSearch, setUsersSearch] = useState([]);
    const [role, setRole] = useState("");
    const [selected, setSelected] = useState([]);

    const handleChange = async (e) => {
        let term = e.target.value;
        term = term.toLowerCase();

        if (term === "") {
            setUsersSearch(allUsers);
            return;
        }

        let users = allUsers.filter(el => {
            return el.name.toLowerCase().includes(term) ||
                el.email.toLowerCase().includes(term);
        });

        setUsersSearch(filterUsers(users));
    };

    const zeroState = () => {
        setUsersSearch([]);
        setRole("");
        setSelected([]);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        // check if fields are fulfilled
        if (role && selected) {
            let answer = true;

            for (let i = 0; i < selected.length; i++) {
                let id = selected[i];
                let result = await coursesApi.addUser(idCourse, id, role);
                answer &= await handleDataServer(result, handleSuccessData);
            }

            // if all went well, zero the state and close the modal
            if (answer) {
                zeroState();
                handleClose(true);
            }
        }
    };

    const filterUsers = (users) => {
        usersAdded.forEach(user => {
            users = users.filter(el => el.id !== user.id);
            users = users.filter(el => el.role_id !== utils.roles.admin);
        });

        return users;
    };

    const populateUsers = async () => {
        let users = filterUsers(await handleDataServer(await usersApi.getUsers(), successData));

        if (users) {
            setAllUsers(users);
            setUsersSearch(users);
        }
    };

    const populate = async () => {
        await populateUsers();
    };

    useEffect(() => {
        populate().then(el => el);
    }, [usersAdded, open]);

    return (
        <ModalClose
            open={open}
            onClose={() => handleClose(false)}
            style={false}
        >
            <>
                <Typography id="modal-modal-title" variant="h6" component="h2">
                    {t("users.addUsers.title")}
                </Typography>
                <div id="modal-modal-description">
                    <Grid container spacing={2} alignItems={'center'} columns={{xs: 4, sm: 4, md: 12}}>
                        <Grid item xs={6}>
                            <TextField
                                label={t("users.addUsers.search")}
                                variant={"outlined"}
                                sx={{width: '100%'}}
                                onChange={handleChange}
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <FormControl
                                variant="outlined"
                                style={{width: "100%"}}
                            >
                                <InputLabel id="select-role">{t('users.addUsers.role')}</InputLabel>
                                <RolesSelect role={role} setRole={setRole}/>
                            </FormControl>
                        </Grid>
                        <Grid item>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={handleSubmit}
                            >
                                {t("general.add")}
                            </Button>
                        </Grid>
                    </Grid>

                    <UsersTable
                        users={usersSearch}
                        hasCheckbox={true}
                        hasRole={false}
                        onSelection={(selectedNow) => setSelected(selectedNow)}
                    />
                </div>
            </>
        </ModalClose>
    );
};

export default AddUserModal;
