import api, {setBearerToken, setCSRFToken} from "../api";
import store from 'store/store';
import {failureCallback, handleDataServer, successCallback, successData} from '../index';
import {setToken, setUser} from "store/modules/User";

export default {
    login: async(email, password, locale) => {
        const csrf = await setCSRFToken();
        if (csrf === undefined) {
            return {
                data: {
                    message: "Error in CSRF Token",
                },
                status: 500
            };
        }

        return await api.post('/api/user/login', {
            email: email,
            password: password,
            locale: locale,
        }).then(successCallback, failureCallback);
    },
    logout: async() => {
        const result = await api.post('/api/user/logout').then(successCallback, failureCallback);
        store.dispatch(setUser(false));
        store.dispatch(setToken(''));
    },
    create: async(name, email, password, password_confirmation, locale) => {
        return await api.post('/api/user/create', {
            name: name,
            email: email,
            password: password,
            password_confirmation: password_confirmation,
            locale: locale
        }).then(successCallback, failureCallback);
    },
    forgot: async(email, locale) => {
        return await api.post('/api/user/forgot', {
            email: email,
            locale: locale
        }).then(successCallback, failureCallback);
    },
    resetPassword: async(email, token, password, password_confirmation, locale) => {
        return await api.post('/api/user/reset-password', {
            email: email,
            token: token,
            password: password,
            password_confirmation: password_confirmation,
            locale: locale
        }).then(successCallback, failureCallback);
    },
    setLocale: async(locale) => {
        return await api.post('/api/user/set-locale', {
            locale: locale,
        }).then(successCallback, failureCallback);
    },
};
