import React, {useEffect, useRef, useState} from "react";
import TextField from "@mui/material/TextField";
import {FileUploader} from "react-drag-drop-files";
import {Chip} from "@mui/material";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import {useTranslation} from "react-i18next";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import utils from "utils/utils";
import {useDispatch} from "react-redux";
import {setError} from "store/modules/Error";

const GenericAddFile = (
    {
        isEdit = false,
        data = null,
        nameTitle,
        titlePage,
        handleSubmit,
        typeFiles
    }
) => {
    const {t} = useTranslation();
    const dispatch = useDispatch();

    const form = useRef();
    const [title, setTitle] = useState("");
    const [author, setAuthor] = useState("");
    const [url, setUrl] = useState("");
    const [file, setFile] = useState(null);
    const [isPublic, setIsPublic] = useState(false);
    const [sameAs, setSameAs] = useState("");

    const onSubmit = (e) => {
        e.preventDefault();
        if (title !== null && author !== null && (url !== "" || file !== null)) {
            handleSubmit(new FormData(form.current));
        } else {
            alert(t("general.fulfillAll"));
        }
    };

    const validUrl = (field, url) => {
        if (url === '') return;

        if (!utils.isValidUrl(url)) {
            dispatch(
                setError(t("general.notValidUrl", {url: field}))
            );
        } else {
            dispatch(setError(""));
        }
    };

    useEffect(() => {
        if (data !== null) {
            setTitle(data.title);
            setAuthor(data.author);
            setUrl(data.path);
            setIsPublic(data.isPublic);
            setSameAs(data.sameAsUrl);
        }
    }, [data]);

    return (
        <Card>
            <form
                style={{
                    display: "flex",
                    flexDirection: "column"
                }}
                ref={form}
            >
                <h1>{t(`general.${isEdit ? 'edit' : 'create'}`)} {titlePage}</h1>
                <div className={'marginBottom8'}>
                    <TextField
                        label={t("general.title")}
                        variant="outlined"
                        onChange={(e) => setTitle(e.target.value)}
                        value={title}
                        className={'marginBottom8 width100'}
                        name={nameTitle}
                        required
                    />

                    <TextField
                        label={t("documents.author")}
                        variant="outlined"
                        onChange={(e) => setAuthor(e.target.value)}
                        value={author}
                        className={'marginBottom8 width100'}
                        name={'author'}
                        required
                    />

                    <FormControlLabel
                        control={
                            <Checkbox
                                name={'isPublic'}
                                checked={isPublic || false}
                                onChange={(e) => setIsPublic(e.target.checked)}
                            />
                        }
                        label={t("general.public")}
                    />

                    <TextField
                        label={t('documents.sameAs')}
                        variant="outlined"
                        onChange={(e) => setSameAs(e.target.value)}
                        onBlur={(e) => validUrl(t('documents.sameAs'), e.target.value)}
                        value={sameAs}
                        className={'marginBottom8 width100'}
                        name={'sameAsUrl'}
                    />

                    <TextField
                        label={'URL'}
                        variant="outlined"
                        onChange={(e) => setUrl(e.target.value)}
                        onBlur={(e) => validUrl('URL', e.target.value)}
                        value={url}
                        className={'marginBottom8 width100'}
                        name={'path'}
                        disabled={file !== null}
                    />

                    <FileUploader
                        required
                        name={'file'}
                        label={t("dragDropFile.title")}
                        hoverTitle={t("dragDropFile.hoverTitle")}
                        types={typeFiles}
                        onTypeError={() => alert(t("dragDropFile.invalidFileType"))}
                        handleChange={(file) => {
                            setFile(file);
                            setUrl("");
                            dispatch(setError(""));
                        }}
                        className={'maxWidth100'}
                        disabled={url !== ""}
                    >
                        {
                            file &&
                            <div>
                                <Chip
                                    label={file.name}
                                    variant={'outlined'}
                                    onDelete={() => setFile(null)}
                                />
                            </div>
                        }
                    </FileUploader>
                </div>

                <Button
                    variant="contained"
                    color="primary"
                    onClick={onSubmit}
                >
                    {t(`general.${isEdit ? 'edit' : 'add'}`)}
                </Button>
            </form>
        </Card>
    );
};

export default GenericAddFile;
