import React, {Fragment, useEffect, useState} from 'react';
import {CardActions, CardContent, CardHeader, Stack, Step, StepContent, StepLabel, Stepper} from "@mui/material";
import TooltipIcon from "components/TooltipIcon";
import Card from "@mui/material/Card";
import {v4 as uuid} from 'uuid';
import TreeScheme from "components/TreeScheme/TreeScheme";
import {useSelector} from "react-redux";
import {selectContainer, selectResources} from "store/modules/Container";
import semantic, {getTagFromProperty} from "utils/semantic";
import AnnotateSemantic from "components/container/recogito/popup/AnnotateSemantic";
import {URL_ACCESS_RIGHTS, URL_ADDITIONAL_TYPE, URL_JSON_LD} from "components/container/recogito/MyW3CTextFormat";
import AccessRightsSelector from "components/container/recogito/AccessRightsSelector";
import {SERVER_URI} from "api";
import utils from "utils/utils";
import {useTranslation} from "react-i18next";
import Button from "@mui/material/Button";

/**
 * An annotation that allows the user to write a semantic annotation.
 * This component must be used inside the CommonAnnotation component.
 * Also used to create a semantic resource alone, defined in the prop 'isFake'
 * and called by the component SemanticRelation.
 * @param props Functions passed through CommonAnnotation
 * @returns {JSX.Element}
 * @constructor
 */
const SemanticAnnotation = (props) => {
    const {t} = useTranslation();

    const container = useSelector(selectContainer);
    const ontology = container.activity.ontology;
    const structure = ontology.structure;

    const resources = useSelector(selectResources);

    // variables to control in which step we are
    const [activeStep, setActiveStep] = useState(0);

    // variables to control when the component is first loaded (and we are updating one annotation)
    const [isEdit, setIsEdit] = useState(false);
    const [isFirst, setIsFirst] = useState(false);

    const [context, setContext] = useState({});
    const [target, setTarget] = useState({});
    const [selected, setSelected] = useState(false);

    const defaultContext = [
        URL_JSON_LD,
        {
            "accessRights": URL_ACCESS_RIGHTS
        }
    ];

    const defaultValue = {
        "@type": "",
    };

    const defaultBody = {
        id: uuid(),
        annotation: props.annotationNow.id,
        type: 'TextualBody',
        purpose: 'classifying',
        value: {...defaultValue},
        format: "text/html"
    };

    const defaultTarget = {
        id: uuid(),
        source: "",
        type: "SpecificResource"
    };

    const handleChangeClassWithResource = (value = null, property = null, resourceNow) => {
        // change class properties according to resource
        const resource = {...resourceNow};
        const skip = ['@id', '@type', '@context'];

        // update body
        const newValue = {...props.body.value};
        newValue['@type'] = resource.type;
        newValue['@id'] = resource.id;

        for (const [key, value] of Object.entries(resource.resource)) {
            if (skip.includes(key)) continue;

            newValue[key] = value;
        }
        props.changeBody('value', newValue);

        // update context
        const newContext = JSON.parse(JSON.stringify(context));
        const contextResource = {...resource.resource.target};
        for (const [key, value] of Object.entries(contextResource)) {
            newContext[1][key] = value;
        }
        setContext(newContext);

        // update target
        const targetNow = {...defaultTarget};
        targetNow.source = resource['@id'];
        setTarget(targetNow);
    };

    const handleChangeProperty = (value, property, resource = false) => {
        // if we are receiving a resource as parameter, we want to change all properties of this annotation
        // following the resource properties
        if (resource) {
            handleChangeClassWithResource(value, property, resource);
            return;
        }

        let id, valueNow;

        // if value is an array, we are receiving a new resource change, we need to update its source also
        if (Array.isArray(value)) {
            // update target
            id = value[0];
            valueNow = value[1];

            const targetNow = {...defaultTarget};
            targetNow.source = `${SERVER_URI}/resource/${id}`;
            setTarget(targetNow);
        } else {
            valueNow = value;
        }

        const tag = semantic.getTagFromProperty(property);

        // update body value
        const newValue = {...props.body.value};
        newValue[tag] = valueNow;
        props.changeBody('value', newValue);

        // update tag in context (necessary to make a deep copy to not occur reference problems)
        const newContext = JSON.parse(JSON.stringify(context));
        newContext[1][tag] = property.sameAs;

        // if object is an enumerate, we must put the additionalType into context
        if (valueNow.additionalType) {
            newContext[1]['additionalType'] = URL_ADDITIONAL_TYPE;
        }
        setContext(newContext);
    };

    // resourceCheck might be the object or the id of the resource
    const handleChangeRelation = (resourceCheck, relation, oldValue, operation) => {
        const tag = getTagFromProperty(relation);
        const newValue = {...props.body.value};

        if (operation === "delete") {
            // resourceCheck is the index in newValue[tag] array
            newValue[tag].splice(resourceCheck, 1);
            props.changeBody('value', newValue);
            return;
        }

        const resource = typeof resourceCheck === 'object' ? resourceCheck : resources.find(el => el.id === resourceCheck);

        const skip = ['target'];

        const isCreatingRelation = oldValue === "";

        const createRelationObject = () => {
            const relationObject = {};

            for (const [key, value] of Object.entries(resource.resource)) {
                if (skip.includes(key)) continue;

                relationObject[key] = value;
            }

            return relationObject;
        };

        // update body
        if (newValue[tag] === undefined) {
            // create array of relations
            newValue[tag] = [createRelationObject()];
        } else if (newValue[tag] !== undefined && !isCreatingRelation) {
            // update array of relations
            const relationsNow = newValue[tag];

            let relationEdit = relationsNow.find(el => utils.getBasename(el['@id']) === oldValue);
            const indexOldRelation = relationsNow.indexOf(relationEdit);
            relationsNow.splice(indexOldRelation, 1);

            const newRelation = createRelationObject();
            relationsNow.splice(indexOldRelation, 0, newRelation);

            // update newValue
            newValue[tag] = relationsNow;
        } else {
            // push new relation
            newValue[tag].push(createRelationObject());
        }
        props.changeBody('value', newValue);

        // update context
        const newContext = JSON.parse(JSON.stringify(context));
        for (const [key, value] of Object.entries(resource.resource.target)) {
            newContext[1][key] = value;
        }
        setContext(newContext);
    };

    const handleSave = () => {
        if (props.customSave) {
            // as we are using a custom save, we need to pass the correct annotation, i.e., with the context and target
            // already fulfilled; we also pass the body, since this would tricky MockStorage to think we are creating an
            // annotation; if we really want to create an annotation, the callee from customSave must do so.
            const annotationCorrect = props.annotationWithContextTarget(context, target);
            props.customSave(annotationCorrect, props.body);
            return;
        }

        props.saveWithInfo(context, target);
        props.close();
    };

    useEffect(() => {
        // set body
        const bodyFind = props.findBodyComment(props.annotationNow);

        // if isFake is set, we also has a selectedFake prop
        if (props.isFake) {
            setSelected(props.selectedFake);
        }

        if (bodyFind) {
            setIsFirst(true);
            setIsEdit(true);

            props.setBody({...bodyFind});

            // set selected
            const tag = bodyFind.value['@type'];
            if (tag !== '') {
                const sameAsTag = props.annotationNow['@context'][1][tag];
                const elementSelected = semantic.recursiveFind(structure, null, 'object', [], true, tag, sameAsTag);

                if (Array.isArray(elementSelected)) {
                    if (elementSelected[0]) {
                        setSelected({...elementSelected[0]});
                    }
                }

                // if we have one element selected, we must set the activeStep to 1
                if (elementSelected) {
                    setActiveStep(1);
                }
            }
        } else {
            props.setBody({...defaultBody});
        }

        // set target
        const targetAnnotation = props.annotationNow.target.selector;
        if (Array.isArray(targetAnnotation)) {
            const exists = targetAnnotation.find(t => t.type === "SpecificResource");
            if (exists !== undefined) {
                setTarget({...exists});
            } else {
                setTarget({...defaultTarget});
            }
        }

        // set context
        const context = props.annotationNow['@context'];
        if (context) {
            setContext({...context});
        } else {
            setContext({...defaultContext});
        }
    }, [props.annotationNow]);

    useEffect(() => {
        // if isFirst is set, it means that the component is being loaded for the first time and setSelected was called,
        // but this behaviour is not desired, so it is necessary to return
        if (isFirst) {
            setIsFirst(false);
            return;
        }

        if (!selected) return;

        // set target source (class sameAs)
        setTarget((prev) => ({...prev, source: selected.sameAs}));

        const tagClass = semantic.getTagFromProperty(selected);

        // change the value of the body
        const valueNow = {...defaultValue};
        valueNow['@type'] = tagClass;
        props.changeBody('value', valueNow);

        // change the context
        const contextNow = {...defaultContext};
        contextNow[1][tagClass] = selected.sameAs;
        setContext(contextNow);
    }, [selected]);

    const renderStep = () => {
        switch (activeStep) {
            case 0:
                return (
                    <TreeScheme
                        items={structure}
                        setItems={() => {}}
                        selected={selected}
                        setSelected={setSelected}
                        readonly={true}
                        blocked={props.isFake}
                    />
                );
            case 1:
                return (
                    <AnnotateSemantic
                        annotationSelected={props.annotationNow}
                        classSelected={selected}
                        values={props.body.value}
                        onChangeProperty={handleChangeProperty}
                        onChangeRelation={handleChangeRelation}
                        isEdit={isEdit}
                    />
                );
        }
    };

    const backStep = () => {
        if (activeStep === 0) return;
        setActiveStep(activeStep - 1);

        // if we are clicking at back button, we must reset the target and context
        props.setBody({...defaultBody});
        setContext({...defaultContext});
        setTarget({...defaultTarget});
    };

    const nextStep = () => {
        // we can only go to the next step if we have selected a class
        if (activeStep === 1) return;
        if (!selected) return;

        setActiveStep(activeStep + 1);
    };

    return (
        <Card>
            <CardHeader
                className={'move'}
                title={t(props.customTitle ? props.customTitle : 'container.semanticAnnotation')}
            />
            <CardContent sx={{padding: '5px'}}>
                <Stepper activeStep={activeStep}>
                    <Step>
                        <StepLabel>{t('annotation.semanticAnnotation.steps.classChoose')}</StepLabel>
                    </Step>
                    <Step>
                        <StepLabel>{t('annotation.semanticAnnotation.steps.propertyFulfill')}</StepLabel>
                    </Step>
                </Stepper>

                <Fragment>
                    {renderStep()}

                    <Stack direction={'row'} justifyContent={'space-between'} className={'margin8'}>
                        <TooltipIcon
                            icon={'arrow_back'}
                            tooltipText={'annotation.semanticAnnotation.steps.back'}
                            disabled={activeStep === 0}
                            onClick={backStep}
                        />
                        <TooltipIcon
                            icon={'arrow_forward'}
                            tooltipText={'annotation.semanticAnnotation.steps.next'}
                            disabled={activeStep === 1}
                            onClick={nextStep}
                        />
                    </Stack>
                </Fragment>

                {
                    // we only show VisibilitySelector if it is not a fake annotation
                    !props.isFake &&
                    <AccessRightsSelector {...props} />
                }
            </CardContent>
            <CardActions disableSpacing sx={{justifyContent: 'space-between'}}>
                {
                    // we only show the delete button if it is not a fake annotation
                    !props.isFake ?
                    <TooltipIcon
                        tooltipText={'general.delete'}
                        icon={'delete'}
                        onClick={props.customDelete ? props.customDelete : props.deleteAnnotation}
                    /> : <span></span>
                }

                <Stack direction={'row'}>
                    <TooltipIcon
                        tooltipText={'general.cancel'}
                        icon={'cancel'}
                        onClick={props.customCancel ? props.customCancel : props.cancel}
                    />
                    <TooltipIcon
                        tooltipText={'general.save'}
                        icon={'save'}
                        onClick={handleSave}
                    />
                </Stack>
            </CardActions>
        </Card>
    );
};

export default SemanticAnnotation;