import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {handleDataServer, successData} from "api";
import annotationApi from 'api/routes/annotations';

const annotationsSlicer = createSlice({
    name: "annotations",
    initialState: {
        annotationsBackup: [],
        annotations: [],
        rendered: [],
        isAnnotating: false,
        type: "",
        isHidden: false,
        hasSelection: false,

        // if we should close the annotation after saving/updating (useful for ReplyAnnotation)
        mustClose: true,

        // if we should scroll into the annotation (when active, it will be the annotation id)
        scrollIntoAnnotation: false,
    },
    reducers: {
        setAnnotations: (state, action) => {
            const annotations = action.payload;

            state.annotations = annotations;
            state.annotationsBackup = annotations;
        },
        setRendered: (state, action) => {
            state.rendered = action.payload;
        },
        setScrollIntoAnnotation: (state, action) => {
            state.scrollIntoAnnotation = action.payload;
        },
        hideAnnotations: (state) => {
            state.isHidden = true;
            state.annotations = [];
        },
        showAnnotations: (state) => {
            state.isHidden = false;
            state.annotations = state.annotationsBackup;
        },
        setIsAnnotating: (state, action) => {
            state.isAnnotating = action.payload;
        },
        setTypeAnnotation: (state, action) => {
            state.type = action.payload;
        },
        closeAnnotation: (state, action) => {
            state.isAnnotating = false;
            state.type = "";
            state.mustClose = true;
        },
        setHasSelection: (state, action) => {
            state.hasSelection = action.payload;
        },
        setMustClose: (state, action) => {
            state.mustClose = action.payload;
        },
    },
    extraReducers(builder) {
        // show annotations
        builder.addCase(getAnnotations.fulfilled, (state, action) => {
            const annotations = action.payload;

            state.annotations = annotations;
            state.annotationsBackup = annotations;
        });
    }
});

export const correctAnnotation = (annotationNow) => {
    const keysTarget = Object.keys(annotationNow.target);
    const targetOld = annotationNow.target;

    const copyAnnotation = {...annotationNow};
    copyAnnotation.target = [];

    const id = copyAnnotation.id;
    keysTarget.forEach(key => {
        if (key === 'id') return;

        const target = targetOld[key];
        target.annotation = id;
        copyAnnotation.target.push({...target});
    });

    return copyAnnotation;
};

export const correctAnnotations = (annotations) => {
    const newAnnotations = [];
    annotations.forEach(annotation => {
        if (!annotation.body.length) return;

        newAnnotations.push(correctAnnotation(annotation));
    });

    return newAnnotations;
};

// test only
const deleteAllAnnotationsFromServer = (annotations) => {
    console.log('delete all');
    annotations.forEach(async annotation => {
        const result = await handleDataServer(await annotationApi.deleteAnnotation(annotation.id), successData);
        console.log(result);
    });
};

// thunk actions
export const getAnnotations = createAsyncThunk("annotations/get",
    async (target, thunkAPI) => {
        const result = await handleDataServer(await annotationApi.getAnnotations(target), successData);

        return correctAnnotations(result);
    });

// getters
export const selectAnnotations = (state) => state.annotations.annotations;
export const selectIsAnnotating = (state) => state.annotations.isAnnotating;
export const selectTypeAnnotation = (state) => state.annotations.type;
export const selectIsHidden = (state) => state.annotations.isHidden;
export const selectHasSelection = (state) => state.annotations.hasSelection;
export const selectMustClose = (state) => state.annotations.mustClose;
export const selectRendered = (state) => state.annotations.rendered;
export const selectScrollIntoAnnotation = (state) => state.annotations.scrollIntoAnnotation;

// actions
export const {
    setAnnotations,
    hideAnnotations,
    showAnnotations,
    setIsAnnotating,
    setTypeAnnotation,
    closeAnnotation,
    setHasSelection,
    setMustClose,
    setRendered,
    setScrollIntoAnnotation
} = annotationsSlicer.actions;

// slice
export default annotationsSlicer;
