import React, {useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {getEnumerates, selectEnumerates} from "store/modules/Enumerates";
import {useTranslation} from "react-i18next";
import {handleDataServer, handleSuccessData} from "api";
import enumeratesApi from 'api/routes/enumerates';
import GenericRead from "components/protected/GenericRead";
import {getRouteRole, openConfirmDelete} from "utils/utils";
import useAuth from "hooks/useAuth";
import {useNavigate} from "react-router-dom";

const EnumerateRead = () => {
    const user = useAuth();
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const enumerates = useSelector(selectEnumerates);

    const keys = [
        {
            key: "type",
            label: t("semantic.field.enumerateName")
        },
        {
            key: "sameAs",
            label: t("semantic.field.sameAs")
        }
    ];

    const deleteEnumerate = async(flag, idEnumerate) => {
        if (flag) {
            let result = await enumeratesApi.deleteEnumerate(parseInt(idEnumerate));
            await handleDataServer(result, handleSuccessData);
            await populate();
        }
    };

    const populate = async() => {
        dispatch(getEnumerates());
    };

    useEffect(() => {
        populate().then(el => el);
    }, []);

    return (
        <GenericRead
            title={t("semantic.field.enumerates")}
            titleAdd={t("semantic.field.enumerate")}
            linkAdd={`${getRouteRole(user)}/semantic/enumerates/new`}
            iterate={enumerates}
            iterateKeys={keys}
            onEdit={(id) => navigate(`${getRouteRole(user)}/semantic/enumerates/${id}/edit`)}
            onDelete={(id) => openConfirmDelete("semantic.field.enumerate", deleteEnumerate, id)}
        />
    );
};

export default EnumerateRead;
