import React from "react";
import "./i18n";
import ReactDOM from "react-dom/client";
import "./css/index.css";
import "./fonts/fonts.css";
import Router from "./routes";
import theme from "./theme";
import {ThemeProvider} from '@mui/material/styles';

import { Provider } from 'react-redux';
import store, {persistorStore} from './store/store';
import {PersistGate} from "redux-persist/integration/react";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    // <React.StrictMode>
        <ThemeProvider theme={theme}>
            <Provider store={store}>
                <PersistGate loading={null} persistor={persistorStore}>
                    <Router />
                </PersistGate>
            </Provider>
        </ThemeProvider>
    // </React.StrictMode>
);
