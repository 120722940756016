import React, {useEffect, useState} from 'react';
import {Autocomplete, FormControl, Stack} from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import SelectFreeAnnotationType from "components/container/recogito/popup/SelectFreeAnnotationType";
import TextField from "@mui/material/TextField";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import {selectContainer} from "store/modules/Container";
import {selectAnnotations} from "store/modules/Annotations";
import utils from "utils/utils";

const FilterAnnotations = (
    {
        onChange = () => {
        }
    }
) => {
    const {t} = useTranslation();
    const container = useSelector(selectContainer);
    const annotations = useSelector(selectAnnotations);
    const classes = container.activity.ontology.classes;
    const users = container.activity.course.users;

    // Enum for annotation types (for i18n translation)
    const TYPES = {
        ALL: "all",
        FREE: "free",
        SEMANTIC: "semantic"
    };
    const TYPE_LANGUAGE = {
        "all": "general.all",
        "free": "container.freeAnnotation",
        "semantic": "container.semanticAnnotation",
    };

    // filter options
    const [annotationType, setAnnotationType] = useState(TYPES.ALL);
    const [freeType, setFreeType] = useState("all");
    const [classSearch, setClassSearch] = useState(null);
    const [userSearch, setUserSearch] = useState(null);

    const filterAnnotations = () => {
        return annotations
            .filter(annotation => {
                if (annotationType === TYPES.SEMANTIC) {
                    return annotation.body[0].purpose === "classifying";
                } else if (annotationType === TYPES.FREE) {
                    return annotation.body[0].purpose !== "classifying";
                }

                return true;
            })
            .filter(annotation => {
                if ((annotationType === TYPES.FREE || annotationType === TYPES.ALL) && freeType !== 'all') {
                    return annotation.body[0].purpose === freeType;
                }

                return true;
            })
            .filter(annotation => {
                if ((annotationType === TYPES.SEMANTIC || annotationType === TYPES.ALL) && classSearch) {
                    return annotation.body[0].value['@type'] === classSearch.display;
                }

                return true;
            })
            .filter(annotation => {
                if (userSearch) {
                    const creatorAnnotation = utils.getCreatorAnnotationGeneric(userSearch);
                    return annotation.creator.id === creatorAnnotation.id;
                }

                return true;
            });
    };

    useEffect(() => {
        onChange(filterAnnotations());
    }, [annotationType, freeType, classSearch, userSearch]);

    return (
        <Stack direction={'row'}>
            {/* annotation type */}
            <FormControl variant="outlined" className={'width100'}>
                <InputLabel id="select-annotationType">
                    {t("container.annotationType")}
                </InputLabel>
                <Select
                    labelId={'select-annotationType'}
                    label={t("container.annotationType")}
                    value={annotationType}
                    onChange={(e) => setAnnotationType(e.target.value)}
                >
                    {
                        Object.keys(TYPES).map((type, index) => (
                            <MenuItem key={index}
                                      value={TYPES[type]}>{t(TYPE_LANGUAGE[TYPES[type]])}</MenuItem>
                        ))
                    }
                </Select>
            </FormControl>

            {/* free annotation type */}
            {
                (annotationType === TYPES.FREE || annotationType === TYPES.ALL) &&
                <SelectFreeAnnotationType
                    hasAll={true}
                    value={freeType}
                    onChange={(value) => setFreeType(value)}
                />
            }

            {/* semantic classes */}
            {
                (annotationType === TYPES.SEMANTIC || annotationType === TYPES.ALL) &&
                <Autocomplete
                    className={'width100'}
                    disablePortal
                    value={classSearch}
                    onChange={(e, value) => setClassSearch(value)}
                    options={classes}
                    getOptionLabel={(option) => option ? option.display : ""}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label={t("semantic.semanticClass")}
                            variant={"outlined"}
                        />
                    )}
                />
            }

            {/* users */}
            <Autocomplete
                className={'width100'}
                disablePortal
                value={userSearch}
                onChange={(e, value) => setUserSearch(value)}
                options={users}
                getOptionLabel={(option) => option ? option.email : ""}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        label={t("container.user")}
                        variant={"outlined"}
                    />
                )}
            />
        </Stack>
    );
};

export default FilterAnnotations;