import api from '../api';
import {failureCallback, MULTIPART_FORM_DATA, successCallback} from '../index';

export default {
    addDocument: async(form) => {
        return await api.post('/api/documents/store', form, {
            data: form,
            headers: { MULTIPART_FORM_DATA }
        }).then(successCallback, failureCallback);
    },
    updateDocument: async(idDocument, form) => {
        return await api.post('/api/documents/update', form, {
            data: form,
            headers: { MULTIPART_FORM_DATA },
            params: {
                id: idDocument
            }
        }).then(successCallback, failureCallback);
    },
    getDocuments: async() => {
        return await api.get('/api/documents/index')
            .then(successCallback, failureCallback);
    },
    deleteDocument: async(idDocument) => {
        return await api.delete('/api/documents/delete', {
            params: {
                id: idDocument
            }
        }).then(successCallback, failureCallback);
    },
};
