import store from "store/store";
import {openDialog} from "store/modules/ConfirmDialog";
import {SERVER_URI} from "api";

const utils = {
    roles: {
        admin: 1,
        teacher: 2,
        student: 3
    },
    protectedRoles: [1, 2],
    rolesNames: {
        1: "admin",
        2: "teacher",
        3: "student",
    },
    rolesCourses: {
        owner: 1,
        moderator: 2,
        student: 3
    },
    rolesCoursesNames: {
        1: "owner",
        2: "moderator",
        3: "student"
    },
    typeAnnotation: {
        free: "free",
        semantic: "semantic"
    },
    accessRights: {
        public: "public",
        private: "private",
        moderate: "moderate",
    },
    isAdmin: (user) => {
        return user && user.role === utils.roles.admin;
    },
    isStudent: (user) => {
        return user && user.role === utils.roles.student;
    },
    isProtectedUser: (user) => {
        const protectedRoles = [utils.roles.admin, utils.roles.teacher];
        return user && protectedRoles.includes(user.role);
    },
    getRouteRole: (user) => {
        return user && user.role ? `/${utils.rolesNames[user.role]}` : "";
    },
    makeOptionsItems: (items, idParam = "id", nameParam = "name") => {
        let itemsReturn = [];

        if (items && items.length) {
            itemsReturn = items.map(item =>
                ({value: item[idParam], text: item[nameParam]})
            );
        }

        return itemsReturn;
    },
    openConfirmGeneric: (title, contentText, confirmText, item, onClose, param = null, event = null) => {
        if (event) {
            event.stopPropagation();
            event.preventDefault();
        }
        store.dispatch(openDialog({title, contentText, confirmText, item, onClose, param}));
    },
    openConfirmDelete: (item, onClose, param = null, event = null) => {
        const title = "general.confirmDelete";
        const contentText = "general.confirmDeleteText";
        const confirmText = "general.delete";
        openConfirmGeneric(title, contentText, confirmText, item, onClose, param);
    },
    isValidUrl: (urlString) => {
        const urlPattern = new RegExp('^(https?:\\/\\/)?' + // validate protocol
            '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // validate domain name
            '((\\d{1,3}\\.){3}\\d{1,3}))' + // validate OR ip (v4) address
            '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // validate port and path
            '(\\?[;&a-z\\d%_.~+=-]*)?' + // validate query string
            '(\\#[-a-z\\d_]*)?$', 'i'); // validate fragment locator

        return !!urlPattern.test(urlString);
    },
    getDescendantProp: (obj, desc) => {
        let arr = desc.split(".");
        while (arr.length) {
            obj = obj[arr.shift()];
        }
        return obj;
    },
    propsTab: (index) => {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    },
    parseISOString: (date) => {
        // https://stackoverflow.com/questions/27012854/how-to-change-iso-date-string-to-date-object

        if (date instanceof String) {
            const b = date.split(/\D+/);
            return new Date(Date.UTC(b[0], --b[1], b[2], b[3], b[4], b[5], b[6]));
        }

        return date.toString();
    },
    getBasename: (url) => {
        try {
            return url.split('/').pop();
        } catch (e) {
            return url;
        }
    },
    getCreatorAnnotationGeneric: (user) => {
        return {
            id: `${SERVER_URI}/user/${user.id}`,
            name: user.name,
            isGuest: false
        };
    },
    getCreatorAnnotation: () => {
        const user = store.getState().user.user;
        return getCreatorAnnotationGeneric(user);
    }
};

export default utils;
export const {
    roles, rolesNames, rolesCourses, rolesCoursesNames, protectedRoles,
    makeOptionsItems, openConfirmDelete, isValidUrl, getRouteRole, isProtectedUser, isAdmin, isStudent,
    getDescendantProp, propsTab, openConfirmGeneric, parseISOString, getBasename, getCreatorAnnotation,
    getCreatorAnnotationGeneric
} = utils;
