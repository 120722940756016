import React from 'react';
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {Stack} from "@mui/material";
import Typography from "@mui/material/Typography";
import {isProtectedUser} from "utils/utils";
import CourseManagement from "components/course/CourseManagement";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionActivities from "components/course/accordions/AccordionActivities";
import useAuth from "hooks/useAuth";
import {useTranslation} from "react-i18next";

const AccordionCourses = (
    {
        courses = [],
        showOptions = true,
    }
) => {
    const user = useAuth();
    const {t} = useTranslation();

    return (
        <>
            {
                courses.length ?
                    courses.map((course, i) =>
                        <Accordion
                            key={i}
                            style={{borderLeft: "2px #00c76d solid", paddingTop: '5px'}}
                            defaultExpanded={courses.length === 1}
                            disableGutters
                        >
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon/>}
                                aria-controls="panel-courses-content"
                                id="panel-courses-header"
                            >
                                <Stack
                                    direction="row"
                                    justifyContent="space-between"
                                    spacing={2}
                                    className={'stack'}
                                    sx={{flexDirection: {xs: 'column', md: 'row'}}}
                                >
                                    <Typography variant="h6" gutterBottom component="div" className={'bold'}>
                                        {course.name}
                                    </Typography>
                                    {(showOptions && isProtectedUser(user)) &&
                                        <CourseManagement course={course}/>
                                    }
                                </Stack>
                            </AccordionSummary>

                            <AccordionDetails>
                                <AccordionActivities
                                    course={course}
                                    activities={course.activities}
                                    showOptions={true}
                                />
                            </AccordionDetails>
                        </Accordion>
                    ) :
                    <p>{t("genericRead.notFound", {title: t('courses.titleNew')})}</p>
            }
        </>
    );
};

export default AccordionCourses;
