import React, {useState} from 'react';
import {setError} from "store/modules/Error";
import {useTranslation} from "react-i18next";
import {useDispatch} from "react-redux";
import TextField from "@mui/material/TextField";

const PasswordConfirm = (
    {
        onChangePass,
        onChangeConfirmPass
    }
) => {
    const {t} = useTranslation();
    const dispatch = useDispatch();

    const [pass, setPass] = useState("");
    const [confirmPass, setConfirmPass] = useState("");
    const [first, setFirst] = useState(false);

    const changePass = (e) => {
        setPass(e.target.value);
        onChangePass(e.target.value);
    };

    const changeConfirmPass = (e) => {
        setConfirmPass(e.target.value);
        onChangeConfirmPass(e.target.value);
    };

    const checkConfirmPass = () => {
        if (!first) {
            setFirst(true);
            return;
        }

        if (pass === confirmPass && pass !== "") {
            dispatch(setError(""));
        } else {
            dispatch(setError(t('auth.confirm.passwordDiff')));
        }
    };

    return (
        <>
            <TextField
                value={pass}
                onChange={changePass}
                onBlur={() => checkConfirmPass()}
                label={t("general.password")}
                variant="outlined"
                type={'password'}
                className={'margin8'}
            />
            <TextField
                value={confirmPass}
                onChange={changeConfirmPass}
                onBlur={() => checkConfirmPass()}
                label={t("auth.confirm.confirmPassword")}
                variant="outlined"
                type={'password'}
                className={'margin8'}
            />
        </>
    );
};

export default PasswordConfirm;
