import React, {useEffect, useState} from "react";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";

import Card from "@mui/material/Card";
import {useDispatch, useSelector} from "react-redux";
import {getOntologies, selectOntologies} from "store/modules/Semantic";
import {handleDataServer, successData} from "api";
import activitiesApi from "api/routes/activities";
import {getDocuments, selectDocuments} from "store/modules/Documents";
import utils from "utils/utils";
import {useNavigate, useParams} from "react-router-dom";
import {Autocomplete, FormControl} from "@mui/material";
import {useTranslation} from "react-i18next";

/**
 * Faz a inserção/atualização de uma nova Atividade em um Curso
 * @returns {JSX.Element} Elemento de criação/atualização de uma Atividade
 * @constructor Sem parâmetros do component, pois são pegos pela url {@see routes.js}
 */
const NewActivity = () => {
    const {t} = useTranslation();
    let {idCourse, idActivity} = useParams();
    const isEdit = idActivity !== undefined;

    // flags demanded for update activity route, where it is necessary to fetch ontologies first
    const [loadedOntologies, setLoadedOntologies] = useState(false);
    const [loaded, setLoaded] = useState(false);

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const ontologies = useSelector(selectOntologies);
    const documents = useSelector(selectDocuments);

    const fields = [
        "createAnnotations", "editAnnotations", "createFree", "createSemantic", "createReplies", "editReplies",
        "visibleEverybody", "visibleGroups", "visiblePrivate", "seeAllSemantic", "filterByUser"
    ];

    const defaultValues = {
        activity: {
            course_id: parseInt(idCourse),
            ontology_id: "",
            name: "",
            removed: false,
            shared_knowledge_base: false,
        },
        documents: [],
        permissions: [
            {
                role_id: utils.rolesCourses.moderator
            },
            {
                role_id: utils.rolesCourses.student
            }
        ],
    };

    const [formData, setFormData] = useState({...defaultValues});

    const getPermissionSemantic = () => {
        return formData.permissions[0].createSemantic || formData.permissions[1].createSemantic;
    };

    const handleFormChange = (e, isCheckbox = false) => {
        const {name, value, checked} = e.target;

        const actualValue = isCheckbox ? checked : value;
        handleSpecificData(name, actualValue);
    };

    const handlePermissionCheckboxChange = (e, permissionIndex, field) => {
        const checked = e.target.checked;

        let dataCopy = {...formData};
        dataCopy.permissions[permissionIndex][field] = checked;

        setFormData(dataCopy);
    };

    const handleSpecificData = (item, value) => {
        if (!item.includes(".")) {
            setFormData((prev) => ({...prev, [item]: value}));
            return;
        }

        let dataCopy = {...formData};

        let string = item.split(".");
        let edit = dataCopy;
        string.forEach((chunk, index) => {
            if (index === string.length - 1) {
                edit[chunk] = value;
            }
            edit = edit[chunk];
        });

        setFormData(dataCopy);
    };

    const populateDocuments = async () => {
        dispatch(getDocuments());
    };

    const populateOntologies = async () => {
        dispatch(getOntologies());
    };

    const populateActivity = async () => {
        let activityResult = await activitiesApi.showActivity(idActivity);
        let activityData = await handleDataServer(activityResult, successData);

        // response from the server is the exacly same format as the defaultValues
        if (activityData) {
            setFormData(activityData);
        }
    };

    const populate = async () => {
        await populateOntologies();
        await populateDocuments();
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        let result = isEdit ?
            await activitiesApi.updateActivity(idActivity, formData) :
            await activitiesApi.addActivity(formData);

        let data = await handleDataServer(result, successData);
        if (data) {
            navigate(`/teacher`);
        }
    };

    const setDefaultData = () => {
        let values = {...defaultValues};

        // for each value in fields, update our selected values
        fields.forEach(field => {
            values['permissions'][0][field] = true; // moderator
            values['permissions'][1][field] = true; // student
        });

        // we only deactivate the semantics (free annotations only are the default)
        values['permissions'][0]['createSemantic'] = false;
        values['permissions'][1]['createSemantic'] = false;

        // update data
        setFormData(values);
    };

    useEffect(() => {
        // the activity must be called after the population of ontologies,
        // because in the activity it is returned only the ontology's name
        // and we need to get its id
        if (isEdit && !loaded && loadedOntologies) {
            populateActivity().then(el => el);
            setLoaded(true);
        }

        if (!loadedOntologies) {
            // make default values from fields
            setDefaultData();

            // populate from the database
            populate().then(el => el);

            setLoadedOntologies(true);
        }
    }, [ontologies]);

    return (
        <>
            <Card>
                <form
                    className={'form'}
                    style={{
                        display: "flex",
                        flexDirection: "column",
                    }}
                >
                    <h1>
                        {`${t(`general.${isEdit ? 'edit' : 'create'}`)} ${t('activities.titleNew')}`}
                        {isEdit && ` - ${idActivity}`}
                    </h1>

                    <h2>{t("activities.create.nameActivity")}</h2>
                    <TextField
                        label={t("general.name")}
                        name={"activity.name"}
                        value={formData.activity.name || ""}
                        onChange={(e) => handleFormChange(e)}
                        variant="outlined"
                        required={true}
                    />

                    {
                        getPermissionSemantic() &&
                        <div>
                            <h2>{t('semantic.singleTitle')}</h2>

                            <FormControl
                                variant="outlined"
                                style={{width: "100%"}}
                            >
                                <InputLabel id="select-ontology" className={'capitalize'}
                                            required={getPermissionSemantic()}>
                                    {t("semantic.singleTitle")}
                                </InputLabel>
                                <Select
                                    labelId={'select-ontology'}
                                    label={t("semantic.singleTitle")}
                                    name={'activity.ontology_id'}
                                    value={formData.activity.ontology_id || ""}
                                    onChange={(e) => handleFormChange(e)}
                                >
                                    {ontologies && ontologies.map(ontology =>
                                        <MenuItem key={ontology.id} value={ontology.id}>{ontology.name}</MenuItem>
                                    )}
                                </Select>
                            </FormControl>

                            <FormControlLabel
                                control={
                                    <Checkbox
                                        name={"activity.shared_knowledge_base"}
                                        checked={formData.activity.shared_knowledge_base || false}
                                        onChange={(e) => handleFormChange(e, true)}
                                    />
                                }
                                label={t("activities.create.knowledgeBaseShare")}
                            />
                        </div>
                    }

                    <div>
                        <h2>{t("documents.title")}</h2>
                        <Autocomplete
                            multiple
                            options={documents}
                            getOptionLabel={(option) => `${option.author} - ${option.title}`}
                            value={formData.documents || []}
                            onChange={(e, value) => handleSpecificData("documents", value)}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    variant="outlined"
                                    label={t("documents.title")}
                                    placeholder={t("documents.title")}
                                    required={true}
                                />
                            )}
                        />
                    </div>

                    <div>
                        <h2>{t("activities.create.permissions")}</h2>
                        {fields.map(field => (
                            <div key={field} style={{borderTop: "1px dotted #d7d7d7"}}>
                                <strong
                                    style={{
                                        width: "30vw",
                                        marginTop: "14px",
                                        display: "inline-table",
                                    }}
                                >
                                    {t(`activities.create.attributes.${field}`)}
                                </strong>

                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={formData.permissions[0][field] || false}
                                            onChange={(e) => handlePermissionCheckboxChange(e, 0, field)}
                                        />
                                    }
                                    label={t("general.moderator")}
                                />

                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={formData.permissions[1][field] || false}
                                            onChange={(e) => handlePermissionCheckboxChange(e, 1, field)}
                                        />
                                    }
                                    label={t("general.student")}
                                />
                            </div>
                        ))}
                    </div>

                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleSubmit}
                    >
                        {t(`general.${isEdit ? 'edit' : 'add'}`)}
                    </Button>
                </form>
            </Card>
        </>
    );
};

export default NewActivity;
