import {configureStore} from "@reduxjs/toolkit";
import coursesSlicer from './modules/Courses';
import semanticSlicer from "./modules/Semantic";
import documentsSlicer from "./modules/Documents";
import activitiesSlicer from "./modules/Activities";
import errorSlicer from "./modules/Error";
import confirmDialogSlicer from "store/modules/ConfirmDialog";
import {persistStore} from "redux-persist";
import {persistedUser} from "store/modules/User";
import enumerateSlicer from "store/modules/Enumerates";
import containerSlicer from "store/modules/Container";
import annotationsSlicer from "store/modules/Annotations";

const store = configureStore({
    reducer: {
        user: persistedUser,
        courses: coursesSlicer.reducer,
        ontologies: semanticSlicer.reducer,
        documents: documentsSlicer.reducer,
        activities: activitiesSlicer.reducer,
        errors: errorSlicer.reducer,
        deleteDialog: confirmDialogSlicer.reducer,
        enumerates: enumerateSlicer.reducer,
        container: containerSlicer.reducer,
        annotations: annotationsSlicer.reducer
    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({
        serializableCheck: false
    })
});

export const persistorStore = persistStore(store);

export default store;
