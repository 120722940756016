import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import coursesApi from "api/routes/courses";
import {handleDataServer, successData} from "api";

const coursesSlicer = createSlice({
    name: 'courses',
    initialState: {
        courses: [],
    },
    reducers: {
        setCourses: (state, action) => {
            state.courses = action.payload;
        }
    },
    extraReducers(builder) {
        // get courses
        builder
            .addCase(getCourses.fulfilled, (state, action) => {
                state.courses = action.payload;
            });
    }
});

// thunk functions (async)
export const getCourses = createAsyncThunk("courses/read",
    async (thunkAPI) => {
        return await handleDataServer(await coursesApi.getCourses(), successData);
    });

// getters
export const selectCourses = (state) => state.courses.courses;

// actions
export const {setCourses} = coursesSlicer.actions;

// slice
export default coursesSlicer;
