import React, {useEffect, useState} from 'react';
import {useAnnotationStore, useAnnotator} from "@annotorious/react";
import utils from "utils/utils";
import {URL_ACCESS_RIGHTS, URL_JSON_LD} from "components/container/recogito/MyW3CTextFormat";
import {useDispatch, useSelector} from "react-redux";
import {closeAnnotation, selectTypeAnnotation} from "store/modules/Annotations";

const CommonAnnotation = (
    {
        selected = [],
        children,
        isFake = false
    }
) => {
    const store = useAnnotationStore();
    const annotator = useAnnotator();
    const dispatch = useDispatch();

    const typeAnnotation = useSelector(selectTypeAnnotation);

    const [isReply, setIsReply] = useState(false);

    // if 'isFake' is set, we are only adding a semantic resource (through semantic annotation)
    // or displaying a fake annotation (through DisplayAnnotations)
    let annotationNow = isFake ? selected : selected[0].annotation;

    const [accessRights, setAccessRights] = useState(utils.accessRights.public);
    const [body, setBody] = useState("");

    const getTypeAnnotation = () => {
        if (isFake) {
            const purpose = annotationNow.bodies[0].purpose;
            if (purpose === "classifying") {
                return utils.typeAnnotation.semantic;
            } else {
                return utils.typeAnnotation.free;
            }
        } else {
            return typeAnnotation;
        }
    };

    const close = () => {
        annotator.cancelSelected();
        dispatch(closeAnnotation());
    };

    const deleteAnnotation = () => {
        annotator.removeAnnotation(annotationNow.id);
    };

    const cancel = () => {
        // if body is blank we remove the annotation, except for replies
        if (isFirst() && (body.value === undefined || body.value === '') && !isReply) {
            deleteAnnotation();
        }

        close();
    };

    const isFirst = () => {
        return annotationNow.bodies.length === 0;
    };

    const updateContextAccessRights = (old) => {
        const annotation = {...old};

        annotation['@context'] = [
            URL_JSON_LD,
            {
                "accessRights": URL_ACCESS_RIGHTS
            }
        ];

        annotation['accessRights'] = accessRights;

        return annotation;
    };

    const updateContext = (old, context) => {
        const annotation = {...old};
        annotation['@context'] = context;
        annotation['accessRights'] = accessRights;

        store.updateAnnotation(annotation.id, annotation);
    };

    const save = (context = false) => {
        // if passed target through parameter, we update the context
        if (context) {
            updateContext(annotationNow, context);
        } else {
            // else, we need at least the accessRights into the context
            store.updateAnnotation(annotationNow.id, updateContextAccessRights(annotationNow));
        }

        return saveSpecific(body);
    };

    const annotationWithContextTarget = (context, target) => {
        const newAnnotation = {...annotationNow};
        newAnnotation['@context'] = context;
        newAnnotation.target.specific = target;
        newAnnotation.accessRights = accessRights;

        return newAnnotation;
    };

    /**
     * Save annotation overriding the original context, and adding the target
     * specified. The body and accessRights must be set before calling this function.
     * This function must be called by SemanticAnnotation.
     * @param context Annotation context
     * @param target Target to be added to the annotation
     */
    const saveWithInfo = (context, target) => {
        store.updateAnnotation(annotationNow.id, annotationWithContextTarget(context, target));

        saveSpecific(body);
    };

    const saveSpecific = (bodyNow) => {
        if (
            !bodyNow || !bodyNow.value || bodyNow.value === '' ||
            (bodyNow.title !== undefined && bodyNow.title === '')
        ) return false;

        const alreadyExists = exists(bodyNow);

        if (!alreadyExists) {
            store.addBody(bodyNow);
        }

        if (alreadyExists) {
            store.updateBody(bodyNow, bodyNow);
        }

        return true;
    };

    const saveAndClose = (context = false) => {
        const flag = save(context);
        if (!flag) return;

        close();
    };

    const saveBodyBatch = (bodies) => {
        if (!Array.isArray(bodies)) return;

        bodies.forEach((b) => {
            saveSpecific(b);
        });
    };

    const exists = (bodyNow) => {
        return annotationNow.bodies.find(b => b.id === bodyNow.id) !== undefined;
    };

    const deleteBody = () => {
        store.deleteBody(body);
    };

    const deleteBodySpecific = (bodyDelete) => {
        if (exists(bodyDelete)) {
            store.deleteBody(bodyDelete);
        }
    };

    const changeBody = (key, value) => {
        setBody((prev) => ({...prev, [key]: value}));
    };

    const findBodyComment = (annotation) => {
        return annotation.bodies.find(b => b.annotation === annotation.id);
    };

    const getReplies = () => {
        return annotationNow.bodies.filter(
            (el, index) => el.purpose === "commenting" && index !== 0
        );
    };

    const getTags = () => {
        return annotationNow.bodies.filter(
            el => el.purpose === "tagging"
        );
    };

    useEffect(() => {
        changeBody('annotation', annotationNow.id);
    }, [annotationNow]);

    return (
        <>
            {children({
                selected,
                findBodyComment,
                close,
                deleteAnnotation,
                cancel,
                save,
                saveAndClose,
                saveBodyBatch,
                saveWithInfo,
                changeBody,
                body,
                deleteBody,
                deleteBodySpecific,
                getReplies,
                getTags,
                setBody,
                setIsReply,
                isReply,
                isFirst,
                accessRights,
                setAccessRights,
                annotationWithContextTarget,
                isFake,
                getTypeAnnotation,
                annotationNow
            })}
        </>
    );
};

export default CommonAnnotation;