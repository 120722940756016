import React, {useEffect, useState} from 'react';
import {Navigate, useNavigate, useParams} from "react-router-dom";
import useAuth from "hooks/useAuth";
import {useDispatch, useSelector} from "react-redux";
import {selectContainer, showContainer} from "store/modules/Container";
import MenuContainer from "components/container/MenuContainer";
import ActionsContainer from "components/container/ActionsContainer";

import {getAnnotations} from "store/modules/Annotations";
import DocumentAnnotator from "components/container/recogito/DocumentAnnotator";
import './style.css';
import abilityDefine, {AbilityContext} from "ability";
import usePermissionCourse from "hooks/usePermissionCourse";
import useRoleCourseContainer from "hooks/useRoleCourseContainer";

const ShowContainer = () => {
    const user = useAuth();
    const roleCourse = useRoleCourseContainer();
    const permission = usePermissionCourse();
    const container = useSelector(selectContainer);
    const sharedKnowledge = container ? container.activity.shared_knowledge_base : false;

    const ability = abilityDefine(user, permission, roleCourse, sharedKnowledge);

    const navigate = useNavigate();
    let {idContainer} = useParams();
    const dispatch = useDispatch();

    const [loaded, setLoaded] = useState(false);
    const target = window.location.href;

    const populate = async () => {
        dispatch(showContainer(parseInt(idContainer)));
        setTimeout(() => {
            dispatch(getAnnotations(target));
        }, 1000);
    };

    useEffect(() => {
        if (!user) {
            navigate('/');
        }

        if (!loaded) {
            populate().then(el => el);
            setLoaded(true);
        }
    }, []);

    return (
        <>
            {
                user === undefined ?
                    <Navigate to={'/'}/> :

                    // configure permission checking using the library 'casl'
                    <AbilityContext.Provider value={ability}>
                        <div>
                            <MenuContainer/>
                            <ActionsContainer/>

                            <div className={'container-html'}>
                                <DocumentAnnotator target={target}/>
                            </div>
                        </div>
                    </AbilityContext.Provider>
            }
        </>
    );
};

export default ShowContainer;
