import api from '../api';
import {failureCallback, JSON_CONTENT_TYPE, successCallback} from "../index";

export default {
    addResource: async (data) => {
        return await api.post('/api/resource/store', data, {
            headers: {JSON_CONTENT_TYPE}
        }).then(successCallback, failureCallback);
    },
    deleteResource: async (idResource, target, idContainer) => {
        return await api.delete('/api/resource/delete', {
            params: {
                id: idResource,
                target: target,
                container_id: idContainer
            }
        }).then(successCallback, failureCallback);
    }
};