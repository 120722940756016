import React, {useEffect, useState} from "react";
import GenericAddFile from "components/protected/GenericAddFile";
import documentsApi from "api/routes/documents";
import {handleDataServer, successData} from "api";
import {useNavigate, useParams} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {getDocuments, selectDocuments} from "store/modules/Documents";
import {useDispatch, useSelector} from "react-redux";
import {getRouteRole} from "utils/utils";
import useAuth from "hooks/useAuth";

const NewDocument = () => {
    const user = useAuth();
    const {t} = useTranslation();
    const navigate = useNavigate();
    const [loaded, setLoaded] = useState(false);
    const [document, setDocument] = useState(null);

    let {idDocument} = useParams();
    const isEdit = idDocument !== undefined;

    const dispatch = useDispatch();
    const documents = useSelector(selectDocuments);

    const getDocument = () => {
        return isEdit ? documents.find(el => el.id === parseInt(idDocument)) : null;
    };

    const handleSubmit = async (formData) => {
        let result = isEdit ?
            await documentsApi.updateDocument(idDocument, formData) :
            await documentsApi.addDocument(formData);

        let data = await handleDataServer(result, successData);
        if (data) {
            navigate(`${getRouteRole(user)}/documents`);
        }
    };

    useEffect(() => {
        if (!loaded) {
            dispatch(getDocuments());
            setLoaded(true);
        }

        setDocument(getDocument());
    }, []);

    return (
        <GenericAddFile
            isEdit={isEdit}
            data={document}
            titlePage={t("documents.singleTitle")}
            typeFiles={['html', 'htm']}
            nameTitle={'title'}
            handleSubmit={handleSubmit}
        />
    );
};

export default NewDocument;
