import React from 'react';
import CommonAnnotation from "components/container/recogito/popup/CommonAnnotation";
import ViewCommonAnnotation from "components/container/recogito/popup/ViewAnnotation/ViewCommonAnnotation";
import Grid from "@mui/material/Grid";

const DisplayAnnotations = (
    {
        annotations = []
    }
) => {

    const getAnnotation = (annotation) => {
        const bodies = JSON.parse(JSON.stringify(annotation.body));
        bodies.forEach(body => {
            body.annotation = annotation.id;
        });

        return {
            id: annotation.id,
            '@context': annotation['@context'],
            accessRights: annotation.accessRights,
            bodies: bodies,
            target: {
                annotation: annotation.id,
                created: annotation.created,
                creator: annotation.creator,
                selector: annotation.target[0].selector,
                updated: annotation.modified
            }
        };
    };

    return (
        <>
            {
                annotations.length ?
                    annotations.map((annotation, index) => (
                        <Grid key={index} item xs={6}>
                            <CommonAnnotation
                                isFake={true}
                                selected={getAnnotation(annotation)}
                                children={props => (
                                    <ViewCommonAnnotation {...props} />
                                )}
                            />
                        </Grid>
                    ))
                    : null
            }
        </>
    );
};

export default DisplayAnnotations;