import React from 'react';
import TreeEntry from "components/TreeScheme/TreeEntry";
import './style.css';
import TooltipIcon from "components/TooltipIcon";
import {recursiveFindArray, recursiveFindObject} from "utils/semantic";
import {v4 as uuid} from 'uuid';

const TreeScheme = (
    {
        items,
        setItems,
        selected,
        setSelected,
        readonly = false,
        blocked = false
    }
) => {
    const defaultItem = {
        id: "",
        display: "",
        sameAs: "",
        children: [],
        properties: [],
        relations: [],
        mainPropertyId: ""
    };

    const getNewItem = () => {
        let newItem = {...defaultItem};
        newItem.id = uuid();

        return newItem;
    };

    const addSibling = () => {
        let itemsNow = [...items];

        if (!selected) {
            // if it does not have one selected, push at the end
            itemsNow.push(getNewItem());
        } else {
            // it there is one selected, find the element and push to it
            let find = recursiveFindArray(itemsNow, selected);
            find.push(getNewItem());
        }

        setItems(itemsNow);
    };

    const addChild = () => {
        if (!selected) return;

        let itemsNow = [...items];

        let find = recursiveFindObject(itemsNow, selected);
        find.children.push(getNewItem());

        setItems(itemsNow);
    };

    const deleteItem = () => {
        if (!selected) return;

        let itemsNow = [...items];

        let find = recursiveFindArray(itemsNow, selected);
        find.splice(find.indexOf(selected), 1);

        setItems(itemsNow);
        setSelected(false);
    };

    const changeAttributeSelected = (attribute, value) => {
        let itemsNow = [...items];
        const element = recursiveFindObject(itemsNow, selected);
        element[attribute] = value;

        setItems(itemsNow);
    };

    return (
        <div>
            {!readonly &&
                <div>
                    <TooltipIcon tooltipText={'semantic.addSibling'} icon={'add'} onClick={addSibling}/>
                    <TooltipIcon tooltipText={'semantic.addChild'} icon={'playlist_add'} onClick={addChild}/>
                    <TooltipIcon tooltipText={'general.delete'} icon={'delete'} onClick={deleteItem}/>
                </div>
            }

            <div style={{padding: '10px', opacity: blocked ? '0.7' : '1'}}>
                {items?.map((item, index) =>
                    <TreeEntry
                        key={index}
                        entry={item}
                        depth={1}
                        onSelected={setSelected}
                        selected={selected}
                        changeAttributeSelected={changeAttributeSelected}
                        readonly={readonly}
                        blocked={blocked}
                    />
                )}
            </div>
        </div>
    );
};

export default TreeScheme;
