import React from "react";
import {HashRouter, useRoutes} from "react-router-dom";

// Auth Pages
import Login from "./pages/auth/Login";
import Create from "./pages/auth/Create";
import Forgot from "./pages/auth/Forgot";
import ConfirmRecover from "./pages/auth/ConfirmRecover";

// Student pages
import IndexStudent from "./pages/student/IndexStudent";

// Protected pages
import IndexProtected from "./pages/protected/IndexProtected";
import Documents from "./pages/protected/documents/read";
import NewDocuments from "./pages/protected/documents/new";
import SemanticSchemes from "./pages/protected/semantic/read";
import NewSemanticSchemes from "./pages/protected/semantic/new";
import NewCourse from "./pages/protected/courses/new";
import NewActivity from "./pages/protected/activities/new";
import ReadUsers from "./pages/protected/courses/users/read";
import Protected from "pages/protected/Protected";
import ProtectedStudent from "./pages/student/ProtectedStudent";
import AuthTemplate from "./pages/common/AuthTemplate";

// Admin pages
import ChangeRoleUser from "pages/admin/ChangeRoleUser";
import SchemeDefinition from "pages/protected/semantic/SchemeDefinition";
import EnumerateRead from "pages/protected/semantic/enumerates/read";
import EnumerateNew from "pages/protected/semantic/enumerates/new";
import ShowContainer from "pages/common/container/show";

// Index Authenticated

const protectedRoutes = ['/teacher', '/admin'].map(path => (
    {
        path: path,
        element: <Protected/>,
        children: [
            {
                index: true,
                element: <IndexProtected/>
            },
            {
                path: 'documents',
                children: [
                    {
                        index: true,
                        element: <Documents/>,
                    },
                    {
                        path: 'new',
                        element: <NewDocuments/>,

                    },
                    {
                        path: ":idDocument/edit",
                        element: <NewDocuments/>
                    },
                    {
                        path: ':idDocument/show',
                        element: <></>
                    }
                ]
            },
            {
                path: 'semantic',
                children: [
                    {
                        index: true,
                        element: <SemanticSchemes/>,
                    },
                    {
                        path: 'new',
                        element: <NewSemanticSchemes/>
                    },
                    {
                        path: ':idOntology/edit',
                        element: <NewSemanticSchemes/>
                    },
                    {
                        path: ':idOntology/scheme',
                        children: [
                            {
                                index: true,
                                element: <SchemeDefinition/>
                            }
                        ]
                    },
                    {
                        path: "enumerates",
                        children: [
                            {
                                index: true,
                                element: <EnumerateRead/>
                            },
                            {
                                path: 'new',
                                element: <EnumerateNew/>
                            },
                            {
                                path: ':idEnumerate/edit',
                                element: <EnumerateNew/>
                            }
                        ]
                    }
                ]
            },
            {
                path: 'courses',
                children: [
                    {
                        path: 'new',
                        element: <NewCourse/>
                    },
                    {
                        path: ':idCourse/edit',
                        element: <NewCourse/>
                    },
                    {
                        path: ':idCourse/activities',
                        children: [
                            {
                                path: 'new',
                                element: <NewActivity/>
                            },
                            {
                                path: ':idActivity/edit',
                                element: <NewActivity/>
                            }
                        ],
                    },
                    {
                        path: ':idCourse/users',
                        children: [
                            {
                                index: true,
                                element: <ReadUsers/>
                            }
                        ]
                    }
                ]
            }
        ]
    }
));

const otherRoutes = [
    // admin routes
    {
        path: '/admin',
        element: <Protected/>,
        children: [
            {
                path: 'change-role',
                element: <ChangeRoleUser/>
            }
        ]
    },

    // user routes
    {
        path: '/student',
        element: <ProtectedStudent/>,
        children: [
            {
                index: true,
                element: <IndexStudent/>
            }
        ],
    },

    // container routes
    {
        path: "container",
        children: [
            {
                path: ":idContainer/show",
                element: <ShowContainer/>
            }
        ]
    },

    // auth routes (unprotected)
    {
        element: <AuthTemplate/>,
        path: '/',
        children: [
            {
                index: true,
                element: <Login/>,
            },
            {
                path: '/create',
                element: <Create/>,
            },
            {
                path: '/forgot',
                element: <Forgot/>,
            },
            {
                path: '/confirm-password/:email/:token',
                element: <ConfirmRecover/>,
            }
        ],
    },
];

const allRoutes = protectedRoutes.concat(otherRoutes);

const Routes = () => useRoutes(allRoutes);

const router = () => (
    <HashRouter>
        <Routes/>
    </HashRouter>
);

export default router;
