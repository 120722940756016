import React from 'react';
import {Stack} from "@mui/material";
import Typography from "@mui/material/Typography";
import {useTranslation} from "react-i18next";
import {SERVER_URI} from "api";
import {mediumModal} from "./style";
import TooltipIcon from "components/TooltipIcon";
import ModalClose from "components/protected/modal/ModalClose";

const ModalMoodle = (
    {
        open,
        handleClose,
        document = {},
    }
) => {
    const {t} = useTranslation();

    const copyClipboard = (content) => {
        navigator.clipboard.writeText(content).then(r => r);
    };

    // if the URL is null, we need to get the URL from the window.location
    const getUrl = () => {
        if (SERVER_URI === "") {
            return `${window.location.origin}/lti`;
        } else {
            return `${SERVER_URI}/lti`;
        }
    };

    const copyContent = (typeCopy) => {
        switch (typeCopy) {
            case "url":
                copyClipboard(getUrl());
                break;
            case "key":
                copyClipboard(document.container.lti_key);
                break;
            case "secret":
                copyClipboard(document.container.lti_secret);
                break;
        }
    };

    return (
        <ModalClose
            open={open}
            onClose={handleClose}
            style={mediumModal}
        >
            <>
                <Typography variant={'h6'} component={'h2'}>
                    {t('documents.singleTitleCaps')} {document.title}
                </Typography>
                {document.container &&
                    <div>
                        <Stack>
                            <Stack direction={'row'} alignItems={'center'} alignContent={'center'}>
                                <b className={'marginRight8'}>URL:</b>
                                <code>{getUrl()}</code>
                                <TooltipIcon icon={'content_copy'} tooltipText={'general.copy'}
                                             onClick={(e) => copyContent('url')}/>
                            </Stack>
                            <Stack direction={'row'} alignItems={'center'} alignContent={'center'}>
                                <b className={'marginRight8'}>{t('general.key')}:</b>
                                <code>{document.container.lti_key}</code>
                                <TooltipIcon icon={'content_copy'} tooltipText={'general.copy'}
                                             onClick={(e) => copyContent('key')}/>
                            </Stack>
                            <Stack direction={'row'} alignItems={'center'} alignContent={'center'}>
                                <b className={'marginRight8'}>{t('general.secret')}:</b>
                                <code>{document.container.lti_secret}</code>
                                <TooltipIcon icon={'content_copy'} tooltipText={'general.copy'}
                                             onClick={(e) => copyContent('secret')}/>
                            </Stack>
                        </Stack>
                    </div>
                }
            </>
        </ModalClose>
    );
};
export default ModalMoodle;