import {AbilityBuilder, buildMongoQueryMatcher, createMongoAbility} from "@casl/ability";
import {createContext} from "react";
import {createContextualCan} from "@casl/react";
import {SERVER_URI} from "api";
import utils from "utils/utils";
import {$nor, nor} from '@ucast/mongo2js';

export const ACTION = {
    CREATE: 'create',
    READ: 'read',
    UPDATE: 'update',
    DELETE: 'delete',
    ACTION: 'action', // generic action to be used for activity permissions (on container/annotations)
    SEIZE: 'seize', // only if shared knowledge base is enabled
};

const conditionsMatcher = buildMongoQueryMatcher({$nor}, {nor});

export default function defineAbilityFor(user, permissions, roleCourse, sharedKnowledgeBase) {
    const {can, build} = new AbilityBuilder(createMongoAbility);

    // activity permissions
    if (permissions) {
        for (const [key, value] of Object.entries(permissions)) {
            if (value) {
                can(ACTION.ACTION, key);
            }
        }
    }

    // course permissions
    if (roleCourse === utils.rolesCourses.owner || roleCourse === utils.rolesCourses.moderator) {
        can(ACTION.READ, 'annotationReport');
    }

    // User URI for annotations
    const uriUser = `${SERVER_URI}/user/${user.id}`;

    // shared knowledge base
    if (sharedKnowledgeBase) {
        can(ACTION.ACTION, 'seizeAnnotation');

        // I can seize an annotation that do not belong to me
        can(ACTION.SEIZE, 'Annotation', {
            $nor: [{user_id: uriUser}]
        });
    }

    // general behaviour for objects that must belong to the user
    can(ACTION.UPDATE, 'Annotation', {user_id: uriUser});
    can(ACTION.DELETE, 'Annotation', {user_id: uriUser});

    can(ACTION.UPDATE, 'Reply', {user_id: uriUser});
    can(ACTION.DELETE, 'Reply', {user_id: uriUser});

    return build({ conditionsMatcher });
}

export const AbilityContext = createContext();
export const Can = createContextualCan(AbilityContext.Consumer);

export class Entity {
    constructor(attrs) {
        Object.assign(this, attrs);
    }
}

export class Annotation extends Entity {
    // modelName is necessary so the library CASL identify in production the class name
    // https://stackoverflow.com/questions/63788118/react-casl-conditions-not-working-in-production-mode-reactjs
    static modelName = 'Annotation';
}

export class Reply extends Entity {
    static modelName = 'Reply';
}