import api from "../api";
import {failureCallback, JSON_CONTENT_TYPE, successCallback} from "../index";

export default {
    getActivities: async(idCourse) => {
        return await api.get('/api/activities/index', {
            params: {
                course_id: idCourse
            }
        }).then(successCallback, failureCallback);
    },
    showActivity: async(idActivity) => {
        return await api.get('/api/activities/show', {
            params: {
                id: idActivity
            }
        }).then(successCallback, failureCallback);
    },
    addActivity: async(json) => {
        return await api.post('/api/activities/store', json, {
            headers: {JSON_CONTENT_TYPE}
        }).then(successCallback, failureCallback);
    },
    updateActivity: async(idActivity, json) => {
        return await api.post('/api/activities/update', json, {
            params: {
                id: idActivity
            },
            headers: {JSON_CONTENT_TYPE}
        }).then(successCallback, failureCallback);
    },
    deleteActivity: async(idActivity) => {
        return await api.delete('/api/activities/delete', {
            params: {
                id: idActivity
            }
        }).then(successCallback, failureCallback);
    },
};
