import React from 'react';
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import {FormControl} from "@mui/material";
import {useTranslation} from "react-i18next";

// The purpose of a W3C Annotation body. Here, we correlate
// between the following purposes and the corresponding W3C
// Comment, Recommendation, Task, Example,
// Explanation, Question, See also
export const purposes = [
    'commenting', 'highlighting', 'moderating', 'describing',
    'editing', 'questioning', 'bookmarking'
];

const SelectFreeAnnotationType = (
    {
        hasAll = false,
        onChange = () => {},
        value = ""
    }
) => {
    const {t} = useTranslation();

    return (
        <FormControl
            variant="outlined"
            style={{width: "100%"}}
        >
            <InputLabel id={'freeAnnotationType'}>
                {t("annotation.freeAnnotationType")}
            </InputLabel>
            <Select
                labelId={'freeAnnotationType'}
                label={t("annotation.freeAnnotationType")}
                value={value}
                onChange={(e) => onChange(e.target.value)}
            >
                {hasAll &&
                    <MenuItem value={'all'}>{t('general.all')}</MenuItem>
                }

                {
                    purposes.map(purpose =>
                        <MenuItem key={purpose} value={purpose}>{t(`annotation.purpose.${purpose}`)}</MenuItem>
                    )
                }
            </Select>
        </FormControl>
    );
};

export default SelectFreeAnnotationType;