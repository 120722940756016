import React, {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import semanticApi from "api/routes/semantic";
import {handleDataServer, successData} from "api";
import {useTranslation} from "react-i18next";
import GenericAddDescription from "components/protected/GenericAddDescription";
import {useDispatch, useSelector} from "react-redux";
import {getOntologies, selectOntologies} from "store/modules/Semantic";
import {getRouteRole} from "utils/utils";
import useAuth from "hooks/useAuth";

const NewSemanticSchemes = () => {
    const user = useAuth();
    const {t} = useTranslation();
    const navigate = useNavigate();
    const [loaded, setLoaded] = useState(false);

    const [name, setName] = useState("");
    const [description, setDescription] = useState("");
    const [isPublic, setIsPublic] = useState(true);

    let {idOntology} = useParams();
    const isEdit = idOntology !== undefined;
    let ontology;

    const dispatch = useDispatch();
    const ontologies = useSelector(selectOntologies);

    const getOntology = () => {
        return isEdit ? ontologies.find(el => el.id === parseInt(idOntology)) : null;
    };

    const handleSubmit = async () => {
        let result = isEdit ?
            await semanticApi.updateOntology(idOntology, name, description, isPublic) :
            await semanticApi.addOntology(name, description, isPublic);

        let data = await handleDataServer(result, successData);
        if (data) {
            navigate(`${getRouteRole(user)}/semantic`);
        }
    };

    useEffect(() => {
        if (!loaded) {
            dispatch(getOntologies());
            setLoaded(true);
        }

        ontology = getOntology();

        if (isEdit && ontology) {
            setName(ontology.name);
            setDescription(ontology.description);
            setIsPublic(ontology.isPublic);
        }
    }, [ontologies]);

    return (
        <GenericAddDescription
            isEdit={isEdit}
            nameTitle={t("semantic.singleTitle")}
            name={name}
            setName={(e) => setName(e.target.value)}
            description={description}
            setDescription={(e) => setDescription(e.target.value)}
            isPublic={isPublic}
            setIsPublic={setIsPublic}
            hasIsPublic={true}
            handleSubmit={handleSubmit}
        />
    );
};

export default NewSemanticSchemes;
