import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import {useNavigate, useParams} from "react-router-dom";
import Card from "@mui/material/Card";
import TreeScheme from "components/TreeScheme/TreeScheme";
import PropertiesRelations from "components/PropertiesRelations/PropertiesRelations";
import {Stack} from "@mui/material";
import semanticApi from 'api/routes/semantic';
import {handleDataServer, successData, successStatus} from "api";
import {getRouteRole} from "utils/utils";
import useAuth from "hooks/useAuth";
import {useDispatch} from "react-redux";
import {setOntology} from "store/modules/Semantic";
import Button from "@mui/material/Button";
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';
import {setError} from "store/modules/Error";

const SchemeDefinition = () => {
    const user = useAuth();
    const {t} = useTranslation();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const routeSemantic = `${getRouteRole(user)}/semantic`;

    const [items, setItems] = useState([]);
    const [selected, setSelected] = useState(false);

    let {idOntology} = useParams();

    const populate = async () => {
        const data = await handleDataServer(await semanticApi.showOntology(parseInt(idOntology)), successData);

        if (data) {
            dispatch(setOntology(data));

            const structure = data.ontology.structure;
            let copyStructure = JSON.parse(JSON.stringify(structure));
            if (structure) {
                setItems(copyStructure);
            }
        }
    };

    const handleSubmit = async () => {
        // check mainPropertyId for all classes
        const notFound = items.filter(el => !el.mainPropertyId)?.map(el => el.display);
        if (notFound && notFound.length) {
            const stringNotFound = notFound.join(', ');
            dispatch(setError(t('semantic.setRequiredError', {display: stringNotFound})));
            return;
        }

        const result = await handleDataServer(await semanticApi.saveScheme(parseInt(idOntology), items), successStatus);

        if (result) {
            navigate(routeSemantic);
        }
    };

    useEffect(() => {
        populate().then(el => el);
    }, []);

    return (
        <Card sx={{minHeight: '400px'}}>
            <Stack direction={'row'} justifyContent={'space-between'}>
                <h1>{t('semantic.addTitle')}</h1>

                <div>
                    <Button
                        variant={'contained'}
                        className={'marginRight8'}
                        endIcon={<CancelIcon />}
                        onClick={() => navigate(routeSemantic)}
                    >
                        {t('general.cancel')}
                    </Button>
                    <Button
                        variant={'contained'}
                        endIcon={<SaveIcon />}
                        onClick={handleSubmit}
                    >
                        {`${t('general.save')} ${t('semantic.singleTitle')}`}
                    </Button>
                </div>
            </Stack>

            <Stack direction={'row'} flexWrap={'wrap'}>
                <TreeScheme
                    items={items}
                    setItems={setItems}
                    selected={selected}
                    setSelected={setSelected}
                />

                <PropertiesRelations
                    items={items}
                    setItems={setItems}
                    selected={selected}
                />
            </Stack>
        </Card>
    );
};

export default SchemeDefinition;
