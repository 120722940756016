import {Link} from "react-router-dom";
import Button from "@mui/material/Button";
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";
import useAuth from "hooks/useAuth";
import {getRouteRole, isAdmin} from "utils/utils";

const MenuProtected = (
    {
        isMobile = false,
        handleCloseNavMenu = () => {
        },
        logout = () => {
        }
    }
) => {
    const [elements, setElements] = useState([]);
    const {t} = useTranslation();
    const user = useAuth();

    useEffect(() => {
        let elementsNew = [
            {
                to: `${getRouteRole(user)}`,
                title: t("courses.title")
            },
            {
                to: `${getRouteRole(user)}/documents`,
                title: t("documents.title")
            },
            {
                to: `${getRouteRole(user)}/semantic`,
                title: t("semantic.title")
            },
            {
                to: "",
                title: "Logout",
                isMobile: true,
                action: logout
            }
        ];

        if (isAdmin(user)) {
            elementsNew.push({
                to: "/admin/change-role",
                title: t("admin.users")
            });
        }

        setElements(elementsNew);
    }, []);

    return (
        <>
            {isMobile &&
                elements.map((element, i) => (
                    <Link key={i} to={element.to}>
                        <MenuItem key={i} onClick={element.action ? element.action : handleCloseNavMenu}>
                            <Typography textAlign="center">
                                <Button color="inherit">{element.title}</Button>
                            </Typography>
                        </MenuItem>
                    </Link>
                ))
            }

            {!isMobile &&
                elements.filter(el => !el.isMobile).map((element, i) => (
                    <Link key={i} to={element.to}>
                        <Button color="inherit">{element.title}</Button>
                    </Link>
                ))
            }
        </>
    );
};

export default MenuProtected;
