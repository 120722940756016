import { createSlice } from "@reduxjs/toolkit";
import storage from "redux-persist/lib/storage";
import {setBearerToken, setCSRFToken} from "api/api";
import {persistReducer} from "redux-persist";

const userSlicer = createSlice({
    name: 'user',
    initialState: {
        loaded: false,
        user: false,
        permissions: {},
        token: ""
    },
    reducers: {
        setUser: (state, action) => {
            state.user = action.payload;
            state.loaded = true;
        },
        setToken: (state, action) => {
            state.token = action.payload;
        }
    },
});

const persistConfig = {
    key: "user",
    storage,
    migrate: async (state) => {
        if (state && state.token) {
            const result = await setCSRFToken();

            if (result === undefined) {
                return Promise.reject("CSRF token");
            }

            setBearerToken(state.token);
            return Promise.resolve(state);
        }
    }
};

// persist config
export const persistedUser = persistReducer(persistConfig, userSlicer.reducer);

// getters
export const selectUser = (state) => state.user.user;

// actions
export const { setUser, setToken } = userSlicer.actions;

// slice
export default userSlicer;
