import React from "react";
import Button from "@mui/material/Button";
import {Link} from "react-router-dom";
import Card from "@mui/material/Card";
import {useTranslation} from "react-i18next";
import {Table, TableBody, TableCell, TableHead, TableRow} from "@mui/material";
import TooltipIcon from "components/TooltipIcon";

const tdStyle = {
    maxWidth: '100px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap'
};

const GenericRead = (
    {
        title,
        titleAdd,
        linkAdd,
        iterate = [],
        iterateKeys = [{
            key: "name",
            label: "Name",
            isLink: false
        }],
        actions = [],
        onEdit = false,
        isDisabledEdit = false,
        onDelete = false,
        isDisabledDelete = false,
        buttons = []
    }
) => {
    const {t} = useTranslation();

    return (
        <Card>
            <h1>{title}</h1>

            <Button style={{margin: "8px", marginLeft: '0'}} variant="contained" color="primary">
                <Link to={linkAdd}>
                    {t("general.create")} {titleAdd}
                </Link>
            </Button>

            {buttons?.map((button, index) =>
                <Button key={index} style={{margin: "8px"}} variant="contained" color="primary"
                        onClick={button.action}>
                    {t(button.label)}
                </Button>
            )}

            {iterate.length ?
                <Table className={'thead-bold'}>
                    <TableHead>
                        <TableRow>
                            {iterateKeys.map((iterateKey, index) =>
                                <TableCell align={'left'} className={'capitalize'} key={index}>
                                    {iterateKey.label}
                                </TableCell>
                            )}
                            <TableCell align={'left'}>{t("general.actions")}</TableCell>
                        </TableRow>
                    </TableHead>

                    <TableBody>
                        {
                            iterate.map((iterateNow, index) =>
                                <TableRow key={index}>
                                    {iterateKeys.map((iterateKey, index) =>
                                        <TableCell key={index} sx={tdStyle}>
                                            {iterateNow[iterateKey.key] !== undefined && iterateNow[iterateKey.key] !== null ?
                                                iterateKey.isLink ?
                                                    <Link to={iterateNow[iterateKey.key].toString()} target={'_blank'}
                                                          className={'link'}>
                                                        {iterateNow[iterateKey.key].toString()}
                                                    </Link> :
                                                    typeof iterateNow[iterateKey.key] === 'boolean' ?
                                                        t(`boolean.${iterateNow[iterateKey.key].toString()}`) :
                                                        iterateNow[iterateKey.key].toString() :
                                                ""
                                            }
                                        </TableCell>
                                    )}
                                    <TableCell>
                                        {actions?.map((action, index) => (
                                                <TooltipIcon key={index} tooltipText={action.label} icon={action.icon}
                                                             onClick={() => action.action(iterateNow.id)}
                                                             disabled={action.disabled !== undefined ? action.disabled(iterateNow) : false}
                                                />
                                            ))
                                        }

                                        {onEdit &&
                                            <TooltipIcon tooltipText={"general.edit"} icon={"edit"}
                                                         onClick={() => onEdit(iterateNow.id)}
                                                         disabled={typeof isDisabledEdit === 'function' ? isDisabledEdit(iterateNow) : isDisabledEdit}
                                            />
                                        }

                                        {onDelete &&
                                            <TooltipIcon tooltipText={"general.delete"} icon={"delete"}
                                                         onClick={() => onDelete(iterateNow.id)}
                                                         disabled={typeof isDisabledDelete === 'function' ? isDisabledDelete(iterateNow) : isDisabledDelete}
                                            />
                                        }
                                    </TableCell>
                                </TableRow>
                            )
                        }
                    </TableBody>
                </Table> :
                <p>{t("genericRead.notFound", {title: titleAdd})}</p>
            }
        </Card>
    );
};

export default GenericRead;
