import React from 'react';
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import {useTranslation} from "react-i18next";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";

const GenericAddDescription = (
    {
        isEdit,
        nameTitle,
        name,
        setName,
        description,
        isPublic,
        setIsPublic,
        setDescription,
        handleSubmit,
        hasDescription = true,
        hasIsPublic = false
    }
) => {
    const {t} = useTranslation();

    return (
        <Card>
            <form
                style={{
                    display: "flex",
                    flexDirection: "column",
                }}
            >
                <h1>
                    {`${t(`general.${isEdit ? 'edit' : 'create'}`)} ${nameTitle}`}
                </h1>

                <TextField
                    value={name}
                    onChange={setName}
                    label={t("general.name")}
                    variant="outlined"
                    className={'marginBottom8'}
                    required={true}
                />

                {hasDescription &&
                    <TextField
                        value={description}
                        onChange={setDescription}
                        label={t("general.description")}
                        variant="outlined"
                        className={'marginBottom8'}
                    />
                }

                {hasIsPublic &&
                    <FormControlLabel
                        control={
                            <Checkbox
                                name={'isPublic'}
                                checked={isPublic || false}
                                onChange={(e) => setIsPublic(e.target.checked)}
                            />
                        }
                        label={t("general.public")}
                    />
                }

                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleSubmit}
                >
                    {t(`general.${isEdit ? 'edit' : 'add'}`)}
                </Button>
            </form>
        </Card>
    );
};

export default GenericAddDescription;
