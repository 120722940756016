import React, {useEffect, useState} from 'react';
import Grid from "@mui/material/Grid";
import {useSelector} from "react-redux";
import {selectResources} from "store/modules/Container";
import utils from "utils/utils";
import {useTranslation} from "react-i18next";

const ViewSemanticSpecific = (props) => {
    const {t} = useTranslation();
    const resources = useSelector(selectResources);

    const [propertiesKeys, setPropertiesKeys] = useState([]);
    const [relationsKeys, setRelationsKeys] = useState([]);

    const IGNORE = ['@id', '@type'];

    const getRelation = (key, value) => {

        const resourceRelation = resources.find(el => el.id === utils.getBasename(value['@id']));
        if (resourceRelation === undefined) {
            return null;
        }

        return (
            <span
                onClick={() => window.open(value['@id'], "_blank")}
                className={'pointer link'}
            >
                <b>{key}:</b> {resourceRelation.value_search} ({t('annotation.type')}: {resourceRelation.type})
            </span>
        );
    };

    const getDefaultValue = (key, value) => {
        return (
            <span>
                <b>{key}:</b> {value}
            </span>
        );
    };

    const getValueSpecific = (keyNow) => {
        // check if it is an enumerate
        if (props.body.value[keyNow].additionalType) {
            return getDefaultValue(keyNow, props.body.value[keyNow].additionalType);
        }

        // default property
        return getDefaultValue(keyNow, props.body.value[keyNow]);
    };

    const getRelations = () => {
        const items = [];
        relationsKeys.forEach(keyNow => {
            const arrayRelations = props.body.value[keyNow];

            arrayRelations.forEach((relation, index) => {
                items.push(
                    <Grid item xs={6} sx={{padding: '2px'}} key={index}>
                        {getRelation(keyNow, relation)}
                    </Grid>
                );
            });
        });

        return items;
    };

    useEffect(() => {
        const values = props.body.value;
        if (!values || props.body.purpose !== 'classifying') return;

        const propertiesKeys = [];
        const relationKeys = [];
        for (const [key, value] of Object.entries(values)) {
            if (IGNORE.includes(key)) continue;

            if (typeof value === 'object') {
                if (Array.isArray(value)) {
                    // if it is an array, it is a relation
                    relationKeys.push(key);
                } else if (value.additionalType) {
                    // if it is an object with 'additionalType', it is an enumerate property
                    propertiesKeys.push(key);
                }
            } else {
                // if it is a string, it is a property
                propertiesKeys.push(key);
            }
        }
        setPropertiesKeys(propertiesKeys);
        setRelationsKeys(relationKeys);
    }, [props.body]);

    return (
        <>
            {props.body.value && props.body.value['@type'] !== '' && props.body.purpose === 'classifying' &&
                <Grid container>
                    <Grid item xs={4} sx={{padding: '2px'}}>
                        <span><b>Instance:</b> {props.body.value['@type']}</span>
                    </Grid>

                    {
                        // properties
                        propertiesKeys.map((keyNow, index) => (
                            <Grid item xs={4} sx={{padding: '2px'}} key={index}>
                                {getValueSpecific(keyNow)}
                            </Grid>
                        ))
                    }

                    {
                        // relations
                        getRelations()
                    }
                </Grid>
            }
        </>
    );
};

export default ViewSemanticSpecific;