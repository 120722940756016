import React from 'react';
import {Modal} from "@mui/material";
import TooltipIcon from "components/TooltipIcon";
import {modalStyle, miniModal} from "components/protected/modal/style";
import Box from "@mui/material/Box";

const ModalClose = (
    {
        open,
        onClose,
        style = miniModal,
        children,
    }
) => {
    return (
        <Modal
            open={open}
            onClose={onClose}
        >
            <Box sx={[modalStyle, style && style]}>
                <span className={'close'}>
                    <TooltipIcon icon={'close'} tooltipText={'general.close'} onClick={onClose}/>
                </span>

                {children}
            </Box>
        </Modal>
    );
};

export default ModalClose;