import React, {useState} from 'react';
import Typography from "@mui/material/Typography";
import FilterAnnotations from "components/container/ReportAnnotations/FilterAnnotations";
import {useTranslation} from "react-i18next";
import DisplayAnnotations from "components/container/ReportAnnotations/DisplayAnnotations";
import Grid from "@mui/material/Grid";
import ModalClose from "components/protected/modal/ModalClose";

const ReportAnnotation = (
    {
        open,
        setOpen
    }
) => {
    const {t} = useTranslation();

    const [annotationsFilter, setAnnotationsFilter] = useState([]);

    const modalReportStyle = {
        maxHeight: '600px',
        overflow: 'auto',
    };

    return (
        <ModalClose
            open={open}
            onClose={() => setOpen(false)}
            style={modalReportStyle}
        >
            <>
                <Typography variant={'h6'} component={'h2'}>
                    {t('container.annotationReport')}
                </Typography>
                <div>
                    <FilterAnnotations onChange={(value) => setAnnotationsFilter(value)}/>

                    <Grid container direction={'row'} alignItems={'stretch'}>
                        <DisplayAnnotations annotations={annotationsFilter}/>
                    </Grid>
                </div>
            </>
        </ModalClose>
    );
};

export default ReportAnnotation;