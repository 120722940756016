import {useEffect, useState} from "react";
import {useAnnotator} from "@annotorious/react";
import {Annotation} from "ability";

const useObjectAnnotation = (isFake = false, selected = false) => {
    const annotator = useAnnotator();
    const [annotation, setAnnotation] = useState(new Annotation({}));

    useEffect(() => {
        if (isFake) {
            const creator = selected.target.creator.id;
            setAnnotation(new Annotation({user_id: creator}));
            return;
        }

        const annotationNow = annotator.getSelected()[0];

        if (annotationNow) {
            const creatorAnnotation = annotationNow.creator.id;
            setAnnotation(new Annotation({user_id: creatorAnnotation}));
        }
    }, [annotator]);

    return annotation;
};

export default useObjectAnnotation;