import React, {useEffect, useState} from 'react';
import {Autocomplete, createFilterOptions} from "@mui/material";
import TextField from "@mui/material/TextField";
import {v4 as uuid} from 'uuid';
import {useDispatch} from "react-redux";
import {setMustClose} from "store/modules/Annotations";

const TagSelector = (props) => {

    const dispatch = useDispatch();

    const defaultTag = {
        id: uuid(),
        annotation: props.annotationNow.id,
        type: 'TextualBody',
        purpose: 'tagging',
    };

    const [value, setValue] = useState([]);

    const filter = createFilterOptions();

    const handleChange = (e, newValue) => {
        dispatch(setMustClose(false));

        // check if there was a removal
        if (newValue && newValue.length < value.length) {
            // identify the removed tag
            const removedTag = value.filter((tag) => !newValue.includes(tag))[0];

            // remove from the annotation
            props.deleteBodySpecific(removedTag);
        }

        if (newValue && Array.isArray(newValue)) {
            let values = [];

            newValue.forEach((valueNow) => {
                let newTag = {...defaultTag};

                if (valueNow.inputValue) {
                    newTag.value = valueNow.inputValue;
                } else if (typeof valueNow === 'object') {
                    newTag = valueNow;
                }

                values.push(newTag);
            });

            // return to tags and to annotation
            setValue(values);
            props.saveBodyBatch(values);
        }
    };

    const filterOptions = (options, params) => {
        const filtered = filter(options, params);
        const inputValue = params.inputValue;

        const isExisting = options.some((option) => inputValue === option.title);

        if (inputValue !== "" && !isExisting) {
            filtered.push({
                inputValue: inputValue,
            });
        }

        return filtered;
    };

    const getOptionLabel = (option) => {
        if (option.inputValue) {
            return option.inputValue;
        }

        return option.value;
    };

    const getOptions = () => {
        return [];
    };

    useEffect(() => {
        const tags = [...props.getTags()];
        setValue(tags);

    }, [props.annotationNow]);

    return (
        <Autocomplete
            multiple
            value={value}
            onChange={handleChange}
            filterOptions={filterOptions}
            options={getOptions()}
            getOptionLabel={getOptionLabel}
            selectOnFocus
            clearOnBlur
            handleHomeEndKeys
            freeSolo
            renderInput={(params) => (
                <TextField
                    {...params}
                    label="Tag"
                    variant="outlined"
                    size={'small'}
                />
            )}
            size={'small'}
            sx={{marginTop: '10px'}}
            disabled={props.isFake}
        />
    );
};

export default TagSelector;