import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {handleDataServer, successData} from "api";
import semanticApi from "api/routes/semantic";

const semanticSlicer = createSlice({
    name: 'semantic',
    initialState: {
        ontologies: [],

        ontology: {},
        primitives: [],
        enumerates: []
    },
    reducers: {
        setOntologies: (state, action) => {
            state.ontologies = action.payload;
        },
        setOntology: (state, action) => {
            const {ontology, primitives, enumerates} = action.payload;
            state.ontology = ontology;
            state.primitives = primitives;
            state.enumerates = enumerates;
        }
    },
    extraReducers(builder) {
        // get ontologies
        builder
            .addCase(getOntologies.fulfilled, (state, action) => {
                state.ontologies = action.payload;
            });
    }
});

// thunk functions (async)
export const getOntologies = createAsyncThunk("semantic/read",
    async (thunkAPI) => {
        return await handleDataServer(await semanticApi.getOntologies(), successData);
    });

// getters
export const selectOntologies = (state) => state.ontologies.ontologies;
export const selectOntology = (state) => state.ontologies.ontology;
export const selectPrimitives = (state) => state.ontologies.primitives;
export const selectEnumerates = (state) => state.ontologies.enumerates;

// actions
export const { setOntologies, setOntology } = semanticSlicer.actions;

// slice
export default semanticSlicer;
