import React from 'react';
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Typography from "@mui/material/Typography";
import AccordionDetails from "@mui/material/AccordionDetails";
import {isProtectedUser} from "utils/utils";
import ActivityManagement from "components/course/ActivityManagement";
import useAuth from "hooks/useAuth";
import ListDocuments from "components/course/accordions/ListDocuments";

const AccordionActivities = (
    {
        course = {},
        activities = [],
        showOptions = false
    }
) => {
    const user = useAuth();

    return (
        <>
            {activities.map((activity, i) =>
                <Accordion key={i} disableGutters>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon/>}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <Typography variant="p" component="div">
                            {activity.name}
                        </Typography>
                    </AccordionSummary>

                    <AccordionDetails>
                        {(showOptions && isProtectedUser(user) && activity) &&
                            <ActivityManagement course={course} activity={activity}/>
                        }

                        {activity.documents &&
                            <ListDocuments
                                documents={activity.documents}
                            />
                        }
                    </AccordionDetails>
                </Accordion>
            )}
        </>
    );
};

export default AccordionActivities;
