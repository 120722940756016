import {failureCallback, JSON_CONTENT_TYPE, successCallback} from "api/index";
import api from "api/api";

export default {
    getEnumerates: async() => {
        return await api.get('/api/enumerates/index').then(successCallback, failureCallback);
    },
    showEnumerate: async(idEnumerate) => {
        return await api.get('/api/enumerates/show', {
            params: {
                id: idEnumerate
            }
        }).then(successCallback, failureCallback);
    },
    addEnumerate: async(json) => {
        return await api.post('/api/enumerates/store', json, {
            headers: {JSON_CONTENT_TYPE}
        }).then(successCallback, failureCallback);
    },
    deleteEnumerate: async(idEnumerate) => {
        return await api.delete('/api/enumerates/delete', {
            params: {
                id: idEnumerate
            }
        }).then(successCallback, failureCallback);
    }
};
