import api from "../api";
import {failureCallback, successCallback} from "../index";

export default {
    getUsers: async () => {
        return await api.get('/api/users/index').then(successCallback, failureCallback);
    },
    changeRole: async (idUser, idRole) => {
        return await api.post('/api/user/change-role', {
            role_id: idRole
        }, {
            params: {
                id: idUser
            }
        });
    },
    deleteUser: async (idUser) => {
        return await api.delete('/api/user/delete', {
            params: {
                id: idUser
            }
        });
    },
};
