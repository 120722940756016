import React, {useEffect} from "react";
import documentsApi from "api/routes/documents";
import {handleDataServer, handleSuccessData} from "api";
import {useDispatch, useSelector} from "react-redux";
import {getDocuments, selectDocuments} from "store/modules/Documents";
import GenericRead from "components/protected/GenericRead";
import {useTranslation} from "react-i18next";
import {getRouteRole, openConfirmDelete} from "utils/utils";
import {useNavigate} from "react-router-dom";
import useAuth from "hooks/useAuth";

const Documents = () => {
    const user = useAuth();
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const documents = useSelector(selectDocuments);

    const keys = [
        {
            key: "title",
            label: t("documents.singleTitle")
        },
        {
            key: "author",
            label: t("documents.author")
        },
        {
            key: "isPublic",
            label: t("general.public")
        },
        {
            key: "indexName",
            label: t("general.index")
        },
        {
            key: "sameAsUrl",
            label: t("documents.sameAs"),
            isLink: true
        },
        {
            key: "path",
            label: t("documents.path"),
            isLink: true
        },
    ];

    const deleteDocument = async (flag, idDocument) => {
        if (flag) {
            let result = await documentsApi.deleteDocument(idDocument);
            await handleDataServer(result, handleSuccessData);
            await populate();
        }
    };

    const populateDocuments = async () => {
        dispatch(getDocuments());
    };

    const populate = async () => {
        await populateDocuments();
    };

    useEffect(() => {
        populate().then(el => el);
    }, []);

    return (
        <GenericRead
            title={t("documents.title")}
            titleAdd={t("documents.singleTitle")}
            linkAdd={`${getRouteRole(user)}/documents/new`}
            iterate={documents}
            iterateKeys={keys}
            onEdit={(id) => navigate(`${getRouteRole(user)}/documents/${id}/edit`)}
            onDelete={(id) => openConfirmDelete("documents.singleTitle", deleteDocument, id)}
        />
    );
};

export default Documents;
