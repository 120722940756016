import React, {useEffect, useState} from "react";
import {
    Chip,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableFooter,
    TableHead,
    TablePagination,
    TableRow
} from "@mui/material";
import utils from "utils/utils";
import Checkbox from "@mui/material/Checkbox";
import {useTranslation} from "react-i18next";
import TooltipIcon from "components/TooltipIcon";

const UsersTable = (
    {
        users,
        hasCheckbox = false,
        addActions = false,
        hasRole = true,
        isCourse = true,
        showChips = true,
        onSelection = () => {
        },
        handleDelete = (id) => {
        },
        handleEdit = (id) => {
        }
    }
) => {
    const {t} = useTranslation();
    const [selected, setSelected] = useState([]);
    const [columns, setColumns] = useState([
        {field: "name", headerName: t("users.read.table.name"), sortable: true},
        {field: "email", headerName: t("users.read.table.email"), sortable: true}
    ]);
    const [done, setDone] = useState(false);

    // pagination
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    const handleCheckboxSelection = (id) => {
        let selectedNow = [...selected];
        if (selectedNow.includes(id)) {
            selectedNow.splice(selectedNow.indexOf(id), 1);
        } else {
            selectedNow.push(id);
        }
        setSelected(selectedNow);

        // call parent function
        onSelection(selectedNow);
    };

    const getColumns = <>
        <TableRow>
            {hasCheckbox &&
                <TableCell></TableCell>
            }

            {
                columns.map((column, index) =>
                    <TableCell key={index} align="center">{column.headerName}</TableCell>
                )
            }

            {
                addActions &&
                <TableCell>{t("general.actions")}</TableCell>
            }
        </TableRow>
    </>;

    const getRows = users && users.length ?
        users.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map((user, index) =>
                <TableRow key={index}>
                    {hasCheckbox &&
                        <TableCell>
                            <Checkbox
                                checked={selected.includes(user.id)}
                                onChange={() => handleCheckboxSelection(user.id)}
                            />
                        </TableCell>
                    }

                    {columns.map((column, index1) =>
                        <TableCell key={index1} align="center">
                            {column.field === "role" ?
                                isCourse ?
                                    t(`roles.${utils.rolesCoursesNames[user.relation.role_id]}`) :
                                    t(`roles.${utils.rolesNames[user.role_id]}`)
                                :
                                user[column.field]
                            }
                        </TableCell>
                    )}

                    {
                        addActions &&
                        <>
                            <TableCell>
                                {handleEdit &&
                                    <TooltipIcon
                                        disabled={isCourse && user.relation.role_id === utils.rolesCourses.owner}
                                        onClick={() => handleEdit(user.id)}
                                        icon={'edit'}
                                        tooltipText={"general.edit"}
                                    />
                                }
                                {handleDelete &&
                                    <TooltipIcon
                                        disabled={isCourse && user.relation.role_id === utils.rolesCourses.owner}
                                        onClick={() => handleDelete(user.id)}
                                        icon={'delete'}
                                        tooltipText={"general.delete"}
                                    />
                                }
                            </TableCell>
                        </>
                    }
                </TableRow>
            ) : [];

    useEffect(() => {
        if (hasRole && !done) {
            setColumns([...columns, {field: "role", headerName: t('users.read.table.role'), sortable: true}]);
            setDone(true);
        }
    }, []);

    return (
        <>
            <div style={{marginTop: '12px', marginBottom: '12px'}}>
                {(selected && showChips) &&
                    selected.map(id =>
                        <Chip
                            key={id}
                            className={'pointer'}
                            label={users.find(el => el.id === id).name}
                            onClick={() => handleCheckboxSelection(id)}
                            onDelete={() => handleCheckboxSelection(id)}
                        />
                    )}
            </div>
            <TableContainer component={Paper} sx={{maxHeight: 400}}>
                <Table className={'thead-bold'}>
                    <TableHead>
                        {getColumns}
                    </TableHead>
                    <TableBody>
                        {getRows}
                    </TableBody>
                    <TableFooter>
                        <TableRow>
                            <TablePagination
                                rowsPerPageOptions={[10, 25, 100]}
                                count={users.length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onPageChange={(event, newPage) => setPage(newPage)}
                                onRowsPerPageChange={(event) => {
                                    setRowsPerPage(parseInt(event.target.value, 10));
                                    setPage(0);
                                }}
                            />
                        </TableRow>
                    </TableFooter>
                </Table>
            </TableContainer>
        </>
    );
};

export default UsersTable;
