import React, {useState} from 'react';
import TabPanel from "components/TabPanel";
import PropertyRelationFulfill from "components/PropertiesRelations/PropertyRelationFulfill";
import Grid from "@mui/material/Grid";
import {recursiveFindObject} from "utils/semantic";
import Button from "@mui/material/Button";
import {useTranslation} from "react-i18next";
import AddIcon from '@mui/icons-material/Add';
import {v4 as uuid} from 'uuid';

const GenericPropertyRelation = (
    {
        items,
        setItems,
        selected,
        isProperty = true,
        index,
        tabNow
    }
) => {
    const {t} = useTranslation();

    const field = isProperty ? 'properties' : 'relations';

    const itemDefault = {
        id: uuid(),
        display: "",
        sameAs: "",
    };

    const getItemDefault = () => {
        const itemDefaultNow = {...itemDefault};

        if (isProperty) {
            itemDefaultNow['typeable_type'] = `App\\Models\\PrimitiveEnumerate`;
            itemDefaultNow['typeable_id'] = '1';
        } else {
            itemDefaultNow['range_id'] = '';
        }

        return itemDefaultNow;
    };

    const addItem = () => {
        const itemsNow = [...items];

        const itemNow = recursiveFindObject(itemsNow, selected);
        itemNow[field].push(getItemDefault());

        setItems(itemsNow);
    };

    const getItems = () => {
        return selected[field];
    };

    return (
        <TabPanel value={tabNow} index={index}>
            <div style={{textAlign: 'right', marginBottom: '15px'}}>
                <Button variant={'contained'} endIcon={<AddIcon/>} onClick={addItem}>
                    {`${t('general.add')} ${t(`semantic.${isProperty ? 'property' : 'relation'}`)}`}
                </Button>
            </div>

            <Grid container spacing={1}>
                {getItems()?.map((item, index) => (
                    <PropertyRelationFulfill
                        items={items}
                        setItems={setItems}
                        isProperty={isProperty}
                        key={index}
                        selected={selected}
                        item={item}
                    />
                ))}
            </Grid>
        </TabPanel>
    );
};

export default GenericPropertyRelation;
