import api from "../api";
import {failureCallback, JSON_CONTENT_TYPE, successCallback} from "../index";

export default {
    getAnnotations: async(target) => {
        return await api.get('/api/annotations/index', {
            params: {
                target: target
            }
        }).then(successCallback, failureCallback);
    },
    addAnnotation: async(data) => {
        return await api.post('/api/annotations/store', data, {
            headers: {JSON_CONTENT_TYPE}
        }).then(successCallback, failureCallback);
    },
    updateAnnotation: async(data) => {
        return await api.post('/api/annotations/update', data, {
            headers: {JSON_CONTENT_TYPE}
        }).then(successCallback, failureCallback);
    },
    deleteAnnotation: async(annotationId) => {
        return await api.delete('/api/annotations/delete', {
            params: {
                id: annotationId
            }
        }).then(successCallback, failureCallback);
    },
    seizeAnnotation: async(data) => {
        return await api.post('/api/annotations/seize', data, {
            headers: {JSON_CONTENT_TYPE}
        }).then(successCallback, failureCallback);
    },
};
