import api from "../api";
import {failureCallback, successCallback} from "api/index";

export default {
    showContainer: async (id) => {
        return await api.get('/api/containers/show', {
            params: {
                id: id
            }
        }).then(successCallback, failureCallback);
    }
};
