import {getQuoteContext, parseW3CTextAnnotation} from "@recogito/text-annotator";
import {serializeW3CBodies} from "@annotorious/core";
import {v4 as uuid} from 'uuid';

const MyW3CTextFormat = (
    source, container
) => ({
    parse: (serialized) => {
        // check if it is semantic annotation (different from the library)
        if (Array.isArray(serialized.target) && serialized.target.length > 1) {
            // semantic annotation
            const copyAnnotation = {...serialized};
            const copyTarget = [...copyAnnotation.target];

            copyAnnotation.target = [copyAnnotation.target[0]];

            const annotationParse = parseW3CTextAnnotation(copyAnnotation);
            annotationParse.parsed.target.specific = copyTarget.slice(1)[0];

            return annotationParse;
        } else {
            // free annotation
            return parseW3CTextAnnotation(serialized);
        }
    },
    serialize: (annotation) => {
        return mySerializeAnnotation(annotation, source, container);
    }
});

const mySerializeAnnotation = (annotation, source, container) => {
    const {bodies, target, ...rest} = annotation;

    const {
        selector,
        creator,
        created,
        updated,
        specific,
        ...targetRest
    } = target;

    // normal targets added by the library
    const w3cTargets = selector.map((s) => {
        const { quote, start, end, range } = s;

        let prefix, suffix = "";

        if (range) {
            const contextQuote = getQuoteContext(range, container);
            prefix = contextQuote.prefix;
            suffix = contextQuote.suffix;
        }

        const w3cSelectors = [{
            type: 'TextQuoteSelector',
            exact: quote,
            prefix,
            suffix
        }, {
            type: 'TextPositionSelector',
            start,
            end,
        }];

        return {
            ...targetRest,
            id: s.id ? s.id : uuid(),
            source,
            selector: w3cSelectors
        };
    });

    // if there is a specific target, add it to the list
    if (specific) {
        w3cTargets.push({
            ...specific,
            ...targetRest
        });
    }

    const context = annotation['@context'];
    const contextCorrect = Array.isArray(context) || typeof context === 'object' ? context : URL_JSON_LD;

    return {
        ...rest,
        '@context': contextCorrect,
        id: annotation.id,
        type: 'Annotation',
        body: serializeW3CBodies(annotation.bodies),
        creator,
        created: created?.toISOString(),
        modified: updated?.toISOString(),
        target: w3cTargets
    };
};

export const URL_TITLE_ANNOTATION = "http://purl.org/dc/elements/1.1/title";
export const URL_JSON_LD = "http://www.w3.org/ns/anno.jsonld";
export const URL_ACCESS_RIGHTS = "http://purl.org/dc/terms/accessRights";
export const URL_ADDITIONAL_TYPE = "https://schema.org/additionalType";

export default MyW3CTextFormat;