import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {handleDataServer, successData} from "api";
import enumeratesApi from "api/routes/enumerates";

const enumerateSlicer = createSlice({
    name: 'enumerates',
    initialState: {
        enumerates: []
    },
    reducers: {
        setEnumerates: (state, action) => {
            state.enumerates = action.payload;
        },
    },
    extraReducers(builder) {
        // get enumerates
        builder
            .addCase(getEnumerates.fulfilled, (state, action) => {
                state.enumerates = action.payload;
            });
    }
});

// thunk functions (async)
export const getEnumerates = createAsyncThunk("enumerates/read",
    async (thunkAPI) => {
        return await handleDataServer(await enumeratesApi.getEnumerates(), successData);
    });

// getters
export const selectEnumerates = (state) => state.enumerates.enumerates;

// actions
export const {setEnumerates} = enumerateSlicer.actions;

// slice
export default enumerateSlicer;
