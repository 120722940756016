import {useEffect} from 'react';
import {useAnnotationStore, useAnnotator} from "@annotorious/react";
import useAuth from "hooks/useAuth";
import {handleDataServer, SERVER_URI, successData} from "api";
import annotationApi from 'api/routes/annotations';
import {useDispatch, useSelector} from "react-redux";
import {
    closeAnnotation,
    selectAnnotations,
    selectRendered,
    selectScrollIntoAnnotation,
    setAnnotations,
    setHasSelection,
    setIsAnnotating,
    setRendered,
    setScrollIntoAnnotation
} from "store/modules/Annotations";
import store from "store/store";
import {useParams} from "react-router-dom";
import {setResources} from "store/modules/Container";

const MockStorage = (
    {
        target,
        onSetAnnotator
    }
) => {
    const DIRECTORY_IMAGE = '/static/images';

    const user = useAuth();

    let {idContainer} = useParams();

    const storeAnnotation = useAnnotationStore();
    const annotator = useAnnotator();

    const dispatch = useDispatch();
    const annotations = useSelector(selectAnnotations);

    const scrollIntoAnnotation = useSelector(selectScrollIntoAnnotation);
    const rendered = useSelector(selectRendered);

    const getData = (annotation) => {
        return {
            annotation: annotation,
            target: target,
            container_id: idContainer
        };
    };

    // Substitute the annotation id for the server id
    const correctAnnotation = (old, newAnnotation) => {
        const copyOld = {...old};
        const idServer = newAnnotation.id;

        copyOld.id = idServer;
        copyOld.target.annotation = idServer;

        copyOld?.bodies.forEach(body => {
            body.annotation = idServer;
        });

        return copyOld;
    };

    const createAnnotation = async (annotation) => {
        console.log('create', annotation);
    };

    /**
     * The emit function passed through annotator does not let us communicate properly with our local store/Annotations.
     * If this function is not called, 'annotations' will always return an empty array.
     */
    const getLocalAnnotations = () => {
        return store.getState().annotations.annotations;
    };

    const getLocalIsHidden = () => {
        return store.getState().annotations.isHidden;
    };

    const getLocalMustClose = () => {
        return store.getState().annotations.mustClose;
    };

    const getLocalRendered = () => {
        return store.getState().annotations.rendered;
    };

    const updateAnnotation = async (annotation) => {
        console.log('update', annotation);

        // if there is no body and it still exist in annotator, remove the annotation
        const exist = annotator.getAnnotations().find(el => el.id === annotation.id) !== undefined;
        const body = annotation.body;
        if (exist && !body.length) {
            annotator.removeAnnotation(annotation.id);
            return;
        } else if (!body.length) {
            return;
        }

        // if we are hiding the annotations, we do not create/update annotations
        const localIsHidden = getLocalIsHidden();
        if (localIsHidden) return;

        const localAnnotations = getLocalAnnotations();

        // if it arrived here, we need to check if the annotation is already in the server
        // for that, we use storeAnnotation/Annotations and api/Annotations to communicate.
        let result;
        if (localAnnotations.find(el => el.id === annotation.id) === undefined) {
            // if there was not a result in annotations, we are adding a new annotation
            result = await handleDataServer(await annotationApi.addAnnotation(getData(annotation)), successData);
        } else {
            // if there was a body in annotations, we are updating an existing annotation
            result = await handleDataServer(await annotationApi.updateAnnotation(getData(annotation)), successData);
        }

        // common behaviour between creating and updating
        // the server adds a new id to the annotation, so we need to update the annotation in the storeAnnotation
        let newAnnotation = result.annotation;
        const resources = result.resources;

        // update local resources
        dispatch(setResources(resources));
        newAnnotation = JSON.parse(newAnnotation);

        // update the annotation id with the server id result
        if (newAnnotation.id !== annotation.id) {
            const annotationOld = storeAnnotation.getAnnotation(annotation.id);
            storeAnnotation.updateAnnotation(annotationOld.id, correctAnnotation(annotationOld, newAnnotation));

            // when we update the annotation id, we must recalculate the positions of the annotation
            storeAnnotation.recalculatePositions();
        }

        // if we were adding custom resources using SemanticAnnotation and SemanticRelation, it may have
        // been added some junk data, so we need to remove it
        removeEmptyAnnotations();

        // update the annotations storeAnnotation
        dispatch(setAnnotations(annotator.getAnnotations()));

        // we have a flag where we can set if we want to close the annotation after updating it
        const mustClose = getLocalMustClose();
        if (mustClose) {
            dispatch(closeAnnotation());
        }
    };

    const removeEmptyAnnotations = () => {
        const allAnnotations = annotator.getAnnotations();
        const onlyValid = allAnnotations.filter(el => el.body.length !== 0);

        if (allAnnotations.length !== onlyValid.length) {
            annotator.setAnnotations(onlyValid);
        }
    };

    const deleteAnnotation = async (annotation) => {
        console.log('delete', annotation);
        await handleDataServer(await annotationApi.deleteAnnotation(annotation.id), successData);

        removeEmptyAnnotations();
        removeRenderedAnnotation(annotation);

        // update the annotations storeAnnotation
        dispatch(setAnnotations(annotator.getAnnotations()));
        dispatch(closeAnnotation());
    };

    const selectionAnnotations = (annotations) => {
        dispatch(setHasSelection(annotations.length));
    };

    useEffect(() => {
        if (annotator == null) return;

        annotator.on('createAnnotation', createAnnotation);
        annotator.on('deleteAnnotation', deleteAnnotation);
        annotator.on('updateAnnotation', updateAnnotation);
        annotator.on('selectionChanged', selectionAnnotations);

        annotator.setUser({
            id: `${SERVER_URI}/user/${user.id}`,
            name: user.email,
            isGuest: false,
        });

        annotator.setVisible(false);

        onSetAnnotator(annotator);
    }, [annotator]);

    // Go to annotation by request
    useEffect(() => {
        if (!scrollIntoAnnotation) return;

        annotator.scrollIntoView(storeAnnotation.getAnnotation(scrollIntoAnnotation));
        dispatch(setScrollIntoAnnotation(false));
    }, [scrollIntoAnnotation]);

    // Render annotations
    const getImageAnnotation = (annotation) => {
        const purpose = annotation.bodies[0].purpose;
        return `${DIRECTORY_IMAGE}/${purpose}.png`;
    };

    const createInserter = (annotation) => {
        const {
            target: {
                selector: [{range}]
            }
        } = annotation;

        const rangeCopy = range.cloneRange();
        const rangeText = range.toString();
        range.deleteContents();

        rangeCopy.collapse(true);

        let el = document.createElement('span');
        el.innerHTML = `<img src="${getImageAnnotation(annotation)}" alt="" class="icon-annotation pointer fade" data-annotation="${annotation.id}"/><span class="icon-renderer">${rangeText}</span>`;
        el.getElementsByClassName('icon-annotation')[0].addEventListener('click', (e) => {
            const annotationId = e.target.getAttribute('data-annotation');

            annotator.setSelected(annotationId);
            dispatch(setIsAnnotating(true));
        });

        range.insertNode(el);

        return [el, range];
    };

    const renderAnnotations = () => {
        const annotationsNotRendered = annotations.filter(el => rendered.find(el2 => el2.annotation.id === el.id) === undefined);

        const renderedNew = [...rendered];
        annotationsNotRendered.forEach(annotation => {
            const annotationStore = storeAnnotation.getAnnotation(annotation.id);
            const [el, range] = createInserter(annotationStore);
            renderedNew.push({
                annotation: annotation,
                element: el,
                range: range
            });
        });
        dispatch(setRendered(renderedNew));
    };

    const removeRenderedAnnotation = (annotation) => {
        const renderedNew = [...getLocalRendered()];
        const found = renderedNew.find(el => el.annotation.id === annotation.id);
        const {element, range} = found;

        // remove the element from the DOM
        const rangeString = range.toString();
        element.remove();

        // insert only the text back
        range.insertNode(document.createTextNode(rangeString));

        // remove the annotation from the rendered list
        renderedNew.splice(renderedNew.indexOf(found), 1);
        dispatch(setRendered(renderedNew));
    };

    useEffect(() => {
        if (!annotator) return;

        const equal = JSON.stringify(annotations) === JSON.stringify(annotator.getAnnotations());
        if (!equal) {
            annotator.setAnnotations(annotations);
            renderAnnotations();
        }
    }, [annotations]);

    return null;
};

export default MockStorage;