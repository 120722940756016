import {useSelector} from "react-redux";
import {selectUser} from "store/modules/User";
import {useMemo} from "react";

const useAuth = () => {
    const user = useSelector(selectUser);

    return useMemo(() => (user), [user]);
};

export default useAuth;
