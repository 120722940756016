import React from 'react';
import {ACTION, Can} from "ability";
import TooltipIcon from "components/TooltipIcon";
import utils from "utils/utils";
import {handleDataServer, successData} from "api";
import annotationApi from "api/routes/annotations";
import resourceApi from "api/routes/resource";
import {setResources} from "store/modules/Container";
import {useDispatch} from "react-redux";
import {useParams} from "react-router-dom";
import {correctAnnotations, setAnnotations} from "store/modules/Annotations";
import {useAnnotator} from "@annotorious/react";

const ViewAnnotationActions = (props) => {
    const dispatch = useDispatch();
    const annotator = useAnnotator();

    let {idContainer} = useParams();
    const target = window.location.href;

    const seizeAnnotation = async () => {
        const annotation = annotator.getAnnotationById(props.annotationNow.id);

        const data = {
            annotation: annotation,
            container_id: parseInt(idContainer),
            target: target
        };

        const result = await handleDataServer(await annotationApi.seizeAnnotation(data), successData);

        setTimeout(() => {
            if (result) {
                const {annotations, resources} = result;

                dispatch(setAnnotations(correctAnnotations(annotations)));
                dispatch(setResources(resources));
            }
        }, 100);
    };

    const deleteInstance = async () => {
        const instanceId = utils.getBasename(props.annotationNow.bodies[0].value['@id']);

        const result = await handleDataServer(await resourceApi.deleteResource(instanceId, target, idContainer), successData);

        // if we delete an instance/resource, we need to remove its corresponding annotations
        if (result) {
            const {annotations, resources} = result;

            dispatch(setAnnotations(annotations));
            dispatch(setResources(resources));
        }
    };

    return (
        <>
            <div>
                <Can I={ACTION.ACTION} a={'seizeAnnotation'}>
                    <Can I={ACTION.SEIZE} this={props.objectAnnotation}>
                        <TooltipIcon tooltipText={'annotation.seize'} icon={'compare_arrows'}
                                     onClick={seizeAnnotation}/>
                    </Can>
                </Can>

                <Can I={ACTION.ACTION} a={'editAnnotations'}>
                    {/* I can only update an annotation that belongs to me */}
                    <Can I={ACTION.UPDATE} this={props.objectAnnotation}>
                        <TooltipIcon tooltipText={'general.edit'} icon={"edit"}
                                     onClick={() => props.setIsEdit(true)}/>
                    </Can>
                </Can>

                {/* I can only delete an annotation/instance that belongs to me */}
                <Can I={ACTION.DELETE} this={props.objectAnnotation}>
                    <TooltipIcon tooltipText={'general.delete'} icon={"delete"}
                                 onClick={props.deleteAnnotation}/>

                    {
                        props.getTypeAnnotation() === utils.typeAnnotation.semantic &&
                        <TooltipIcon tooltipText={'container.deleteInstance'} icon={'delete_sweep'}
                                     onClick={deleteInstance}
                        />
                    }
                </Can>
            </div>

            <Can I={ACTION.ACTION} a={'createReplies'}>
                <TooltipIcon tooltipText={'general.reply'} icon={'reply'}
                             onClick={() => props.setReply(true)}/>
            </Can>
        </>
    );
};

export default ViewAnnotationActions;