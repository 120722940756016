import React from "react";
import ReadCourses from "pages/common/courses/read";

const IndexProtected = () => {
    return (
        <ReadCourses/>
    );
};

export default IndexProtected;
