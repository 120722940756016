import React, {useEffect, useState} from 'react';
import Card from "@mui/material/Card";
import {useNavigate, useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {getEnumerates, selectEnumerates} from "store/modules/Enumerates";
import TextField from "@mui/material/TextField";
import {Stack} from "@mui/material";
import TooltipIcon from "components/TooltipIcon";
import Grid from "@mui/material/Grid";
import useAuth from "hooks/useAuth";
import {useTranslation} from "react-i18next";
import {getRouteRole} from "utils/utils";
import {handleDataServer, successStatus} from "api";
import enumeratesApi from 'api/routes/enumerates';

const EnumerateNew = () => {
    const user = useAuth();
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [enumerate, setEnumerate] = useState(false);
    const [loaded, setLoaded] = useState(false);

    const routeEnumerates = `${getRouteRole(user)}/semantic/enumerates`;

    const enumerates = useSelector(selectEnumerates);

    const defaultEnumerate = {
        id: "",
        type: "",
        sameAs: "",
        items: []
    };

    const defaultItem = {
        id: "",
        value: "",
        sameAs: ""
    };

    let {idEnumerate} = useParams();
    const isEdit = idEnumerate !== undefined;

    const addItem = () => {
        let items = [...enumerate.items];
        items.push({...defaultItem});
        setEnumerate({...enumerate, items: items});
    };

    const getItems = enumerate && enumerate.items?.map((item, index) =>
        <Grid item key={index}>
            <Stack direction={'row'} alignItems={'center'}>
                <TextField
                    label={t('general.item')}
                    variant={'outlined'}
                    value={item.value}
                    className={'margin8 width100'}
                    onChange={(e) => {
                        let items = [...enumerate.items];
                        items[index] = {...items[index], value: e.target.value};
                        setEnumerate({...enumerate, items: items});
                    }}
                />
                <TextField
                    label={t('semantic.field.sameAs')}
                    variant={'outlined'}
                    value={item.sameAs}
                    className={'margin8 width100'}
                    onChange={(e) => {
                        let items = [...enumerate.items];
                        items[index] = {...items[index], sameAs: e.target.value};
                        setEnumerate({...enumerate, items: items});
                    }}
                />
                <TooltipIcon icon={'delete'} tooltipText={'general.delete'} onClick={() => {
                    let items = [...enumerate.items];
                    items.splice(index, 1);
                    setEnumerate({...enumerate, items: items});
                }}/>
            </Stack>
        </Grid>
    );

    const handleSubmit = async () => {
        const result = await handleDataServer(await enumeratesApi.addEnumerate(enumerate), successStatus);

        if (result) {
            navigate(routeEnumerates);
        }
    };

    useEffect(() => {
        if (!loaded) {
            dispatch(getEnumerates());
            setLoaded(true);
        }

        if (isEdit) {
            setEnumerate(enumerates.find(el => el.id === parseInt(idEnumerate)));
        } else {
            setEnumerate({...defaultEnumerate});
        }
    }, []);

    return (
        <Card>
            <Stack direction={'row'} justifyContent={'space-between'}>
                <h1>
                    {`${t(`general.${isEdit ? 'edit' : 'create'}`)} ${t('semantic.field.enumerate')}`}
                </h1>

                <div>
                    <TooltipIcon
                        icon={'cancel'}
                        tooltipText={'general.cancel'}
                        onClick={() => navigate(routeEnumerates)}
                    />
                    <TooltipIcon
                        icon={'save'}
                        tooltipText={'general.save'}
                        onClick={handleSubmit}
                    />
                </div>
            </Stack>

            <Stack direction={'row'}>
                <TextField
                    label={t("semantic.field.enumerateName")}
                    variant={'outlined'}
                    value={enumerate ? enumerate.type : ""}
                    className={'margin8 width100'}
                    onChange={(e) => setEnumerate((prev) => ({...prev, type: e.target.value}))}
                />

                <TextField
                    label={t("semantic.field.sameAs")}
                    variant={'outlined'}
                    value={enumerate ? enumerate.sameAs : ""}
                    className={'margin8 width100'}
                    onChange={(e) => setEnumerate((prev) => ({...prev, sameAs: e.target.value}))}
                />
            </Stack>

            <div>
                <Stack direction={'row'} justifyContent={'space-between'}>
                    <h2>Items</h2>
                    <TooltipIcon icon={'add'} tooltipText={'general.add'} onClick={addItem}/>
                </Stack>

                {getItems}
            </div>
        </Card>
    );
};

export default EnumerateNew;
