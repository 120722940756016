import React, {useState} from 'react';
import './style.css';
import {useTranslation} from "react-i18next";
import {Tab, Tabs} from "@mui/material";
import Box from "@mui/material/Box";
import {propsTab} from "utils/utils";
import GenericPropertyRelation from "components/PropertiesRelations/GenericPropertyRelation";
import ClassDefinition from "components/PropertiesRelations/ClassDefinition";

const PropertiesRelations = (
    {
        items,
        setItems,
        selected
    }
) => {
    const {t} = useTranslation();
    const [tab, setTab] = useState(0);

    const changeTab = (e, newValue) => {
        setTab(newValue);
    };

    return (
        <div>
            {selected &&
                <Box sx={{width: '100%'}}>
                    <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
                        <Tabs
                            value={tab}
                            onChange={changeTab}
                            variant="scrollable"
                            scrollButtons="auto"
                            allowScrollButtonsMobile
                        >
                            <Tab label={t('semantic.classDefinition')} {...propsTab(0)} />
                            <Tab label={t('semantic.properties')} {...propsTab(1)} />
                            <Tab label={t('semantic.relations')} {...propsTab(2)} />
                        </Tabs>
                    </Box>
                    <ClassDefinition
                        tabNow={tab}
                        index={0}
                        selected={selected}
                        items={items}
                        setItems={setItems}
                    />
                    <GenericPropertyRelation
                        isProperty={true}
                        tabNow={tab}
                        index={1}
                        selected={selected}
                        items={items}
                        setItems={setItems}
                    />
                    <GenericPropertyRelation
                        isProperty={false}
                        tabNow={tab}
                        index={2}
                        selected={selected}
                        items={items}
                        setItems={setItems}
                    />
                </Box>
            }
        </div>
    );
};

export default PropertiesRelations;
