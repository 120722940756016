import React, {useEffect, useState} from 'react';
import FreeAnnotation from "components/container/recogito/popup/FreeAnnotation";
import utils from "utils/utils";
import ReplyAnnotation from "components/container/recogito/popup/ReplyAnnotation";
import CommonAnnotation from "components/container/recogito/popup/CommonAnnotation";
import '../style.css';
import SemanticAnnotation from "components/container/recogito/popup/SemanticAnnotation";
import useObjectAnnotation from "hooks/useObjectAnnotation";
import ViewCardAnnotation from "components/container/recogito/popup/ViewAnnotation/ViewCardAnnotation";

/**
 * This component is responsible for displaying the annotation information for
 * both FreeAnnotation and SemanticAnnotation. It is used inside the CommonAnnotation.
 * @param props Functions passed through CommonAnnotation
 * @returns {JSX.Element} The JSX code
 * @constructor
 */
const ViewCommonAnnotation = (props) => {
    const [isEdit, setIsEdit] = useState(false);
    const [reply, setReply] = useState(false);
    const [replyEdit, setReplyEdit] = useState(false);

    const objectAnnotation = useObjectAnnotation(props.isFake, props.selected);

    const closeReply = () => {
        setReply(false);
        setReplyEdit(false);
    };

    useEffect(() => {
        const bodyFind = props.findBodyComment(props.annotationNow);

        props.setBody(bodyFind);
        props.setAccessRights(props.annotationNow.accessRights);
    }, [props.annotationNow]);

    return (
        <>
            {props.annotationNow ?
                isEdit ?
                    // Editing an annotation
                    props.getTypeAnnotation() === utils.typeAnnotation.free ?
                        <FreeAnnotation {...props} /> :
                        <SemanticAnnotation {...props} />
                    :
                    reply ?
                        // Replying to an annotation
                        <CommonAnnotation
                            isFake={props.isFake}
                            selected={props.selected}
                            children={props1 => (
                                <ReplyAnnotation {...props1} replyObj={replyEdit} closeReply={closeReply}/>
                            )}
                        /> :

                        // No actions happening: viewing information about annotation
                        <ViewCardAnnotation {...props} setReply={setReply} setReplyEdit={setReplyEdit}
                                            objectAnnotation={objectAnnotation} setIsEdit={setIsEdit} /> :
                null
            }
        </>
    );
};

export default ViewCommonAnnotation;