import React from 'react';
import {Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from "@mui/material";
import Button from "@mui/material/Button";
import {useTranslation} from "react-i18next";
import {useDispatch} from "react-redux";
import {resetDialog} from "store/modules/ConfirmDialog";

const ConfirmDialog = (
    {
        open = false,
        title = "general.confirmDelete",
        contentText = "general.confirmDeleteText",
        item = "general.item",
        confirmText = "general.delete",
        onClose,
        param = null
    }
) => {
    const {t} = useTranslation();
    const dispatch = useDispatch();

    const handleClose = (value) => {
        onClose(value, param);
        dispatch(resetDialog());
    };

    return (
        <Dialog
            open={open}
            onClose={() => handleClose(false)}
            sx={{'& .MuiDialog-paper': {width: '80%', maxHeight: 435}}}
            maxWidth="xs"
        >
            <DialogTitle>{t(title)}</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    {item ?
                        t(contentText, {item: t(item)}) :
                        t(contentText)
                    }
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => handleClose(false)} color="primary">
                    {t('general.cancel')}
                </Button>
                <Button onClick={() => handleClose(true)} color="primary" autoFocus>
                    {t(confirmText)}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default ConfirmDialog;
